import { styled } from 'styled-components'
import { TokenLogo } from '@pulsex/uikit'
import { useHttpLocations } from '@pulsex/hooks'

const StyledListLogo = styled(TokenLogo)<{ size: number }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function ListLogo({
  logoURI,
  style,
  size = 24,
  alt,
}: {
  logoURI: string
  size?: number
  style?: React.CSSProperties
  alt?: string
}) {
  const srcs: string[] = useHttpLocations(logoURI)

  return <StyledListLogo alt={alt} size={size} srcs={srcs} style={style} />
}
