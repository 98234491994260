import React, { useState, useMemo, useEffect } from 'react'
import BigNumber from 'bignumber.js'
import { Text, Flex, Box, ArrowBackIcon, ArrowForwardIcon } from '@pulsex/uikit'
import { formatAmount } from 'utils/formatInfoNumbers'
import { DoubleCurrencyLogo } from 'pages/Info/components/CurrencyLogo'
import { useTranslation } from '@pulsex/localization'
import { BurnTable, BuyAndBurnLpResponse } from 'state/buyandburn/types'
import { useToken } from 'hooks/Tokens'
import useUSDCPrice from 'hooks/useUSDCPrice'
import { formatEther, formatUnits } from 'viem'
import {
  ClickableColumnHeader,
  TableWrapper,
  PageButtons,
  Arrow,
  Break,
  ResponsiveGrid,
  StyledCheckbox,
  SORT_FIELD,
  TableLoader
} from './shared'

const ITEMS_PER_PAGE = 100

interface DataRowProps {
  lp: BuyAndBurnLpResponse
  handleCheck: (event: any) => void
  calculateUSD: (event: any, event2: any) => void
  reset?: boolean
  handleReset: () => void
}

const DataRow: React.FC<DataRowProps> = ({
  lp,
  handleCheck, 
  calculateUSD, 
  reset, 
  handleReset 
}) => {
  const [isChecked, setIsChecked] = useState(false)
  const lpBalance = new BigNumber(formatEther(lp.balance)).toNumber()
  const tok0 = useToken(lp.token0)
  const pr0 = new BigNumber(useUSDCPrice(tok0)?.toSignificant())
  const tok1 = useToken(lp.token1)
  const pr1 = new BigNumber(useUSDCPrice(tok1)?.toSignificant())

  const calc = new BigNumber(lp.balance.toString()).div(new BigNumber(lp.totalSupply.toString()))
  const tok0balance = new BigNumber(formatUnits(lp.reserve.reserve0, tok0?.decimals)).multipliedBy(calc)
  const tok1balance = new BigNumber(formatUnits(lp.reserve.reserve1, tok1?.decimals)).multipliedBy(calc)
  const tok0Value = pr0.multipliedBy(tok0balance)
  const tok1Value = pr1.multipliedBy(tok1balance)
  const USDValue = tok0Value.plus(tok1Value)

  function click(e) {
    setIsChecked(!isChecked)
    handleCheck(e)
    calculateUSD(e, USDValue.toNumber())
  }

  useEffect(() => {
    if (reset) {
      setIsChecked(false)
      handleReset()
    }
  }, [reset, handleReset])
  
  return (
      <ResponsiveGrid>
        <StyledCheckbox scale='sm' checked={isChecked} value={[lp.token0, lp.token1]} onChange={(e) => click(e)}/>
        <Flex alignItems="center">  
          <DoubleCurrencyLogo address0={tok0?.address} address1={tok1?.address} />
          <Text ml="8px">
            {tok0?.symbol}/{tok1?.symbol}
          </Text>
        </Flex>
        <Text>${formatAmount(USDValue.toNumber())}</Text>
        <Text>{formatAmount(tok0balance.toNumber())}</Text>
        <Text>{formatAmount(tok1balance.toNumber())}</Text>
        <Text>{formatAmount(lpBalance)}</Text>
      </ResponsiveGrid>
  )
}

interface PoolTableProps {
  lpData: BuyAndBurnLpResponse[]
  loading?: boolean // If true shows indication that SOME pools are loading, but the ones already fetched will be shown
  handleCheck: (event: any) => void
  calculateUSD: (event: any, event2: any) => void
  reset?: boolean
  handleReset: () => void
}

const PoolTable: React.FC<PoolTableProps> = ({ lpData, handleCheck, calculateUSD, reset, handleReset }) => {
  // for sorting
  const [sortField] = useState(SORT_FIELD.balance)
  const [sortDirection] = useState<boolean>(true)
  const { t } = useTranslation()

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  useEffect(() => {
    let extraPages = 1
    if (lpData && lpData.length % ITEMS_PER_PAGE === 0) {
      extraPages = 0
    }
    setMaxPage(Math.floor(lpData?.length / ITEMS_PER_PAGE) + extraPages)
  }, [lpData])

  const sortedLps = useMemo(() => {
    return lpData
      ? lpData
          .sort((a, b) => {
            if (a && b) {
              return a[sortField as keyof BurnTable] > b[sortField as keyof BurnTable]
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            }
            return -1
          })
          .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)
      : []
  }, [page, lpData, sortDirection, sortField])

  // TODO get sorting to work
  // const handleSort = useCallback(
  //   (newField: string) => {
  //     setSortField(newField)
  //     setSortDirection(sortField !== newField ? true : !sortDirection)
  //   },
  //   [sortDirection, sortField],
  // )

  // const arrow = useCallback(
  //   (field: string) => {
  //     const directionArrow = !sortDirection ? '↑' : '↓'
  //     return sortField === field ? directionArrow : ''
  //   },
  //   [sortDirection, sortField],
  // )
  
  return (
    <TableWrapper>
      <ResponsiveGrid>
        <Text color="secondary" fontSize="12px" bold />
        <Text color="secondary" fontSize="12px" bold textTransform="uppercase">
          {t('LPs')}
        </Text>
        <ClickableColumnHeader
          color="secondary"
          fontSize="12px"
          bold
          // onClick={() => handleSort(SORT_FIELD.balance)}
          textTransform="uppercase"
        >
          {t('USD value')} 
          {/* {arrow(SORT_FIELD.balance)} */}
        </ClickableColumnHeader>
        <ClickableColumnHeader
          color="secondary"
          fontSize="12px"
          bold
          // onClick={() => handleSort(SORT_FIELD.token0)}
          textTransform="uppercase"
        >
          {t('Token0 amount')} 
          {/* {arrow(SORT_FIELD.token0)} */}
        </ClickableColumnHeader>
        <ClickableColumnHeader
          color="secondary"
          fontSize="12px"
          bold
          // onClick={() => handleSort(SORT_FIELD.token1)}
          textTransform="uppercase"
        >
          {t('Token1 amount')} 
          {/* {arrow(SORT_FIELD.token1)} */}
        </ClickableColumnHeader>
        <ClickableColumnHeader
          color="secondary"
          fontSize="12px"
          bold
          // onClick={() => handleSort(SORT_FIELD.USDValue)}
          textTransform="uppercase"
        >
          {t('LP amount')} 
          {/* {arrow(SORT_FIELD.USDValue)} */}
        </ClickableColumnHeader>
      </ResponsiveGrid>
      <Break />
      {sortedLps.length > 0 ? (
        <>
          {sortedLps.map((lp) => {
            if (parseInt(lp.balance.toString()) > 1000000000) {
              return (
                <React.Fragment key={lp.address}>
                  <DataRow lp={lp} handleCheck={handleCheck} calculateUSD={calculateUSD} reset={reset} handleReset={handleReset}/>
                  <Break />
                </React.Fragment>
              )
            }
            return null
          })}
          {/* {loading && <LoadingRow />} */}
          <PageButtons>
            <Arrow
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <ArrowBackIcon color={page === 1 ? 'textDisabled' : 'primary'} />
            </Arrow>

            <Text>{t('Page %page% of %maxPage%', { page, maxPage })}</Text>

            <Arrow
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <ArrowForwardIcon color={page === maxPage ? 'textDisabled' : 'primary'} />
            </Arrow>
          </PageButtons>
        </>
      ) : (
        <>
          <TableLoader />
          {/* spacer */}
          <Box />
        </>
      )}
    </TableWrapper>
  )
}

export default PoolTable
