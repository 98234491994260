import styled from 'styled-components'
import { Token } from '@pulsex/sdk'
import { Flex, Skeleton, Text, TimerIcon } from '@pulsex/uikit'
import { DeserializedPool } from '@pulsex/pools'
import { useCurrentBlock } from 'state/block/hooks'
import { useTranslation } from '@pulsex/localization'
import { getPoolTimeInfo } from 'pages/Pools/helpers'
import BaseCell, { CellContent } from './BaseCell'

interface FinishCellProps {
  pool: DeserializedPool<Token>
}

const StyledCell = styled(BaseCell)`
  flex: 2 0 100px;
`

const EndsInCell: React.FC<FinishCellProps> = ({ pool }) => {
  const { totalStaked, isFinished, timeStamp } = pool
  const currentBlock = useCurrentBlock()
  const { t } = useTranslation()

  const { shouldShowSecondCountdown, secondsUntilStart, secondsRemaining, hasPoolStarted } = getPoolTimeInfo(
    pool,
    timeStamp,
  )

  const daysout = Math.floor(secondsRemaining / 86400)
  const hoursout = Math.floor((secondsRemaining - daysout * 86400) / 3600)
  const minutesout = Math.floor((secondsRemaining - daysout * 86400 - hoursout * 3600) / 60)

  const renderBlocks = shouldShowSecondCountdown ? (
    <Flex alignItems="center">
      <Flex flex="1.3">
        <Text fontSize="16px">{`${daysout}d ${hoursout}h ${minutesout}m`}</Text>
      </Flex>
      <Flex flex="1">
        <TimerIcon ml="4px" />
      </Flex>
    </Flex>
  ) : (
    <Text>-</Text>
  )

  // A bit hacky way to determine if public data is loading relying on totalStaked
  // Opted to go for this since we don't really need a separate publicDataLoaded flag
  // anywhere else
  const isLoadingBlockData = !currentBlock || (!secondsRemaining && !secondsUntilStart)
  const isLoadingPublicData = hasPoolStarted ? !totalStaked.gt(0) || isLoadingBlockData : isLoadingBlockData
  const showLoading = isLoadingPublicData && !isFinished
  return (
    <StyledCell role="cell">
      <CellContent>
        <Text fontSize="12px" color="textSubtle" textAlign="left">
          {hasPoolStarted || !shouldShowSecondCountdown ? t('Ends in') : t('Starts in')}
        </Text>
        {showLoading ? <Skeleton width="80px" height="16px" /> : renderBlocks}
      </CellContent>
    </StyledCell>
  )
}

export default EndsInCell
