import { useState, useEffect, useMemo } from 'react'
import { usePlsxUsdPrice } from 'hooks/useUSDCPrice'
import { useAppDispatch } from 'state'
import orderBy from 'lodash/orderBy'
import { Token } from '@pulsex/sdk'
import { DeserializedPool } from '@pulsex/pools'
import { fetchPoolsPublicDataAsync, setInitialPoolConfig } from 'state/pools'
import { usePools } from 'state/pools/hooks'
import { useAddLiquidityFormState } from 'state/mint/reducer'
import { FetchStatus } from 'config/constants/types'
import useSWR from 'swr'

export function usePoolsWithVault() {
  const { pools: poolsWithoutAutoVault } = usePools()
  const pools = useMemo(() => {
    return [...poolsWithoutAutoVault]
  }, [poolsWithoutAutoVault])

  return pools
}

const useGetTopPoolsByApr = (isIntersecting: boolean, chainId: number) => {
  const dispatch = useAppDispatch()

  const [topPools, setTopPools] = useState<DeserializedPool<Token>[]>([null, null, null, null, null])

  const pools = usePoolsWithVault()

  const { protocol } = useAddLiquidityFormState()
  const cakePriceBusd = usePlsxUsdPrice(protocol)

  const { status: fetchStatus, isValidating } = useSWR(
    isIntersecting && chainId && [chainId, 'fetchTopPoolsByApr'],
    async () => {
      await dispatch(setInitialPoolConfig({ chainId }))
      return Promise.all([
        dispatch(fetchPoolsPublicDataAsync(chainId)),
      ])
    },
    { revalidateOnFocus: false, revalidateOnReconnect: false },
  )

  useEffect(() => {
    const getTopPoolsByApr = (activePools: DeserializedPool<Token>[]) => {
      const sortedByApr = orderBy(activePools, (pool: DeserializedPool<Token>) => pool.apr || 0, 'desc')
      setTopPools(sortedByApr.slice(0, 5))
    }
    if (fetchStatus === FetchStatus.Fetched && !isValidating) {
      getTopPoolsByApr(pools)
    }
  }, [setTopPools, pools, isValidating, fetchStatus, cakePriceBusd, topPools])

  return { topPools }
}

export default useGetTopPoolsByApr
