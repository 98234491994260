import { useTranslation } from '@pulsex/localization';
import { useState } from 'react';
import { styled } from "styled-components";
import { useTooltip } from "../../hooks/useTooltip";
import { Box, Flex, Grid } from "../Box";
import { ExpandableLabel } from "../Button";
import { LinkExternal } from "../Link";
import { HelpIcon } from "../Svg";
import { Text } from "../Text";

const Footer = styled(Flex)`
  width: 100%;
  background: ${({ theme }) => theme.colors.dropdown};
`

const BulletList = styled.ul`
  list-style-type: none;
  margin-top: 16px;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
  }
  li::before {
    content: '•';
    margin-right: 4px;
    color: ${({ theme }) => theme.colors.textSubtle};
  }
  li::marker {
    font-size: 12px;
  }
`

interface RoiCalculatorFooterProps {
  isFarm: boolean
  apr?: number;
  displayApr?: string;
  autoCompoundFrequency: number
  multiplier?: string;
  linkLabel: string
  linkHref: string
  performanceFee: number
  rewardIncPerSecond?: boolean;
}

const RoiCalculatorFooter: React.FC<React.PropsWithChildren<RoiCalculatorFooterProps>> = ({
  isFarm,
  apr = 0,
  displayApr,
  multiplier,
  linkLabel,
  linkHref,
  performanceFee,
  rewardIncPerSecond,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { t } = useTranslation()
  const {
    targetRef: multiplierRef,
    tooltip: multiplierTooltip,
    tooltipVisible: multiplierTooltipVisible,
  } = useTooltip(
    <>
      <Text>
        {t(
          'The Multiplier represents the proportion of INC rewards each farm receives, as a proportion of the INC produced each second.',
        )}
      </Text>
      <Text my="24px">
        {t('For example, if a 1x farm received 1 INC per second, a 40x farm would receive 40 INC per second.')}
      </Text>
      <Text>{t('This amount is already included in all APR calculations for the farm.')}</Text>
    </>,
    { placement: 'top-end', tooltipOffset: [20, 10] },
  )

  const gridRowCount = isFarm ? 4 : 2

  return (
    <Footer p="16px" flexDirection="column">
      <ExpandableLabel expanded={isExpanded} onClick={() => setIsExpanded((prev) => !prev)}>
        {isExpanded ? t('Hide') : t('Details')}
      </ExpandableLabel>
      {isExpanded && (
        <Box px="8px">
          <Grid gridTemplateColumns="2.5fr 1fr" gridRowGap="0px" gridTemplateRows={`repeat(${gridRowCount}, auto)`}>
            <Text color="textSubtle" small>
              {'APR'}
            </Text>
            <Text small textAlign="right">
              {apr.toFixed(2)}%
            </Text>
            {isFarm && (
              <>
                <Text color="textSubtle" small>
                  {t('Farm Multiplier')}
                </Text>
                <Flex justifyContent="flex-end" alignItems="flex-end">
                  <Text small textAlign="right" mr="4px">
                    {multiplier}
                  </Text>
                  <span ref={multiplierRef}>
                    <HelpIcon color="textSubtle" width="16px" height="16px" />
                  </span>
                  {multiplierTooltipVisible && multiplierTooltip}
                </Flex>
              </>
            )}
          </Grid>
          <BulletList>
            <li>
              <Text fontSize="12px" textAlign="center" color="textSubtle" display="inline">
                {t('Calculated based on current rates.')}
              </Text>
            </li>
            <li>
              <Text fontSize="12px" textAlign="center" color="textSubtle" display="inline">
                {t(
                  'All figures are estimates provided for your convenience only, and by no means represent guaranteed returns.',
                )}
              </Text>
            </li>
            {performanceFee > 0 && (
              <li>
                <Text mt="14px" fontSize="12px" textAlign="center" color="textSubtle" display="inline">
                  {t('All estimated rates take into account this pool’s %fee%% performance fee', {
                    fee: performanceFee,
                  })}
                </Text>
              </li>
            )}
          </BulletList>
          <Flex justifyContent="center" mt="24px">
            <LinkExternal to={linkHref}>{linkLabel}</LinkExternal>
          </Flex>
        </Box>
      )}
    </Footer>
  )
}

export default RoiCalculatorFooter
