import { Box, Flex } from '@pulsex/uikit'
import styled from 'styled-components'

export const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.3rem 0.75rem 0.75rem;
`

export const StyledSwapContainer = styled(Flex)<{ $isChartExpanded: boolean }>`
  width: 360px;
  flex-shrink: 0;
  height: fit-content;
  padding: 0;

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 450px;
    padding: 0;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    width: 450px;
    padding: 0;
  }
`

export const StyledInputCurrencyWrapper = styled(Box)`
  width: 100%;
`
export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.cardBorder};
`