import { Text, Heading, Card } from '@pulsex/uikit'
import Page from 'components/Layout/Page'
import PoolTable from 'pages/Info/components/InfoTables/PoolsTable'
import useInfoUserSavedTokensAndPools from 'hooks/useInfoUserSavedTokensAndPoolsList'
import { useTranslation } from '@pulsex/localization'
import { usePoolDatasQuery } from 'state/info/hooks'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useNonSpamPoolsData } from '../hooks/usePoolsData'

const PoolsOverview: React.FC<{ protocol: string }> = ({ protocol }) => {
  const { t } = useTranslation()
  const { chainId } = useActiveChainId()
  const { poolsData } = useNonSpamPoolsData(protocol)

  const { v1, v2 } = useInfoUserSavedTokensAndPools(chainId)
  const { savedPools } = protocol === 'v1' ? v1 : v2
  const watchlistPools = usePoolDatasQuery(protocol, savedPools)

  return (
    <Page>
      <Heading scale="lg" mb="16px">
        {t('Your Watchlist')}
      </Heading>
      <Card>
        {watchlistPools.length > 0 ? (
          <PoolTable protocol={protocol} poolDatas={watchlistPools} />
        ) : (
          <Text px="24px" py="16px">
            {t('Saved pools will appear here')}
          </Text>
        )}
      </Card>
      <Heading scale="lg" mt="40px" mb="16px" id="info-pools-title">
        {t('All Pools')}
      </Heading>
      <PoolTable protocol={protocol} poolDatas={poolsData} />
    </Page>
  )
}

export default PoolsOverview
