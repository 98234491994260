import { useQuery } from '@tanstack/react-query'
import { useDeferredValue } from 'react'
import { Protocol } from '@pulsex/smart-order-router/src/routers/sdk'
import { AlphaRouter } from '@pulsex/smart-order-router'
import { CurrencyAmount, TradeType, Currency } from '@pulsex/sdk'
import { ChainId } from '@pulsex/chains'
import { useDebounce, usePropsChanged } from '@pulsex/hooks'
import { useCurrentBlock } from 'state/block/hooks'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getClientSideQuote, QuoteArguments } from 'state/routing/hooks/clientSideSmartOrderRouter'
import { GetQuoteResult } from 'state/routing/types'
import { QUOTING_API } from 'config/constants/endpoints'
import { getViemClients } from 'utils/viem'
import qs from 'qs'

// Revalidate interval in milliseconds
const REVALIDATE_AFTER = {
  [ChainId.PULSECHAIN]: 20_000,
  [ChainId.PULSECHAIN_TESTNET]: 20_000,
}

const CLIENT_PARAMS = {
  protocols: [Protocol.V1, Protocol.V2, Protocol.MIXED],
  minSplits: 1,
}

export function useRoutingQuoter<TTradeType extends TradeType>(
  key: string,
  amount: CurrencyAmount<Currency> | undefined,
  currencyOut: Currency | undefined,
  tradeType: TTradeType,
  args: QuoteArguments,
  autoRevalidate: boolean,
): {
  quote: GetQuoteResult
  isLoading: boolean
  isSyncing: boolean
  isStale: boolean
  isError: boolean
  error: Error
} {
  const { chainId } = useActiveWeb3React()
  const currenciesUpdated = usePropsChanged(amount?.currency, currencyOut)
  const blockNumber = useCurrentBlock()
  const deferQuotientRaw = useDeferredValue(amount?.quotient.toString())
  const deferQuotient = useDebounce(deferQuotientRaw, 500)
  const provider = getViemClients({ chainId })

  const router = new AlphaRouter({
    chainId,
    provider,
  })

  const {
    data: quote,
    status,
    fetchStatus,
    isPreviousData,
    isError,
    error,
  } = useQuery<GetQuoteResult, Error>({
    queryKey: [
      key,
      currenciesUpdated,
      amount?.currency.chainId,
      amount?.currency.isToken ? amount?.currency.address : amount?.currency.symbol,
      currencyOut?.isToken ?  currencyOut?.address : currencyOut?.symbol,
      tradeType,
      deferQuotient,
    ],
    queryFn: async () => {
      try {
        const { tokenInAddress, tokenInChainId, tokenOutAddress, amount: amountRaw, type } = args
        const query = qs.stringify({
          tokenInAddress,
          tokenOutAddress,
          chainId: tokenInChainId,
          amountRaw,
          type,
        })
        const response = await fetch(`${QUOTING_API}?${query}`)
        if (response.ok) {
          const serializedResponse = await response.json()
          return serializedResponse
        }
        console.error(await response.json())
        // Falls back to client-side quoter when API fails
        const { data } = await getClientSideQuote(
          args,
          router,
          // TODO(zzmp): Use PRICE_PARAMS for RouterPreference.PRICE.
          // This change is intentionally being deferred to first see what effect router caching has.
          CLIENT_PARAMS
        )
        return { ...data }

      } catch (e) {
        throw new Error('Failed to fetch quote')
      }
    },
    enabled: !!(amount && currencyOut && deferQuotient),
    refetchOnWindowFocus: false,
    keepPreviousData: !currenciesUpdated,
    retry: false,
    staleTime: autoRevalidate ? REVALIDATE_AFTER[amount?.currency?.chainId] : 0,
    refetchInterval: autoRevalidate && REVALIDATE_AFTER[amount?.currency?.chainId],
  })

  const isValidating = fetchStatus === 'fetching'
  const isLoading = status === 'loading' || isPreviousData
  const isSyncing = isValidating || (amount?.quotient.toString() !== deferQuotient && deferQuotient !== undefined)
  const isStale = quote?.blockNumber !== blockNumber.toString()

  return {
    quote,
    isLoading,
    isSyncing,
    isStale,
    isError,
    error,
  }
}
