import { styled } from 'styled-components'
import {
  Flex,
  Text,
  ArrowBackIcon,
  QuestionHelper,
} from '@pulsex/uikit'
import { Link } from 'react-router-dom'

interface Props {
  title: string
  subtitle?: string
  helper?: string
  backTo?: string
  protocolSelector?: JSX.Element
}

const AppHeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 17px 24px 17px 24px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

const AppHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  helper,
  backTo,
  protocolSelector
}) => {
  return (
    <AppHeaderContainer>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Flex>
          {backTo && (
            <Link to={backTo}>
              <ArrowBackIcon width="22px" />
            </Link>
          )}
          <Flex>
            <Text fontSize="17px" bold>
              {title}
            </Text>
            <Flex alignItems="center">{helper && <QuestionHelper text={helper} ml="4px" />}</Flex>
          </Flex>
        </Flex>
        {protocolSelector && <Flex>{protocolSelector}</Flex>}
      </Flex>
    </AppHeaderContainer>
  )
}

export default AppHeader
