import { HelpIcon } from '@pulsex/uikit'
import { memo } from 'react'
import { isChainSupported } from '../../utils/wagmi'

export const ChainLogo = memo(
  ({ chainId, width = 24, height = 24 }: { chainId: number; width?: number; height?: number }) => {
    if (isChainSupported(chainId)) {
      return (
        <img
          alt={`chain-${chainId}`}
          style={{ maxHeight: `${height}px` }}
          src="https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png"
          width={width}
          height={height}
        />
      )
    }

    return <HelpIcon width={width} height={height} />
  },
)
