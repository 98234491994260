import BigNumber from 'bignumber.js'
import { Token } from '@pulsex/sdk'
import { DeserializedPool } from '@pulsex/pools'
import { getBalanceNumber, getFullDisplayBalance, getDecimalAmount } from '@pulsex/utils/formatBalance'

export const convertSharesToPlsx = (
  shares: BigNumber,
  plsxPerFullShare: BigNumber,
  decimals = 18,
  decimalsToRound = 3,
) => {
  const sharePriceNumber = getBalanceNumber(plsxPerFullShare, decimals)
  const amountInPlsx = new BigNumber(shares.multipliedBy(sharePriceNumber))
  const plsxAsNumberBalance = getBalanceNumber(amountInPlsx, decimals)
  const plsxAsBigNumber = getDecimalAmount(new BigNumber(plsxAsNumberBalance), decimals)
  const plsxAsDisplayBalance = getFullDisplayBalance(amountInPlsx, decimals, decimalsToRound)
  return { plsxAsNumberBalance, plsxAsBigNumber, plsxAsDisplayBalance }
}

export const convertPlsxToShares = (
  plsx: BigNumber,
  plsxPerFullShare: BigNumber,
  decimals = 18,
  decimalsToRound = 3,
) => {
  const sharePriceNumber = getBalanceNumber(plsxPerFullShare, decimals)
  const amountInShares = new BigNumber(plsx.dividedBy(sharePriceNumber))
  const sharesAsNumberBalance = getBalanceNumber(amountInShares, decimals)
  const sharesAsBigNumber = getDecimalAmount(new BigNumber(sharesAsNumberBalance), decimals)
  const sharesAsDisplayBalance = getFullDisplayBalance(amountInShares, decimals, decimalsToRound)
  return { sharesAsNumberBalance, sharesAsBigNumber, sharesAsDisplayBalance }
}

export const getPlsxVaultEarnings = (
  account: string,
  plsxAtLastUserAction: BigNumber,
  userShares: BigNumber,
  pricePerFullShare: BigNumber,
  earningTokenPrice: number,
) => {
  const hasAutoEarnings =
    account && plsxAtLastUserAction && plsxAtLastUserAction.gt(0) && userShares && userShares.gt(0)
  const { plsxAsBigNumber } = convertSharesToPlsx(userShares, pricePerFullShare)
  const autoPlsxProfit = plsxAsBigNumber.minus(plsxAtLastUserAction)
  const autoPlsxToDisplay = autoPlsxProfit.gte(0) ? getBalanceNumber(autoPlsxProfit, 18) : 0

  const autoUsdProfit = autoPlsxProfit.times(earningTokenPrice)
  const autoUsdToDisplay = autoUsdProfit.gte(0) ? getBalanceNumber(autoUsdProfit, 18) : 0
  return { hasAutoEarnings, autoPlsxToDisplay, autoUsdToDisplay }
}

export const getPoolTimeInfo = (pool: DeserializedPool<Token>, currentTime: number) => {
  const { startTimestamp, endTimestamp, isFinished } = pool
  const shouldShowSecondCountdown = Boolean(!isFinished && startTimestamp && endTimestamp)
  const secondsUntilStart = Math.max(startTimestamp - currentTime, 0)
  const secondsRemaining = Math.max(endTimestamp - currentTime, 0)
  const hasPoolStarted = secondsUntilStart === 0 && secondsRemaining > 0
  const secondsToDisplay = hasPoolStarted ? secondsRemaining : secondsUntilStart
  return { shouldShowSecondCountdown, secondsUntilStart, secondsRemaining, hasPoolStarted, secondsToDisplay }
}
