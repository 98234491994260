import { Currency, CurrencyAmount, ERC20Token, Token, PairV1, PairV2, Price, WNATIVE } from '@pulsex/sdk'
import { ChainId } from '@pulsex/chains'
import { PLSX, STABLE_COIN } from '@pulsex/tokens'
import { FAST_INTERVAL } from 'config/constants/exchange'
import { useMemo } from 'react'
import useSWR from 'swr'
import { multiplyPriceByAmount } from 'utils/prices'
import getLpAddress from 'utils/getLpAddress'
import { isChainTestnet } from 'utils/wagmi'
import tryParseCurrencyAmount from '@pulsex/utils/tryParseAmount'
import { useActiveChainId } from './useActiveChainId'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { usePairContract } from './useContract'
import { PairState, useV1Pairs, useV2Pairs } from './usePairs'

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export default function useUSDCPrice(currency?: Currency): Price<Currency, Currency> | undefined {
  const { chainId } = useActiveChainId()
  const wrapped = currency?.wrapped
  const wnative = WNATIVE[chainId]
  const stable = STABLE_COIN[chainId]
  const tokenPairs: [Currency | undefined, Currency | undefined][] = useMemo(
    () => [
      [chainId && wrapped && wnative?.equals(wrapped) ? undefined : currency, chainId ? wnative : undefined],
      [stable && wrapped?.equals(stable) ? undefined : wrapped, stable],
      [chainId ? wnative : undefined, stable],
    ],
    [chainId, currency, stable, wnative, wrapped],
  )
  const [[ethPairState, ethPair], [usdcPairState, usdcPair], [usdcEthPairState, usdcEthPair]] = useV1Pairs(tokenPairs)
  const [[ethPairState2, ethPair2], [usdcPairState2, usdcPair2], [usdcEthPairState2, usdcEthPair2]] =
    useV2Pairs(tokenPairs)

  return useMemo(() => {
    if (!currency || !wrapped || !chainId || !wnative) {
      return undefined
    }
    // handle wpls/pls V1
    if (wrapped.equals(wnative)) {
      if (usdcPair) {
        const price = usdcPair.priceOf(wnative)
        return new Price(currency, stable, price.denominator, price.numerator)
      }
      return undefined
    }
    // handle wpls/pls V2
    if (wrapped.equals(wnative)) {
      if (usdcPair2) {
        const price = usdcPair2.priceOf(wnative)
        return new Price(currency, stable, price.denominator, price.numerator)
      }
      return undefined
    }

    // handle stable
    if (wrapped.equals(stable)) {
      return new Price(stable, stable, '1', '1')
    }

    const ethPairETHAmount = ethPair?.reserveOf(wnative)
    const ethPairETHUSDCValue: bigint =
      ethPairETHAmount && usdcEthPair ? usdcEthPair.priceOf(wnative).quote(ethPairETHAmount).quotient : 0n

    const ethPairETHAmount2 = ethPair2?.reserveOf(wnative)
    const ethPairETHUSDCValue2: bigint =
      ethPairETHAmount2 && usdcEthPair2 ? usdcEthPair2.priceOf(wnative).quote(ethPairETHAmount2).quotient : 0n

    // all other tokens V1
    // first try the stable pair
    if (usdcPairState === PairState.EXISTS && usdcPair && usdcPair.reserveOf(stable).greaterThan(ethPairETHUSDCValue)) {
      const price = usdcPair.priceOf(wrapped)
      return new Price(currency, stable, price.denominator, price.numerator)
    }
    if (ethPairState === PairState.EXISTS && ethPair && usdcEthPairState === PairState.EXISTS && usdcEthPair) {
      if (usdcEthPair.reserveOf(stable).greaterThan('0') && ethPair.reserveOf(wnative).greaterThan('0')) {
        const ethUSDCPrice = usdcEthPair.priceOf(stable)
        const currencyEthPrice = ethPair.priceOf(wnative)
        const usdcPrice = ethUSDCPrice.multiply(currencyEthPrice).invert()
        return new Price(currency, stable, usdcPrice.denominator, usdcPrice.numerator)
      }
    }

    // all other tokens V2
    // first try the stable pair
    if (
      usdcPairState2 === PairState.EXISTS &&
      usdcPair2 &&
      usdcPair2.reserveOf(stable).greaterThan(ethPairETHUSDCValue2)
    ) {
      const price = usdcPair2.priceOf(wrapped)
      return new Price(currency, stable, price.denominator, price.numerator)
    }
    if (ethPairState2 === PairState.EXISTS && ethPair2 && usdcEthPairState2 === PairState.EXISTS && usdcEthPair2) {
      if (usdcEthPair2.reserveOf(stable).greaterThan('0') && ethPair2.reserveOf(wnative).greaterThan('0')) {
        const ethUSDCPrice2 = usdcEthPair2.priceOf(stable)
        const currencyEthPrice2 = ethPair2.priceOf(wnative)
        const usdcPrice2 = ethUSDCPrice2.multiply(currencyEthPrice2).invert()
        return new Price(currency, stable, usdcPrice2.denominator, usdcPrice2.numerator)
      }
    }

    return undefined
  }, [
    chainId,
    currency,
    ethPair,
    ethPairState,
    stable,
    usdcEthPair,
    usdcEthPairState,
    usdcPair,
    usdcPairState,
    wnative,
    wrapped,
    ethPair2,
    ethPairState2,
    usdcEthPair2,
    usdcEthPairState2,
    usdcPair2,
    usdcPairState2,
  ])
}

export const usePlsxUsdcPrice = (): Price<Currency, Currency> | undefined => {
  const { chainId } = useActiveChainId()
  const plsxUsdcPrice = useUSDCPrice(PLSX[chainId])
  return plsxUsdcPrice
}

export const useUSDCCurrencyAmount = (currency: Currency, amount: number): number | undefined => {
  const { chainId } = useActiveChainId()
  const usdcPrice = useUSDCPrice(currency)
  const wrapped = wrappedCurrency(currency, chainId)
  if (usdcPrice) {
    return multiplyPriceByAmount(usdcPrice, amount, wrapped.decimals)
  }
  return undefined
}

export const useUSDCPlsxAmount = (amount: number): number | undefined => {
  const plsxUsdcPrice = usePlsxUsdcPrice()
  if (plsxUsdcPrice) {
    return multiplyPriceByAmount(plsxUsdcPrice, amount)
  }
  return undefined
}

export const usePriceByPairs = (protocol: string, currencyA?: Currency, currencyB?: Currency) => {
  const [tokenA, tokenB] = [currencyA?.wrapped, currencyB?.wrapped]
  const pairAddress = getLpAddress(protocol, tokenA, tokenB)
  const pairContract = usePairContract(pairAddress)

  const { data: price } = useSWR(
    currencyA && currencyB && ['pair-price', currencyA, currencyB],
    async () => {
      const reserves = await pairContract.read.getReserves()
      if (!reserves) {
        return null
      }
      const [reserve0, reserve1] = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

      let pair: PairV1 | PairV2
      if (protocol === 'V1') {
        pair = new PairV1(
          CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
        )
      } else {
        pair = new PairV2(
          CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
        )
      }

      return pair.priceOf(tokenB)
    },
    { dedupingInterval: FAST_INTERVAL, refreshInterval: FAST_INTERVAL },
  )

  return price
}

// @Note: only fetch from one pair
export const usePlsxUsdPrice = (
  protocol: string,
  { forceMainnet } = { forceMainnet: false },
): Price<ERC20Token, ERC20Token> | undefined => {
  const { chainId } = useActiveChainId()
  const isTestnet = !forceMainnet && isChainTestnet(chainId)
  // Return bsc testnet cake if chain is testnet
  const plsx: Token = isTestnet ? PLSX[ChainId.PULSECHAIN_TESTNET] : PLSX[ChainId.PULSECHAIN]
  const stable = STABLE_COIN[plsx.chainId]
  return usePriceByPairs(protocol, stable, plsx)
}

export const usePLSUsdcPrice = (): Price<Currency, Currency> | undefined => {
  const { chainId } = useActiveChainId()
  const wnative = WNATIVE[chainId]
  const plsUsdcPrice = useUSDCPrice(wnative)
  return plsUsdcPrice
}

/**
 *
 * @param fiatValue string representation of a USD amount
 * @returns CurrencyAmount where currency is stablecoin on active chain
 */
export function useStablecoinAmountFromFiatValue(fiatValue: string | null | undefined) {
  const { chainId } = useActiveChainId()
  const stable = STABLE_COIN[chainId]

  return useMemo(() => {
    if (fiatValue === null || fiatValue === undefined || !chainId || !stable) {
      return undefined
    }

    // trim for decimal precision when parsing
    const parsedForDecimals = parseFloat(fiatValue).toFixed(stable.decimals).toString()
    try {
      // parse USD string into CurrencyAmount based on stablecoin decimals
      return tryParseCurrencyAmount(parsedForDecimals, stable)
    } catch (error) {
      return undefined
    }
  }, [chainId, fiatValue, stable])
}

export function useUSDPrice(currencyAmount?: CurrencyAmount<Currency>): number {
  const currency = currencyAmount?.currency

  // Use USDC price for chains not supported by backend yet
  const stablecoinPrice = useUSDCPrice(currency)
  if (currencyAmount && stablecoinPrice) {
    return parseFloat(stablecoinPrice.quote(currencyAmount).toSignificant())
  }
  return 0
}
