import { ChainId } from '@pulsex/chains'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { Menu as UikitMenu } from '@pulsex/uikit'
import { languageList, useTranslation } from '@pulsex/localization'
import { useTheme } from 'styled-components'
import { usePlsxUsdPrice } from 'hooks/useUSDCPrice'
import { useAddLiquidityFormState } from 'state/mint/reducer'
import { useActiveChainId } from 'hooks/useActiveChainId'
import TestnetWarningBanner from "../TestnetWarningBanner"
import config from './config/config'
import UserMenu from './UserMenu'
import GlobalSettings from './GlobalSettings'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'

const Menu = (props) => {
  const { chainId } = useActiveChainId()
  const { isDark } = useTheme()
  const { protocol } = useAddLiquidityFormState()
  const pulsexPriceUsd = usePlsxUsdPrice(protocol)
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useLocation()
  const [showWarning, setShowWarning] = useState(false)

  const activeMenuItem = getActiveMenuItem({ menuConfig: config(t), pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  useEffect(() => {
    if (ChainId.PULSECHAIN_TESTNET === chainId) {
      setShowWarning(true)
    } else {
      setShowWarning(false)
    }
  }, [chainId])

  return (
    <>
      <UikitMenu
        linkComponent={(linkProps) => {
          return <Link to={linkProps.href} {...linkProps} />
        }}
        userMenu={<UserMenu />}
        globalMenu={<GlobalSettings />}
        isDark={isDark}
        banner={showWarning && typeof window !== 'undefined' && <TestnetWarningBanner setShowWarning={setShowWarning}/>}
        // toggleTheme={toggleTheme}
        currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        plsxPriceUsd={pulsexPriceUsd}
        links={config(t)}
        subLinks={activeMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        buyCakeLabel={t('Buy PLSX')}
        {...props}
      />
    </>
  )
}

export default Menu
