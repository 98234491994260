import { styled } from 'styled-components'
import { ChainId } from '@pulsex/chains'
import {
  Flex,
  IconButton,
  Text,
  LineGraphIcon,
} from '@pulsex/uikit'
import { useActiveChainId } from 'hooks/useActiveChainId'

interface Props {
  title: string
  noConfig?: boolean
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
  hasAmount: boolean
  onRefreshPrice: () => void
  protocolSelector?: JSX.Element
}

const CurrencyInputContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 0px 20px;
  width: 100%;
`

const ColoredIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.textSubtle};
`

const CurrencyInputHeader: React.FC<Props> = ({ title, setIsChartDisplayed, isChartDisplayed, protocolSelector }) => {
  const { chainId } = useActiveChainId()
  const toggleChartDisplayed = () => {
    setIsChartDisplayed((currentIsChartDisplayed) => !currentIsChartDisplayed)
  }

  return (
    <CurrencyInputContainer>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column" width="100%" mr={15}>
          <Text fontSize="17px" bold>
            {title}
          </Text>
        </Flex>
        <Flex>
        {(chainId === ChainId.PULSECHAIN || chainId === ChainId.PULSECHAIN_TESTNET) && protocolSelector && <Flex>{protocolSelector}</Flex>}
          {setIsChartDisplayed && (
            <ColoredIconButton onClick={toggleChartDisplayed} variant="text" scale="sm">
              {isChartDisplayed ? <LineGraphIcon width="18px" color="primary" /> : <LineGraphIcon width="18px" color="textSubtle" />}
            </ColoredIconButton>
          )}
        </Flex>
      </Flex>
    </CurrencyInputContainer>
  )
}

export default CurrencyInputHeader
