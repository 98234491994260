import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  EarnIcon,
  MoreIcon,
  DropdownMenuItems,
} from '@pulsex/uikit'
import { ContextApi } from '@pulsex/localization'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Trade'),
    icon: SwapIcon,
    href: '/',
    showItemsOnMobile: false,
    items: [
      {
        label: t('Exchange'),
        href: '/',
      },
      // {
      //   label: t('Limit Orders'),
      //   href: '/limit-orders',
      // },
      {
        label: t('Liquidity'),
        href: '/liquidity',
      },
    ],
  },
  {
    label: t('Earn'),
    href: '/farms',
    icon: EarnIcon,
    items: [
      {
        label: t('Farms'),
        href: '/farms',
      },
      {
        label: t('Pools'),
        href: '/pools',
      },
    ],
  },
  {
    label: '',
    href: '/info',
    icon: MoreIcon,
    hideSubNav: true,
    items: [
      {
        label: t('Burn'),
        href: '/burn',
      },
      {
        label: t('Info'),
        href: '/info',
      },
      // {
      //   label: t('Voting'),
      //   href: '/voting',
      // },
      {
        type: DropdownMenuItemType.DIVIDER,
      },
      // {
      //   label: t('Bridge'),
      //   href: 'https://pulseramp.com',
      //   type: DropdownMenuItemType.EXTERNAL_LINK,
      // },
      // {
      //   label: t('PLS Staking'),
      //   href: 'https://stake.v2b.testnet.pulsechain.com',
      //   type: DropdownMenuItemType.EXTERNAL_LINK,
      // },
      // {
      //   label: t('Docs'),
      //   href: 'https://docs.pulsex.com',
      //   type: DropdownMenuItemType.EXTERNAL_LINK,
      // },
      {
        label: t('Twitter'),
        href: 'https://twitter.com/PulseXcom',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Telegram'),
        href: 'https://t.me/PulseXcom',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    ],
  },
]

export default config
