import { useState } from 'react'
import { useTranslation } from '@pulsex/localization'
import styled, { keyframes } from 'styled-components'
import { Currency, TradeType } from '@pulsex/sdk'
import { ChevronDownIcon, Flex, Text } from '@pulsex/uikit'
import { InterfaceTrade } from 'state/routing/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { AutoColumn } from 'components/Layout/Column'
import Row, { RowBetween, RowFixed } from 'components/Layout/Row'
import { LoadingOpacityContainer } from 'components/Loader/LoadingRows'
import { AdvancedSwapDetails } from './AdvancedSwapDetails'
import AnimatedDropdown from '../../components/AnimatedDropdown'
import TradePrice from '../../components/TradePrice'
import GasEstimateBadge from './GasEstimateBadge'
import SwapRoute from './SwapRoute'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const Wrapper = styled(Row)`
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  border-radius: 16px;
  padding: 10px 10px;
`
const StyledHeaderRow = styled(RowBetween)<{ disabled: boolean; open: boolean }>`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`
const StyledPolling = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
  margin-left: 4px;
  align-items: center;
  color: ${({ theme }) => theme.colors.tertiary};
  transition: 250ms ease color;
`
const StyledPollingDot = styled.div`
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  border-radius: 50%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.backgroundAlt2};
  transition: 250ms ease background-color;
`
const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.colors.text};
  background: transparent;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  transition: 250ms ease border-color;
  left: -3px;
  top: -3px;
`
export const HideSmall = styled.span`
  ${({ theme }) => theme.mediaQueries.sm} {
    display: none;
  };
`
const RotatingArrow = styled(ChevronDownIcon)<{ trade?: boolean; open?: boolean; hidden?: boolean }>`
  hidden: ${({ hidden }) => (hidden ? 'true' : 'false')};
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  transition: transform 0.1s linear;
  stroke: ${({ theme, trade }) => trade ? theme.colors.tertiary : theme.colors.backgroundAlt2};
`

interface SwapDetailsInlineProps {
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined
  syncing: boolean
  loading: boolean
  allowedSlippage: number
}

export default function SwapDetailsDropdown({ trade, syncing, loading, allowedSlippage }: SwapDetailsInlineProps) {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const [showDetails, setShowDetails] = useState(false)

  return (
    <Wrapper style={{ marginTop: '0.25rem' }}>
      <AutoColumn style={{ width: '100%' }}>
        <StyledHeaderRow onClick={() => setShowDetails(!showDetails)} disabled={!trade} open={showDetails}>
          <RowFixed style={{ position: 'relative' }}>
            {loading || syncing && (
              <StyledPolling>
                <StyledPollingDot>
                  <Spinner />
                </StyledPollingDot>
              </StyledPolling>
            )}
            {trade ? (
              <LoadingOpacityContainer $loading={syncing}>
                <TradePrice price={trade.executionPrice} />
              </LoadingOpacityContainer>
            ) : loading || syncing ? (
              <Flex>
                <StyledPolling>
                  <StyledPollingDot>
                    <Spinner />
                  </StyledPollingDot>
                </StyledPolling>
                <Text small color='textSubtle'>{t('Fetching best price')}</Text>
              </Flex>
            ) : null}
          </RowFixed>
          <RowFixed>
            {!trade?.gasUseEstimateUSD ||
              showDetails ||
              !chainId ? null : (
              <GasEstimateBadge
                trade={trade}
                loading={syncing || loading}
                showRoute={!showDetails}
                disableHover={showDetails}
              />
            )}
            <RotatingArrow width="25px" open={Boolean(trade && showDetails)} />
          </RowFixed>
        </StyledHeaderRow>
        <AnimatedDropdown open={showDetails}>
          <AutoColumn gap="sm">
            {trade ? (

              <AdvancedSwapDetails trade={trade} allowedSlippage={allowedSlippage} syncing={syncing} />

            ) : null}
            {trade ? <SwapRoute trade={trade} syncing={syncing} /> : null}
          </AutoColumn>
        </AnimatedDropdown>
      </AutoColumn>
    </Wrapper>
  )
}
