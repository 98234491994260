import { PublicClient, formatEther } from 'viem'
import { ChainId } from '@pulsex/chains'
import { masterChefAddresses } from './const'
import { farmV2FetchFarms, FetchFarmsParams, fetchMasterChefV2Data } from './fetchFarms'

const supportedChainId = [ChainId.PULSECHAIN, ChainId.PULSECHAIN_TESTNET]

export function createFarmFetcher(provider: ({ chainId }: { chainId: ChainId }) => PublicClient) {
  const fetchFarms = async (
    params: {
      isTestnet: boolean
    } & Pick<FetchFarmsParams, 'chainId' | 'farms'>,
  ) => {
    const { isTestnet, farms, chainId } = params
    const masterChefAddress = isTestnet ? masterChefAddresses[ChainId.PULSECHAIN_TESTNET] : masterChefAddresses[ChainId.PULSECHAIN]
    // Fetch public data
    const { poolLength, totalAllocPoint, incPerSecond } = await fetchMasterChefV2Data({
      isTestnet,
      provider,
      masterChefAddress,
    })
    const regularIncPerSecond = formatEther(incPerSecond)
    const farmsWithPrice = await farmV2FetchFarms({
      provider,
      masterChefAddress,
      isTestnet,
      chainId,
      farms: farms.filter((f) => !f.pid || poolLength > f.pid),
      totalAllocPoint,
    })

    return {
      farmsWithPrice,
      poolLength: Number(poolLength),
      regularIncPerSecond: +regularIncPerSecond,
    }
  }

  return {
    fetchFarms,
    isChainSupported: (chainId: ChainId) => supportedChainId.includes(chainId),
    supportedChainId,
    isTestnet: (chainId: ChainId) => ![ChainId.PULSECHAIN].includes(chainId),
  }
}

export * from './apr'
export * from './farmsPriceHelpers'
export * from './types'
export * from './deserializeFarmUserData'
export * from './deserializeFarm'
export * from './filterFarmsByQuery'
export * from './fetchFarms'
