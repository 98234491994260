import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useAllPoolDataQuery } from 'state/info/hooks'

export const usePoolsData = (protocol: string) => {

  // get all the pool datas that exist
  const allPoolData = useAllPoolDataQuery(protocol)

  // get all the pool datas that exist
  const poolsData = useMemo(() => {
    return Object.values(allPoolData)
      .map((pool) => {
        return {
          ...pool.data,
        }
      })
      .filter((pool) => pool.token1.name !== 'unknown' && pool.token0.name !== 'unknown')
  }, [allPoolData])
  return { poolsData }
}

export const useNonSpamPoolsData = (protocol: string) => {
  const { poolsData: rawPoolsData, ...others } = usePoolsData(protocol)
  // top 10 pair need create at least 4 days
  const poolsData = useMemo(
    () =>
      rawPoolsData.reduce((acc, data) => {
        if (acc.length > 10) {
          acc.push(data)
          return acc
        }

        const maySpam = dayjs().diff(dayjs.unix(data.timestamp), 'day') < 4

        // top 10 should not show may spam tokens,
        if (maySpam) return acc

        // after top 10 will not filtered
        acc.push(data)
        return acc
      }, [] as typeof rawPoolsData),
    [rawPoolsData],
  )

  return {
    poolsData,
    ...others,
  }
}
