import { Currency, Price, Token, RouteV1 as V1RouteSDK, RouteV2 as V2RouteSDK, PairV1, PairV2 } from '@pulsex/sdk'
import { Protocol } from './protocol'
import { MixedRouteSDK } from './mixedRoute/route'

export interface IRoute<TInput extends Currency, TOutput extends Currency, TPool extends PairV1 | PairV2> {
	protocol: Protocol
	pools: TPool[]
	path: Token[]
	midPrice: Price<TInput, TOutput>
	input: TInput
	output: TOutput
}

// V1 route wrapper
export class RouteV1<TInput extends Currency, TOutput extends Currency>
	extends V1RouteSDK<TInput, TOutput>
	implements IRoute<TInput, TOutput, PairV1>
{
	public readonly protocol: Protocol = Protocol.V1
	public readonly pools: PairV1[]

	constructor(v1Route: V1RouteSDK<TInput, TOutput>) {
		super(v1Route.pairs, v1Route.input, v1Route.output)
		this.pools = this.pairs
	}
}

// V2 route wrapper
export class RouteV2<TInput extends Currency, TOutput extends Currency>
	extends V2RouteSDK<TInput, TOutput>
	implements IRoute<TInput, TOutput, PairV2>
{
	public readonly protocol: Protocol = Protocol.V2
	public readonly pools: PairV2[]

	constructor(v2Route: V2RouteSDK<TInput, TOutput>) {
		super(v2Route.pairs, v2Route.input, v2Route.output)
		this.pools = this.pairs
	}
}

// Mixed route wrapper
export class MixedRoute<TInput extends Currency, TOutput extends Currency>
	extends MixedRouteSDK<TInput, TOutput>
	implements IRoute<TInput, TOutput, PairV1 | PairV2>
{
	public readonly protocol: Protocol = Protocol.MIXED

	constructor(mixedRoute: MixedRouteSDK<TInput, TOutput>) {
		super(mixedRoute.pools, mixedRoute.input, mixedRoute.output)
	}
}
