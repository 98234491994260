import { useTranslation } from '@pulsex/localization'
import { SubMenuItems } from '@pulsex/uikit'
import { useLocation } from 'react-router'
import InfoNav from './components/InfoNav'

export const InfoPageLayout = ({ children }) => {
  const { pathname } = useLocation()
  const isV2 = pathname.includes(`info/v2`)
  const { t } = useTranslation()

  return (
    <>
    <SubMenuItems
        items={[
          {
            label: t('V1'),
            href: `/info`,
          },
          {
            label: t('V2'),
            href: `/info/v2`,
          },
        ]}
        activeItem={isV2 ? `/info/v2` : `/info`}
      />
      <InfoNav isV2={isV2}/>
      {children}
    </>
  )
}
