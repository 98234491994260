import { useRef, useMemo } from 'react'
import styled from 'styled-components'
import { Button, ChevronUpIcon, RowType } from '@pulsex/uikit'
import { FarmWidget } from '@pulsex/widgets-internal'
import { useTranslation } from '@pulsex/localization'
import BigNumber from 'bignumber.js'
import { getBalanceNumber } from '@pulsex/utils/formatBalance'
import latinise from '@pulsex/utils/latinise'
import { BIG_ZERO } from '@pulsex/utils/bigNumber'
import { FarmWithStakedValue } from '@pulsex/farms'
import { useSearchParams } from 'react-router-dom'
import { getDisplayApr } from '../getDisplayApr'

import Row, { RowProps } from './Row'

export interface ITableProps {
  farms: FarmWithStakedValue[]
  userDataReady: boolean
  incPrice: BigNumber
  sortColumn?: string
}

const Container = styled.div`
  border-radius: ${({ theme }) => theme.radii.card};
  background-color: ${({ theme }) => theme.card.background};
`

const StyledTableBorder = styled.div`
  border-radius: ${({ theme }) => theme.radii.card};
  background-color: ${({ theme }) => theme.colors.cardBorder};
  padding: 1px 1px 1px 1px;
  background-size: 400% 400%;
`

const TableWrapper = styled.div`
  overflow: visible;
  scroll-margin-top: 64px;

  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledTable = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`

const TableBody = styled.tbody`
  & tr {
    td {
      font-size: 16px;
      vertical-align: middle;
    }
  }
`

const TableContainer = styled.div`
  position: relative;
`

const ScrollButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
`

const FarmTable: React.FC<React.PropsWithChildren<ITableProps>> = ({ farms, incPrice, userDataReady }) => {
  const tableWrapperEl = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const searchQuery = searchParams.get('search')

  const columns = useMemo(
    () =>
      FarmWidget.DesktopColumnSchema.map((column) => ({
        id: column.id,
        name: column.name,
        label: column.label,
        sort: (a: RowType<RowProps>, b: RowType<RowProps>) => {
          switch (column.name) {
            case 'farm':
              return b.id - a.id
            case 'apr':
              if (a.original.apr.value && b.original.apr.value) {
                return Number(a.original.apr.value) - Number(b.original.apr.value)
              }

              return 0
            case 'earned':
              return a.original.earned.earnings - b.original.earned.earnings
            default:
              return 1
          }
        },
        sortable: column.sortable,
      })),
    [],
  )

  const getFarmEarnings = (farm) => {
    let earnings = BIG_ZERO
    const existingEarnings = new BigNumber(farm.userData.earnings)

    if (farm.boosted) {
      const proxyEarnings = new BigNumber(farm.userData?.proxy?.earnings)

      earnings = proxyEarnings.gt(0) ? proxyEarnings : existingEarnings
    } else {
      earnings = existingEarnings
    }

    return getBalanceNumber(earnings)
  }

  const generateRow = (farm) => {
    const { token, quoteToken } = farm
    const tokenAddress = token.address
    const quoteTokenAddress = quoteToken.address
    const lpLabel = farm.lpSymbol && farm.lpSymbol.replace('PulseX', '')
    const lowercaseQuery = latinise(typeof searchQuery === 'string' ? searchQuery.toLowerCase() : '')
    const initialActivity = latinise(lpLabel?.toLowerCase()) === lowercaseQuery
    const row: RowProps = {
      apr: {
        value: getDisplayApr(farm.apr, farm.lpRewardsApr),
        pid: farm.pid,
        multiplier: farm.multiplier,
        lpLabel,
        lpSymbol: farm.lpSymbol,
        lpTokenPrice: farm.lpTokenPrice,
        tokenAddress,
        quoteTokenAddress,
        incPrice,
        lpRewardsApr: farm.lpRewardsApr,
        originalValue: farm.apr,
        protocol: farm.protocol,
      },
      farm: {
        label: lpLabel,
        pid: farm.pid,
        token: farm.token,
        quoteToken: farm.quoteToken,
        isReady: farm.multiplier !== undefined,
      },
      earned: {
        earnings: getFarmEarnings(farm),
        pid: farm.pid,
      },
      liquidity: {
        liquidity: farm?.liquidity,
      },
      multiplier: {
        multiplier: farm.multiplier,
      },
      type: farm.isCommunity ? 'community' : 'core',
      details: farm,
      initialActivity,
      protocol: farm.protocol,
    }

    return row
  }

  const rowData = farms.map((farm) => generateRow(farm))

  const generateSortedRow = (row) => {
    // @ts-ignore
    const newRow: RowProps = {}
    columns.forEach((column) => {
      if (!(column.name in row)) {
        throw new Error(`Invalid row data, ${column.name} not found`)
      }
      newRow[column.name] = row[column.name]
    })
    newRow.initialActivity = row.initialActivity
    return newRow
  }

  const sortedRows = rowData.map(generateSortedRow)

  const scrollToTop = (): void => {
    tableWrapperEl.current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <StyledTableBorder>
      <Container id="farms-table">
        <TableContainer id="table-container">
          <TableWrapper ref={tableWrapperEl}>
            <StyledTable>
              <TableBody>
                {sortedRows.map((row) => {
                  return <Row {...row} userDataReady={userDataReady} key={`table-row-${row.farm.pid}`} />
                })}
              </TableBody>
            </StyledTable>
          </TableWrapper>
          <ScrollButtonContainer>
            <Button variant="text" onClick={scrollToTop}>
              {t('To Top')}
              <ChevronUpIcon color="primary" />
            </Button>
          </ScrollButtonContainer>
        </TableContainer>
      </Container>
    </StyledTableBorder>
  )
}

export default FarmTable
