import { useMemo } from 'react'
import styled from 'styled-components'
import { Currency, TradeV1, TradeV2, TradeType } from '@pulsex/sdk'
import { Button, Text, ErrorIcon, MouseoverTooltip } from '@pulsex/uikit'
import { useTranslation } from '@pulsex/localization'
import { Field } from 'state/swap/actions'
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  warningSeverity,
} from 'utils/exchange'
import { AutoColumn } from 'components/Layout/Column'
import { AutoRow, RowBetween, RowFixed } from 'components/Layout/Row'
import truncateHash from 'utils/truncateHash'
import FormattedPriceImpact from './FormattedPriceImpact'
import { SwapCallbackError, SwapShowAcceptChanges } from './styleds'

const SwapModalFooterContainer = styled(AutoColumn)`
  margin-top: 1rem;
  padding: 0.5rem 0;
  border-radius: ${({ theme }) => theme.radii.default};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.text};
  padding: 0.1rem 0.5rem;
`

export default function SwapModalFooter({
  trade,
  onConfirm,
  recipient,
  allowedSlippage,
  swapErrorMessage,
  disabledConfirm,
  showAcceptChanges,
  isMobile,
  onAcceptChanges
}: {
  trade: TradeV1<Currency, Currency, TradeType> | TradeV2<Currency, Currency, TradeType>
  allowedSlippage: number
  onConfirm: () => void
  recipient: string | null
  swapErrorMessage: string | undefined
  disabledConfirm: boolean
  showAcceptChanges: boolean
  isMobile: boolean
  onAcceptChanges: () => void
}) {
  const { t } = useTranslation()
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [allowedSlippage, trade],
  )
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)


  const amount =
    trade.tradeType === TradeType.EXACT_INPUT
      ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)
      : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)
  const symbol =
    trade.tradeType === TradeType.EXACT_INPUT ? trade.outputAmount.currency.symbol : trade.inputAmount.currency.symbol
  const tradeInfoText =
    trade.tradeType === TradeType.EXACT_INPUT
      ? t('Output is estimated. You will receive at least %amount% %symbol% or the transaction will revert.', {
          amount,
          symbol,
        })
      : t('Input is estimated. You will sell at most %amount% %symbol% or the transaction will revert.', {
          amount,
          symbol,
        })
  const [estimatedText, transactionRevertText] = tradeInfoText.split(`${amount} ${symbol}`)

  const truncatedRecipient = recipient ? truncateHash(recipient) : ''

  const recipientInfoText = t('Output will be sent to %recipient%', {
    recipient: truncatedRecipient,
  })

  const [recipientSentToText, postSentToText] = recipientInfoText.split(truncatedRecipient)

  return (
    <>
      <SwapModalFooterContainer>
        <LabelRow>
          <RowFixed>
            <MouseoverTooltip
              isMobile={isMobile}
              text={t('The impact your trade has on the market price of this pool.')}
            >
              <Text fontSize="14px">{t('Price Impact')}</Text>
            </MouseoverTooltip>
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </LabelRow>

        <LabelRow>
          <Text fontSize="14px">{`1 ${trade.inputAmount.currency.symbol} price`}</Text>
          <Text fontSize="14px">
            {`${trade.executionPrice?.toSignificant(3)} ${trade.outputAmount.currency.symbol}`}
          </Text>
        </LabelRow>

        <LabelRow>
          <Text fontSize="14px">{`1 ${trade.outputAmount.currency.symbol} price`}</Text>
          <Text fontSize="14px">
            {`${trade.executionPrice?.invert().toSignificant(3)} ${trade.inputAmount.currency.symbol}`}
          </Text>
        </LabelRow>
        
        <LabelRow>
          <RowFixed>
            <MouseoverTooltip
              isMobile={isMobile}
              text={
                <Text mb="12px">{t('For each trade a %amount% fee is paid towards PLSX buy and burn', { amount: '0.29%' })}</Text>
              }
            >
              <Text fontSize="14px">{t('Liquidity Provider Fee')}</Text>
            </MouseoverTooltip>
          </RowFixed>
          <Text fontSize="14px">
            {realizedLPFee ? `${realizedLPFee?.toSignificant(6)} ${trade.inputAmount.currency.symbol}` : '-'}
          </Text>
        </LabelRow>

        <LabelRow>
          <RowFixed>
            <MouseoverTooltip
              isMobile={isMobile}
              text={t(
                'The minimum amount you are guaranteed to receive. If the price slips any further, your transaction will revert.',
              )}
            >
              <Text fontSize="14px">
                {trade.tradeType === TradeType.EXACT_INPUT ? t('Minimum received') : t('Maximum sold')}{' '}
                {t(`after slippage (${(allowedSlippage / 100)}%)`)}
              </Text>
            </MouseoverTooltip>
          </RowFixed>
          <RowFixed>
            <Text fontSize="14px">
              {trade.tradeType === TradeType.EXACT_INPUT
                ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6) ?? '-'
                : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6) ?? '-'}
            </Text>
            <Text fontSize="14px" marginLeft="4px">
              {trade.tradeType === TradeType.EXACT_INPUT
                ? trade.outputAmount.currency.symbol
                : trade.inputAmount.currency.symbol}
            </Text>
          </RowFixed>
        </LabelRow>
      </SwapModalFooterContainer>

      {showAcceptChanges ? (
        <SwapShowAcceptChanges justify="flex-start" gap="0px">
          <RowBetween>
            <RowFixed>
              <ErrorIcon mr="8px" />
              <Text bold> {t('Price Updated')}</Text>
            </RowFixed>
            <Button height="35px" onClick={onAcceptChanges}>{t('Accept')}</Button>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}
      <AutoColumn justify="flex-start" gap="sm" style={{ paddingTop: '.75rem' }}>
        <Text small color="textSubtle" textAlign="left" style={{ width: '100%' }}>
          {estimatedText}
          <b>
            {amount} {symbol}
          </b>
          {transactionRevertText}
        </Text>
      </AutoColumn>
      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <Text color="textSubtle">
            {recipientSentToText}
            <b title={recipient}>{truncatedRecipient}</b>
            {postSentToText}
          </Text>
        </AutoColumn>
      ) : null}

      <AutoRow>
        <Button
          variant={severity > 2 ? 'danger' : 'primary'}
          onClick={onConfirm}
          disabled={disabledConfirm}
          mt="12px"
          id="confirm-swap-or-send"
          width="100%"
        >
          {severity > 2 ? t('Swap Anyway') : t('Confirm Swap')}
        </Button>

        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
