import { Token } from '@pulsex/sdk'
import { ChainId } from '@pulsex/chains'
// import { plsTokens } from '@pulsex/tokens'
import { plsWarningTokens } from 'config/constants/warningTokens'

// const {} = plsTokens
const { safemoon } = plsWarningTokens

interface WarningTokenList {
  [chainId: number]: {
    [key: string]: Token
  }
}

const SwapWarningTokens = <WarningTokenList>{
  [ChainId.PULSECHAIN]: {
    safemoon,
  },
  [ChainId.PULSECHAIN_TESTNET]: {
    // ...
  }
}

export default SwapWarningTokens
