import { useCallback } from 'react'
import { parseUnits } from 'viem'
import { usePlsxLab } from 'hooks/useContract'

const poolUnstake = (plsxLabContract: any, sousId: number, amount: string, decimals: number) => {
  const units = parseUnits(amount, decimals)

  return plsxLabContract.write.withdraw([sousId, units.toString()])
}

const poolEmergencyUnstake = (plsxLabContract: any) => {
  return plsxLabContract.emergencyWithdraw()
}

const useUnstakePool = (sousId: number, enableEmergencyWithdraw = false) => {
  const plsxLabContract = usePlsxLab()

  const handleUnstake = useCallback(
    async (amount: string, decimals: number) => {
      if (enableEmergencyWithdraw) {
        return poolEmergencyUnstake(plsxLabContract)
      }
      return poolUnstake(plsxLabContract, sousId, amount, decimals)
    },
    [enableEmergencyWithdraw, plsxLabContract, sousId],
  )

  return { onUnstake: handleUnstake }
}

export default useUnstakePool
