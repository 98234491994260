import { styled } from 'styled-components'
import { Box, Link, Text, useMatchBreakpoints } from '@pulsex/uikit'
import packageJson from '../../../../package.json'

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: left;
`

export const VersionTag = () => {
  const version = packageJson?.version
  const { isMobile } = useMatchBreakpoints()

  return (
    <Box position="fixed" bottom={isMobile ? "6%" : "0px"} left="0px">
      <Wrapper p="10px">
        <Text color='textDisabled'>v{version}</Text>
        <Link external small color="textDisabled" to="https://app.pulsex.com">
          Check for update
        </Link>
      </Wrapper>
    </Box>
  )
}
