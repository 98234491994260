import { Box } from '@pulsex/uikit'
import { styled } from 'styled-components'
import Container from './Container'

const StyledPage = styled(Box)`
  width: 100%;
  min-height: calc(100vh - 77px);
  background: ${({ theme }) => theme.colors.gradientBubblegum};
`
const StyledContainer = styled(Container)`
  min-height: calc(100vh - 77px);
  padding-top: 16px;
  padding-bottom: 70px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`

const Page: React.FC<React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> = ({ children, ...props }) => {
  return (
    <>
      <StyledPage>
        <StyledContainer {...props}>{children} </StyledContainer>
      </StyledPage>
    </>
  )
}

export default Page
