import { useMemo } from 'react'
import { CurrencyAmount, TradeType, Currency } from '@pulsex/sdk'
import { useRoutingAPITrade } from 'state/routing/useRoutingAPITrade'
import { InterfaceTrade, TradeState , RouterPreference } from 'state/routing/types'
import { useIsWrapping } from 'hooks/useWrapCallback'
import { useIsWindowVisible } from '@pulsex/hooks'

export function useBestTrade(
  tradeType: TradeType,
  amount?: CurrencyAmount<Currency>,
  otherCurrency?: Currency
): {
  state: TradeState
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined
} {

  const isWindowVisible = useIsWindowVisible()
  const isWrapping = useIsWrapping(amount?.currency, otherCurrency, amount?.toExact())
  const shouldGetTrade = !isWrapping && isWindowVisible

  const routingAPITrade = useRoutingAPITrade(
    tradeType,
    shouldGetTrade ? amount : undefined,
    otherCurrency,
    RouterPreference.CLIENT,
    true,
  )

  const isLoading = routingAPITrade.state === TradeState.LOADING

  // only return gas estimate from api if routing api trade is used
  return useMemo(
    () => ({
      ...(routingAPITrade),
      ...(isLoading ? { state: TradeState.LOADING } : {}),
    }),
    [isLoading, routingAPITrade]
  )
}
