import { Currency, Token } from '@pulsex/sdk'
import { useModal } from '@pulsex/uikit'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import useSWRImmutable from 'swr/immutable'
import shouldShowSwapWarning from 'utils/shouldShowSwapWarning'

import ImportTokenWarningModal from 'components/ImportTokenWarningModal'
import { useAllTokens, useCurrency } from 'hooks/Tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { Field } from 'state/swap/actions'
import { useSwapState } from 'state/swap/hooks'
import { safeGetAddress } from 'utils'

import SwapWarningModal from '../components/SwapWarningModal'

export default function useWarningImport() {
  const navigate = useNavigate()
  const { chainId, isWrongNetwork } = useActiveWeb3React()
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()

  // swap warning state
  const [swapWarningCurrency, setSwapWarningCurrency] = useState(null)

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [useCurrency(inputCurrencyId), useCurrency(outputCurrencyId)]

  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken) ?? [],
    [loadedInputCurrency, loadedOutputCurrency],
  )

  const defaultTokens = useAllTokens()

  const { data: loadedTokenList } = useSWRImmutable(['token-list'])

  const importTokensNotInDefault = useMemo(() => {
    return !isWrongNetwork && urlLoadedTokens && !!loadedTokenList
      ? urlLoadedTokens.filter((token: Token) => {
          const checksummedAddress = safeGetAddress(token.address) || ''

          return !(checksummedAddress in defaultTokens) && token.chainId === chainId
        })
      : []
  }, [chainId, defaultTokens, isWrongNetwork, loadedTokenList, urlLoadedTokens])

  const [onPresentSwapWarningModal] = useModal(<SwapWarningModal swapCurrency={swapWarningCurrency} />, false)
  const [onPresentImportTokenWarningModal] = useModal(
    <ImportTokenWarningModal tokens={importTokensNotInDefault} onCancel={() => navigate('/swap')} />,
  )

  useEffect(() => {
    if (swapWarningCurrency) {
      onPresentSwapWarningModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapWarningCurrency])

  const swapWarningHandler = useCallback(
    (currencyInput: Currency) => {
      const showSwapWarning = shouldShowSwapWarning(chainId, currencyInput)
      if (showSwapWarning) {
        setSwapWarningCurrency(currencyInput)
      } else {
        setSwapWarningCurrency(null)
      }
    },
    [chainId, setSwapWarningCurrency],
  )

  useEffect(() => {
    if (importTokensNotInDefault.length > 0) {
      onPresentImportTokenWarningModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importTokensNotInDefault.length])

  return swapWarningHandler
}
