/* eslint-disable no-param-reassign */
import { gql } from 'graphql-request'
import { TokenData, Block } from 'state/info/types'
import { getChangeForPeriod } from 'utils/getChangeForPeriod'
import { getAmountChange, getPercentChange } from 'pages/Info/utils/infoDataHelpers'
import { infoClientWithChain } from 'utils/graphql'
import { fetchTokenAddresses } from './topTokens'

interface TokenFields {
  id: string
  symbol: string
  name: string
  derivedPLS: string // Price in PLS per token
  derivedUSD: string // Price in USD per token
  tradeVolumeUSD: string
  totalTransactions: string
  totalLiquidity: string
}

interface FormattedTokenFields
  extends Omit<TokenFields, 'derivedPLS' | 'derivedUSD' | 'tradeVolumeUSD' | 'totalTransactions' | 'totalLiquidity'> {
  derivedPLS: number
  derivedUSD: number
  tradeVolumeUSD: number
  totalTransactions: number
  totalLiquidity: number
}

interface TokenQueryResponse {
  data: TokenFields[]
}

/**
 * Main token data to display on Token page
 */
const TOKEN_AT_BLOCK = (block: number | undefined, tokens: string[]) => {
  const addressesString = `["${tokens.join('","')}"]`
  const blockString = block ? `block: {number: ${block}}` : ``
  return `tokens(
      where: {id_in: ${addressesString}}
      ${blockString}
      orderBy: tradeVolumeUSD
      orderDirection: desc
    ) {
      id
      symbol
      name
      derivedPLS
      derivedUSD
      tradeVolumeUSD
      totalTransactions
      totalLiquidity
    }
  `
}

const fetchTokenData = async (
  chainId: number,
  protocol: string,
  block24h: number,
  block48h: number,
  block7d: number,
  block14d: number,
  tokenAddresses: string[],
) => {
  try {
    const queries = [
      TOKEN_AT_BLOCK(null, tokenAddresses),
      TOKEN_AT_BLOCK(block24h, tokenAddresses),
      TOKEN_AT_BLOCK(block48h, tokenAddresses),
      TOKEN_AT_BLOCK(block7d, tokenAddresses),
      TOKEN_AT_BLOCK(block14d, tokenAddresses),
    ];

    const promises = queries.map((query) => {
      const gqlQuery = gql`
        query tokens {
          data: ${query}
        }
      `
      return infoClientWithChain(chainId, protocol).request<TokenQueryResponse>(gqlQuery)
    })

    const results = await Promise.all(promises)

    return { data: results.map(result => result.data), error: false }
  } catch (error) {
    console.error('Failed to fetch token data', error)
    return { error: true }
  }
}

// Transforms tokens into "0xADDRESS: { ...TokenFields }" format and cast strings to numbers
const parseTokenData = (tokens?: TokenFields[]) => {
  if (!tokens) {
    return {}
  }
  return tokens.reduce((accum: { [address: string]: FormattedTokenFields }, tokenData) => {
    const { derivedPLS, derivedUSD, tradeVolumeUSD, totalTransactions, totalLiquidity } = tokenData
    accum[tokenData.id] = {
      ...tokenData,
      derivedPLS: parseFloat(derivedPLS),
      derivedUSD: parseFloat(derivedUSD),
      tradeVolumeUSD: parseFloat(tradeVolumeUSD),
      totalTransactions: parseFloat(totalTransactions),
      totalLiquidity: parseFloat(totalLiquidity),
    }
    return accum
  }, {})
}

export const fetchAllTokenDataByAddresses = async (
  chainId: number,
  protocol: string,
  blocks: Block[],
  tokenAddresses: string[],
) => {
  const [block24h, block48h, block7d, block14d] = blocks ?? []

  const fetchDataPromises = [
    fetchTokenData(chainId, protocol, block24h?.number, block48h?.number, block7d?.number, block14d?.number, tokenAddresses),
  ];

  const [data] = await Promise.all(fetchDataPromises);

  const parsed = parseTokenData(data?.data[0])
  const parsed24 = parseTokenData(data?.data[1])
  const parsed48 = parseTokenData(data?.data[2])
  const parsed7d = parseTokenData(data?.data[3])
  const parsed14d = parseTokenData(data?.data[4])

  // Calculate data and format
  const formatted = tokenAddresses.reduce((accum: { [address: string]: { data: TokenData } }, address) => {
    const current: FormattedTokenFields | undefined = parsed[address]
    const oneDay: FormattedTokenFields | undefined = parsed24[address]
    const twoDays: FormattedTokenFields | undefined = parsed48[address]
    const week: FormattedTokenFields | undefined = parsed7d[address]
    const twoWeeks: FormattedTokenFields | undefined = parsed14d[address]

    const [volumeUSD, volumeUSDChange] = getChangeForPeriod(
      current?.tradeVolumeUSD,
      oneDay?.tradeVolumeUSD,
      twoDays?.tradeVolumeUSD,
    )
    const [volumeUSDWeek] = getChangeForPeriod(current?.tradeVolumeUSD, week?.tradeVolumeUSD, twoWeeks?.tradeVolumeUSD)
    const liquidityUSD = current ? current.totalLiquidity * current.derivedUSD : 0
    const liquidityUSDOneDayAgo = oneDay ? oneDay.totalLiquidity * oneDay.derivedUSD : 0
    const liquidityUSDChange = getPercentChange(liquidityUSD, liquidityUSDOneDayAgo)
    const liquidityToken = current ? current.totalLiquidity : 0
    // Prices of tokens for now, 24h ago and 7d ago
    const priceUSD = current ? current.derivedUSD : 0
    const priceUSDOneDay = oneDay ? oneDay.derivedUSD : 0
    const priceUSDWeek = week ? week.derivedUSD : 0
    const priceUSDChange = getPercentChange(priceUSD, priceUSDOneDay)
    const priceUSDChangeWeek = getPercentChange(priceUSD, priceUSDWeek)
    const txCount = getAmountChange(current?.totalTransactions, oneDay?.totalTransactions)

    accum[address] = {
      data: {
        exists: !!current,
        address,
        name: current ? current.name : '',
        symbol: current ? current.symbol : '',
        volumeUSD,
        volumeUSDChange,
        volumeUSDWeek,
        txCount,
        liquidityUSD,
        liquidityUSDChange,
        liquidityToken,
        priceUSD,
        priceUSDChange,
        priceUSDChangeWeek,
      },
    }
    return accum
  }, {})

  return formatted
}

export const fetchAllTokenData = async (chainId: number, protocol: string, blocks: Block[]) => {
  const tokenAddresses = await fetchTokenAddresses(chainId, protocol)
  const data = await fetchAllTokenDataByAddresses(chainId, protocol, blocks, tokenAddresses)
  return data
}
