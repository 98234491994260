export const peripheryPaymentsWithFeeExtendedAbi = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "pull",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "refundETH",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amountMinimum",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "recipient",
				"type": "address"
			}
		],
		"name": "sweepToken",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amountMinimum",
				"type": "uint256"
			}
		],
		"name": "sweepToken",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amountMinimum",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "feeBips",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "feeRecipient",
				"type": "address"
			}
		],
		"name": "sweepTokenWithFee",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amountMinimum",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "recipient",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "feeBips",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "feeRecipient",
				"type": "address"
			}
		],
		"name": "sweepTokenWithFee",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amountMinimum",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "recipient",
				"type": "address"
			}
		],
		"name": "unwrapWETH9",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amountMinimum",
				"type": "uint256"
			}
		],
		"name": "unwrapWETH9",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amountMinimum",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "recipient",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "feeBips",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "feeRecipient",
				"type": "address"
			}
		],
		"name": "unwrapWETH9WithFee",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amountMinimum",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "feeBips",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "feeRecipient",
				"type": "address"
			}
		],
		"name": "unwrapWETH9WithFee",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "wrapETH",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	}
] as const
