import BigNumber from 'bignumber.js'
import { DEFAULT_GAS_LIMIT, DEFAULT_TOKEN_DECIMAL } from 'config'
import { getMasterChefContract } from 'utils/contractHelpers'

type MasterChefContract = ReturnType<typeof getMasterChefContract>

// TODO: implement gasPrice calcs
export const stakeFarm = async (
  masterChefContract: MasterChefContract,
  pid: number,
  amount: string, 
  gasLimit?: bigint,
  // gasPrice: bigint,
) => {
  const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()
  return masterChefContract.write.deposit([BigInt(pid), BigInt(value)], {
    gas: gasLimit || DEFAULT_GAS_LIMIT,
    // gasPrice,
    account: masterChefContract.account,
    chain: masterChefContract.chain,
  })
}

export const unstakeFarm = async (
  masterChefContract: MasterChefContract, 
  pid: number, 
  amount: string, 
  gasLimit?: bigint,
  // gasPrice: bigint, 
) => {
  const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()
  return masterChefContract.write.withdraw([BigInt(pid), BigInt(value)], {
    gas: gasLimit || DEFAULT_GAS_LIMIT,
    // gasPrice,
    account: masterChefContract.account,
    chain: masterChefContract.chain,
  })
}

export const harvestFarm = async (
  masterChefContract: MasterChefContract, 
  pid: number,  
  gasLimit?: bigint,
  // gasPrice?: bigint,
) => {
  return masterChefContract.write.deposit([BigInt(pid), 0n], {
    gas: gasLimit || DEFAULT_GAS_LIMIT,
    // gasPrice,
    account: masterChefContract.account,
    chain: masterChefContract.chain,
  })
}
