import React from "react";
import { styled, keyframes } from "styled-components";
import PanIcon from "./PanIcon";
import { SpinnerProps2 } from "./types";

const pulse = keyframes`
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.4);
	}
  100% {
		transform: scale(1);
	}
`;

const Container = styled.div`
  position: relative;
`;

const FloatingPanIcon = styled(PanIcon)`
  animation: ${pulse} 2s ease-in-out infinite;
  transform: translate3d(0, 0, 0);
`;

const Spinner2: React.FC<React.PropsWithChildren<SpinnerProps2>> = ({ size = 128 }) => {
  return (
    <Container>
      <FloatingPanIcon width={`${size}px`} />
    </Container>
  );
};

export default Spinner2;
