import styled from 'styled-components'
import { Flex, Heading } from '@pulsex/uikit'
import { FarmWidget } from '@pulsex/widgets-internal'
import { Token } from '@pulsex/sdk'
import { TokenPairImage } from 'components/TokenImage'

const { ProtocolTag } = FarmWidget.Tags
export interface ExpandableSectionProps {
  lpLabel?: string
  multiplier?: string
  isCommunityFarm?: boolean
  token: Token
  quoteToken: Token
  pid?: number
  protocol: string
}

const Wrapper = styled(Flex)`
  svg {
    margin-right: 4px;
  }
`

const CardHeading: React.FC<React.PropsWithChildren<ExpandableSectionProps>> = ({
  lpLabel,
  token,
  quoteToken,
  protocol
}) => {
  return (
    <Wrapper justifyContent="space-between" alignItems="center" mb="12px">
      <TokenPairImage variant="inverted" primaryToken={token} secondaryToken={quoteToken} width={64} height={64} />
      <Flex flexDirection="column" alignItems="flex-end">
        <Heading mb="10px">{lpLabel.split(' ')[0]}</Heading>
        <ProtocolTag protocol={protocol} scale="sm" />
      </Flex>
    </Wrapper>
  )
}

export default CardHeading
