import BigNumber from 'bignumber.js'
import { SLOW_INTERVAL, FAST_INTERVAL } from 'config/constants/exchange'
import { ChainId } from '@pulsex/chains'
import { getFarmConfig } from '@pulsex/farms/constants'
import { useRefreshEffect } from 'hooks/useRefreshEffect'
import useUSDCPrice from 'hooks/useUSDCPrice'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useQuery } from '@tanstack/react-query'
import { getMasterChefContract } from 'utils/contractHelpers'
import { INC } from '@pulsex/tokens'
import { DeserializedFarm, DeserializedFarmsState, DeserializedFarmUserData } from '@pulsex/farms'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAccountActiveChain from 'hooks/useAccountActiveChain'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync, fetchInitialFarmsData } from '.'
import { State } from '../types'
import {
  farmFromLpSymbolSelector,
  farmSelector,
  makeBusdPriceFromPidSelector,
  makeFarmFromPidSelector,
  makeLpTokenPriceFromLpSymbolSelector,
  makeUserFarmFromPidSelector,
} from './selectors'

export function useFarmsLength() {
  const { chainId } = useActiveChainId()
  return useQuery(
    ['farmsLength', chainId],
    async () => {
      const mc = getMasterChefContract(undefined, chainId)
      return Number(await mc.read.poolLength())
    },
    {
      enabled: Boolean(chainId),
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )
}

export const usePollFarmsWithUserData = () => {
  const dispatch = useAppDispatch()
  const { account, chainId } = useAccountActiveChain()

  useQuery(
    ['publicFarmData', chainId],
    async () => {
      const farmsConfig = await getFarmConfig(chainId)
      if (!farmsConfig) return false
      const pids = farmsConfig.map((farmToFetch) => farmToFetch.pid)
      dispatch(fetchFarmsPublicDataAsync({ pids, chainId }))
      return true
    },
    {
      enabled: Boolean(chainId),
      refetchInterval: SLOW_INTERVAL,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )

  useQuery(
    ['farmsWithUserData', account, chainId],
    async () => {
      const farmsConfig = await getFarmConfig(chainId)
      if (!farmsConfig) return false
      const pids = farmsConfig.map((farmToFetch) => farmToFetch.pid)
      const params = { account, pids, chainId }
      dispatch(fetchFarmUserDataAsync(params))
      return true
    },
    {
      enabled: Boolean(account && chainId),
      refetchInterval: SLOW_INTERVAL,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )
}

/**
 * Fetches the "core" farm data used globally
 * 0 = PLSX-PLS LP
 * 1 = USDC-PLS LP
 */
const coreFarmPIDs = {
  [ChainId.PULSECHAIN]: [0, 1],
  [ChainId.PULSECHAIN_TESTNET]: [0, 1],
}
export const usePollCoreFarmData = () => {
  const dispatch = useAppDispatch()
  const { chainId } = useActiveChainId()

  useEffect(() => {
    if (chainId) {
      dispatch(fetchInitialFarmsData({ chainId }))
    }
  }, [chainId, dispatch])

  useRefreshEffect(FAST_INTERVAL, () => {
    if (chainId) {
      dispatch(fetchFarmsPublicDataAsync({ pids: coreFarmPIDs[chainId], chainId }))
    }
  }, [dispatch, chainId])
}

export const useFarms = (): DeserializedFarmsState => {
  const { chainId } = useActiveChainId()
  return useSelector(useMemo(() => farmSelector(chainId), [chainId]))
}

export const useFarmsPoolLength = (): number => {
  return useSelector((state: State) => state.farms.poolLength)
}

export const useFarmFromPid = (pid: number): DeserializedFarm => {
  const farmFromPid = useMemo(() => makeFarmFromPidSelector(pid), [pid])
  return useSelector(farmFromPid)
}

export const useFarmFromLpSymbol = (lpSymbol: string): DeserializedFarm => {
  const farmFromLpSymbol = useMemo(() => farmFromLpSymbolSelector(lpSymbol), [lpSymbol])
  return useSelector(farmFromLpSymbol)
}

export const useFarmUser = (pid): DeserializedFarmUserData => {
  const farmFromPidUser = useMemo(() => makeUserFarmFromPidSelector(pid), [pid])
  return useSelector(farmFromPidUser)
}

// Return the base token price for a farm, from a given pid
export const useUsdcPriceFromPid = (pid: number): BigNumber => {
  const usdPriceFromPid = useMemo(() => makeBusdPriceFromPidSelector(pid), [pid])
  return useSelector(usdPriceFromPid)
}

export const useLpTokenPrice = (symbol: string) => {
  const lpTokenPriceFromLpSymbol = useMemo(() => makeLpTokenPriceFromLpSymbolSelector(symbol), [symbol])
  return useSelector(lpTokenPriceFromLpSymbol)
}

// Return the Incentive token price in USD
export const usePriceINCUsdc = (): BigNumber => {
  const { chainId } = useActiveChainId()
  const incentivePrice = useUSDCPrice(INC[chainId])?.toFixed()

  const incPriceBusd = useMemo(() => {
    return new BigNumber(incentivePrice)
  }, [incentivePrice])

  return incPriceBusd
}
