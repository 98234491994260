import { createElement, useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import { FarmWithStakedValue } from '@pulsex/farms'
import { useTranslation } from '@pulsex/localization'
import { Flex, Skeleton, useMatchBreakpoints } from '@pulsex/uikit'
import { FarmWidget } from '@pulsex/widgets-internal'
import { useDelayedUnmount } from '@pulsex/hooks'
import { useFarmUser } from 'state/farms/hooks'

import Apr, { AprProps } from './Apr'
import Farm from './Farm'
import ActionPanel from './Actions/ActionPanel'

const { ProtocolTag, CoreTag } = FarmWidget.Tags
const { CellLayout, Details, Multiplier, Liquidity, Earned } = FarmWidget.FarmTable
const { DesktopColumnSchema, MobileColumnSchema } = FarmWidget

export interface RowProps {
  apr: AprProps
  farm: FarmWidget.FarmTableFarmTokenInfoProps
  earned: FarmWidget.FarmTableEarnedProps
  multiplier: FarmWidget.FarmTableMultiplierProps
  liquidity: FarmWidget.FarmTableLiquidityProps
  details: FarmWithStakedValue
  type: 'core' | 'community'
  protocol: 'V1' | 'V2'
  initialActivity?: boolean
}

interface RowPropsWithLoading extends RowProps {
  userDataReady: boolean
}

const cells = {
  apr: Apr,
  farm: Farm,
  earned: Earned,
  details: Details,
  multiplier: Multiplier,
  liquidity: Liquidity,
}

const CellInner = styled.div`
  padding: 24px 0px;
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 8px;

  ${({ theme }) => theme.mediaQueries.xl} {
    padding-right: 32px;
  }
`

const StyledTr = styled.tr`
  cursor: pointer;
  border-bottom: 2px solid ${({ theme }) => theme.colors.cardBorder};
`

const Row: React.FunctionComponent<RowPropsWithLoading> = (props) => {
  const { details, initialActivity, userDataReady } = props
  const hasSetInitialValue = useRef(false)
  const hasStakedAmount = !!useFarmUser(details.pid).stakedBalance.toNumber()
  const [actionPanelExpanded, setActionPanelExpanded] = useState(hasStakedAmount)
  const shouldRenderChild = useDelayedUnmount(actionPanelExpanded, 300)
  const { t } = useTranslation()

  const toggleActionPanel = () => {
    setActionPanelExpanded(!actionPanelExpanded)
  }

  useEffect(() => {
    setActionPanelExpanded(hasStakedAmount)
  }, [hasStakedAmount])
  useEffect(() => {
    if (initialActivity && hasSetInitialValue.current === false) {
      setActionPanelExpanded(initialActivity)
      hasSetInitialValue.current = true
    }
  }, [initialActivity])

  const { isDesktop, isMobile } = useMatchBreakpoints()

  const isSmallerScreen = !isDesktop
  const tableSchema = isSmallerScreen ? MobileColumnSchema : DesktopColumnSchema
  const columnNames = tableSchema.map((column) => column.name)

  const handleRenderRow = () => {
    if (!isMobile) {
      return (
        <StyledTr onClick={toggleActionPanel}>
          {Object.keys(props).map((key) => {
            const columnIndex = columnNames.indexOf(key)
            if (columnIndex === -1) {
              return null
            }

            switch (key) {
              case 'type':
                return (
                  <td key={key}>
                    {userDataReady ? (
                      <CellInner style={{ width: '140px' }}>
                        <CoreTag scale="sm" />
                      </CellInner>
                    ) : (
                      <Skeleton width={60} height={24} />
                    )}
                  </td>
                )
              case 'protocol':
                return (
                  <td key={key}>
                    {userDataReady ? (
                      <CellInner style={{ width: '60px' }}>
                        <ProtocolTag protocol={props.protocol} scale="sm" />
                      </CellInner>
                    ) : (
                      <Skeleton width={60} height={24} />
                    )}
                  </td>
                )
              case 'details':
                return (
                  <td key={key}>
                    <CellInner>
                      <CellLayout>
                        <Details actionPanelToggled={actionPanelExpanded} />
                      </CellLayout>
                    </CellInner>
                  </td>
                )
              case 'apr':
                return (
                  <td key={key}>
                    <CellInner>
                      <CellLayout label={t('APR')}>
                        <Apr {...props.apr} hideButton={isSmallerScreen} />
                      </CellLayout>
                    </CellInner>
                  </td>
                )
              default:
                return (
                  <td key={key}>
                    <CellInner>
                      <CellLayout label={t(tableSchema[columnIndex].label)}>
                        {createElement(cells[key], { ...props[key], userDataReady })}
                      </CellLayout>
                    </CellInner>
                  </td>
                )
            }
          })}
        </StyledTr>
      )
    }

    return (
      <StyledTr onClick={toggleActionPanel}>
        <Flex justifyContent="space-between" padding="24px 0px">
          <CellLayout>
            <Farm {...props.farm} />
          </CellLayout>
          <CellLayout label={t('Earned')}>
            <Earned {...props.earned} userDataReady={userDataReady} />
          </CellLayout>
          <CellLayout label={t('APR')}>
            <Apr {...props.apr} hideButton />
          </CellLayout>
        </Flex>
      </StyledTr>
    )
  }

  return (
    <>
      {handleRenderRow()}
      {shouldRenderChild && (
        <tr>
          <td colSpan={6}>
            <ActionPanel {...props} expanded={actionPanelExpanded} />
          </td>
        </tr>
      )}
    </>
  )
}

export default Row
