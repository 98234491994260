import { Currency } from '@pulsex/sdk'
import { TokenLogo } from '@pulsex/uikit'
import { useMemo } from 'react'
import { styled } from 'styled-components'
import { WrappedTokenInfo } from '@pulsex/token-lists'
import { useHttpLocations } from '@pulsex/hooks'
import getTokenLogoURL from '../../utils/getTokenLogoURL'

const domain = 'https://tokens.app.pulsex.com'

const StyledLogo = styled(TokenLogo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

const StyledPulseLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '26px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency?.isNative) return []

    if (currency?.isToken) {
      const tokenLogoURL = getTokenLogoURL(currency.address)

      if (currency instanceof WrappedTokenInfo) {
        if (!tokenLogoURL) return [...uriLocations]
        return [...uriLocations, tokenLogoURL]
      }
      if (!tokenLogoURL) return []
      return [tokenLogoURL]
    }
    return []
  }, [currency, uriLocations])

  if (currency?.isNative) {
    return (
      <StyledPulseLogo
        src={`${domain}/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png`}
        size={size}
        style={style}
      />
    )
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
