import { Currency, CurrencyAmount, Fraction, Percent, TradeV1, TradeV2, TradeType, Token } from '@pulsex/sdk'
import { pulsexRouter02Abi } from 'config/abi/IPulseXRouter02'
import {
  ALLOWED_PRICE_IMPACT_HIGH,
  ALLOWED_PRICE_IMPACT_LOW,
  ALLOWED_PRICE_IMPACT_MEDIUM,
  BIPS_BASE,
  BLOCKED_PRICE_IMPACT_NON_EXPERT,
  INPUT_FRACTION_AFTER_FEE,
  ONE_HUNDRED_PERCENT,
  ROUTER_ADDRESS_V1,
  ROUTER_ADDRESS_V2,
} from 'config/constants/exchange'
import range from 'lodash/range'
import { InterfaceTrade } from 'state/routing/types'

import { useActiveChainId } from 'hooks/useActiveChainId'
import { useContract } from 'hooks/useContract'
import { Field } from '../state/swap/actions'

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(BigInt(num), BIPS_BASE)
}

export function calculateSlippageAmount(value: CurrencyAmount<Currency>, slippage: number): [bigint, bigint] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    (value.quotient * BigInt(10000 - slippage)) / BIPS_BASE,
    (value.quotient * BigInt(10000 + slippage)) / BIPS_BASE,
  ]
}

// universal method for getting PulseXRouter across protocols and chains
export function useRouterContractBothProtocols(protocol: string) {
  const { chainId } = useActiveChainId()
  return useContract(
    protocol === 'V1' ? ROUTER_ADDRESS_V1[chainId] : ROUTER_ADDRESS_V2[chainId],
    pulsexRouter02Abi,
  )
}

// computes price breakdown for the trade
export function computeSmartRouterTradePriceBreakdown(trade: InterfaceTrade<Currency, Currency, TradeType> | null): {
  priceImpactWithoutFee: Percent | undefined
  realizedLPFee: CurrencyAmount<Currency> | undefined | null
} {
  // for each hop in our trade, take away the x*y=k price impact from 0.3% fees
  // e.g. for 3 tokens/2 hops: 1 - ((1 - .03) * (1-.03))
  const realizedLPFee = !trade
    ? undefined
    : ONE_HUNDRED_PERCENT.subtract(
        trade.swaps.reduce<Fraction>(
          (currentFee: Fraction): Fraction => currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
          ONE_HUNDRED_PERCENT,
        ),
      )

  // remove lp fees from price impact
  const priceImpactWithoutFeeFraction = trade && realizedLPFee ? trade?.priceImpact.subtract(realizedLPFee) : undefined

  // the x*y=k impact
  const priceImpactWithoutFeePercent = priceImpactWithoutFeeFraction
    ? new Percent(priceImpactWithoutFeeFraction?.numerator, priceImpactWithoutFeeFraction?.denominator)
    : undefined

  // the amount of the input that accrues to LPs
  const realizedLPFeeAmount =
    realizedLPFee &&
    trade &&
    CurrencyAmount.fromRawAmount(
      trade.inputAmount.currency,
      realizedLPFee.multiply(trade.inputAmount.quotient).quotient,
    )

  return { priceImpactWithoutFee: priceImpactWithoutFeePercent, realizedLPFee: realizedLPFeeAmount }
}

// computes price breakdown for the trade
export function computeTradePriceBreakdown(
  trade: TradeV1<Currency, Currency, TradeType> | TradeV2<Currency, Currency, TradeType> | null,
): {
  priceImpactWithoutFee: Percent | undefined
  realizedLPFee: CurrencyAmount<Currency> | undefined | null
} {
  // for each hop in our trade, take away the x*y=k price impact from 0.3% fees
  // e.g. for 3 tokens/2 hops: 1 - ((1 - .03) * (1-.03))
  const realizedLPFee = !trade
    ? undefined
    : ONE_HUNDRED_PERCENT.subtract(
        // equivalent use to (new Array(trade.route.pairs.length)).fill(null)
        range(trade.route.pairs.length).reduce<Fraction>(
          (currentFee: Fraction): Fraction => currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
          ONE_HUNDRED_PERCENT,
        ),
      )

  // remove lp fees from price impact
  const priceImpactWithoutFeeFraction = trade && realizedLPFee ? trade?.priceImpact.subtract(realizedLPFee) : undefined

  // the x*y=k impact
  const priceImpactWithoutFeePercent = priceImpactWithoutFeeFraction
    ? new Percent(priceImpactWithoutFeeFraction?.numerator, priceImpactWithoutFeeFraction?.denominator)
    : undefined

  // the amount of the input that accrues to LPs
  const realizedLPFeeAmount =
    realizedLPFee &&
    trade &&
    CurrencyAmount.fromRawAmount(
      trade.inputAmount.currency,
      realizedLPFee.multiply(trade.inputAmount.quotient).quotient,
    )

  return { priceImpactWithoutFee: priceImpactWithoutFeePercent, realizedLPFee: realizedLPFeeAmount }
}

// computes the minimum amount out and maximum amount in for a trade given a user specified allowed slippage in bips

export function computeSmartRouterSlippageAdjustedAmounts(
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined,
  allowedSlippage: number,
): { [field in Field]?: CurrencyAmount<Currency> } {
  const pct = basisPointsToPercent(allowedSlippage)
  return {
    [Field.INPUT]: trade?.maximumAmountIn(pct),
    [Field.OUTPUT]: trade?.minimumAmountOut(pct),
  }
}

export function computeSlippageAdjustedAmounts(
  trade: TradeV1<Currency, Currency, TradeType> | TradeV2<Currency, Currency, TradeType> | undefined,
  allowedSlippage: number,
): { [field in Field]?: CurrencyAmount<Currency> } {
  const pct = basisPointsToPercent(allowedSlippage)
  return {
    [Field.INPUT]: trade?.maximumAmountIn(pct),
    [Field.OUTPUT]: trade?.minimumAmountOut(pct),
  }
}

export function warningSeverity(priceImpact: Percent | undefined): 0 | 1 | 2 | 3 | 4 {
  if (!priceImpact?.lessThan(BLOCKED_PRICE_IMPACT_NON_EXPERT)) return 4
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_HIGH)) return 3
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_MEDIUM)) return 2
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_LOW)) return 1
  return 0
}

export function formatExecutionPrice(trade?: TradeV1<Currency, Currency, TradeType>, inverted?: boolean): string {
  if (!trade) {
    return ''
  }
  return inverted
    ? `${trade.executionPrice.invert().toSignificant(6)} ${trade.inputAmount.currency.symbol} / ${
        trade.outputAmount.currency.symbol
      }`
    : `${trade.executionPrice.toSignificant(6)} ${trade.outputAmount.currency.symbol} / ${
        trade.inputAmount.currency.symbol
      }`
}

// computes fee amount from input field
export function computeLimitOrderLPFee(
  trade?: TradeV1<Currency, Currency, TradeType> | null,
  inputAmount?: CurrencyAmount<Currency>,
): CurrencyAmount<Currency> | undefined | null {
  // for each hop in our trade, take away the x*y=k price impact from 0.29% fees
  // e.g. for 3 tokens/2 hops: 1 - ((1 - .029) * (1-.029))
  const realizedLPFee = !trade
    ? undefined
    : ONE_HUNDRED_PERCENT.subtract(
        trade.route.pairs.reduce<Fraction>(
          (currentFee: Fraction): Fraction => currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
          ONE_HUNDRED_PERCENT,
        ),
      )

  // the amount of the input that accrues to LPs
  const realizedLPFeeAmount =
    realizedLPFee &&
    trade &&
    CurrencyAmount.fromRawAmount(inputAmount.currency, realizedLPFee.multiply(inputAmount.quotient).quotient)

  return realizedLPFeeAmount
}

// computes the minimum amount out for a trade given a user specified allowed slippage in bips
export function computeOutputSlippageAdjustedAmount(
  trade: TradeV1<Currency, Currency, TradeType> | undefined,
  outputAmount: CurrencyAmount<Currency>,
  rawOutput: string | undefined,
  allowedSlippage: number,
): CurrencyAmount<Token> | CurrencyAmount<Currency> {
  if (!trade && !rawOutput) return null
  const pct = basisPointsToPercent(allowedSlippage)
  const slippageAdjustedAmountOut = new Fraction(BigInt(1)).add(pct).invert().multiply(rawOutput).quotient
  return CurrencyAmount.fromRawAmount(outputAmount.currency, BigInt(slippageAdjustedAmountOut.toString()))
}
