import { styled } from 'styled-components'
import { Box } from '@pulsex/uikit'
import Container from '../Layout/Container'
import { PageHeaderProps } from './types'

const Outer = styled(Box)<{ background?: string }>`
  min-height: calc(100vh - 119px);
  background: ${({ theme, background }) => background || theme.colors.gradientBubblegum};

  ${({ theme }) => theme.mediaQueries.lg} {
    min-height: calc(100vh - 119px);
  }
`

const Inner = styled(Container)`
  padding-top: 32px;
  padding-bottom: 70px;
`

const PageHeader: React.FC<PageHeaderProps> = ({ background, children, ...props }) => (
  <Outer background={background} {...props}>
    <Inner>{children}</Inner>
  </Outer>
)

export default PageHeader
