import { useFarmUser } from 'state/farms/hooks'
import { FarmWidget } from '@pulsex/widgets-internal'
import { TokenPairImage } from 'components/TokenImage'

const { FarmTokenInfo } = FarmWidget.FarmTable

// export interface FarmProps {
//   label: string
//   pid: number
//   token: Token
//   quoteToken: Token
// }

// const Container = styled.div`
//   padding-left: 16px;
//   display: flex;
//   align-items: center;

//   ${({ theme }) => theme.mediaQueries.sm} {
//     padding-left: 32px;
//   }
// `

// const TokenWrapper = styled.div`
//   padding-right: 8px;
//   width: 40px;

//   ${({ theme }) => theme.mediaQueries.sm} {
//     width: 40px;
//   }
// `

const Farm: React.FunctionComponent<React.PropsWithChildren<FarmWidget.FarmTableFarmTokenInfoProps>> = ({
  token,
  quoteToken,
  label,
  pid,
  isReady,
}) => {
  const { stakedBalance } = useFarmUser(pid)

  return (
    <FarmTokenInfo
      pid={pid}
      label={label}
      token={token}
      quoteToken={quoteToken}
      isReady={isReady}
      stakedBalance={stakedBalance}
    >
      <TokenPairImage width={40} height={40} variant="inverted" primaryToken={token} secondaryToken={quoteToken} />
    </FarmTokenInfo>
  )
}

export default Farm
