import { Currency, Price } from '@pulsex/sdk'
import { useCallback, useState } from 'react'
import { Box, Text } from '@pulsex/uikit'

interface TradePriceProps {
  price: Price<Currency, Currency>
}

export default function TradePrice({ price }: TradePriceProps) {
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `1 ${price?.baseCurrency?.symbol} = ${price?.quoteCurrency?.symbol}`
    : `1 ${price?.quoteCurrency?.symbol} = ${price?.baseCurrency?.symbol}`
  const flipPrice = useCallback(() => setShowInverted(!showInverted), [setShowInverted, showInverted])

  return (
    <Text
      style={{ color: 'textSubtle', fontSize: '14px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}
    >
      {show ? (
        <Box
          onClick={(e) => {
            e.stopPropagation() // dont want this click to affect dropdowns / hovers
            flipPrice()
          }}>
          {label} {formattedPrice ?? '-'}
        </Box>
      ) : (
        '-'
      )}
    </Text>
  )
}
