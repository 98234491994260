import { ChainId } from '@pulsex/chains'
import { enumValues } from '@pulsex/utils/enumValues'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

type TokenAndPoolList = Record<ChainId, Record<'tokens' | 'pools', string[]>>

const createDefaultTokenAndPoolList = () => {
  const listV1 = {} as TokenAndPoolList
  const listV2 = {} as TokenAndPoolList
  for (const chainId of enumValues(ChainId)) {
    listV1[chainId] = {
      pools: [],
      tokens: [],
    }
  }
  for (const chainId of enumValues(ChainId)) {
    listV2[chainId] = {
      pools: [],
      tokens: [],
    }
  }
  return { listV1, listV2 }
}

const { listV1: defaultTokenAndPoolListV1, listV2: defaultTokenAndPoolListV2 } = createDefaultTokenAndPoolList()

const tokensAtomV1 = atomWithStorage('pcs:infoSavedTOkensAndPoolsV1', defaultTokenAndPoolListV1)
const tokensAtomV2 = atomWithStorage('pcs:infoSavedTOkensAndPoolsV2', defaultTokenAndPoolListV2)

const useInfoUserSavedTokensAndPools = (chainId: ChainId) => {

  // V1
  const [listsV1, setListsV1] = useAtom(tokensAtomV1)

  const addTokenV1 = (newToken: string) => {
    setListsV1({
      ...listsV1,
      [chainId]: {
        ...listsV1[chainId],
        tokens: [newToken, ...listsV1[chainId].tokens],
      },
    })
  }

  const removeTokenV1 = (token: string) => {
    setListsV1(() => ({
      ...listsV1,
      [chainId]: {
        ...listsV1[chainId],
        tokens: listsV1[chainId].tokens.filter((t) => t !== token),
      },
    }))
  }

  const addPoolV1 = (newPool: string) => {
    setListsV1({
      ...listsV1,
      [chainId]: {
        ...listsV1[chainId],
        pools: [newPool, ...listsV1[chainId].pools],
      },
    })
  }

  const removePoolV1 = (pool: string) => {
    setListsV1(() => ({
      ...listsV1,
      [chainId]: {
        ...listsV1[chainId],
        pools: listsV1[chainId].pools.filter((p) => p !== pool),
      },
    }))
  }

  // V2
  const [listsV2, setListsV2] = useAtom(tokensAtomV2)

  const addTokenV2 = (newToken: string) => {
    setListsV2({
      ...listsV2,
      [chainId]: {
        ...listsV2[chainId],
        tokens: [newToken, ...listsV2[chainId].tokens],
      },
    })
  }

  const removeTokenV2 = (token: string) => {
    setListsV2(() => ({
      ...listsV2,
      [chainId]: {
        ...listsV2[chainId],
        tokens: listsV2[chainId].tokens.filter((t) => t !== token),
      },
    }))
  }

  const addPoolV2 = (newPool: string) => {
    setListsV2({
      ...listsV2,
      [chainId]: {
        ...listsV2[chainId],
        pools: [newPool, ...listsV2[chainId].pools],
      },
    })
  }

  const removePoolV2 = (pool: string) => {
    setListsV2(() => ({
      ...listsV2,
      [chainId]: {
        ...listsV2[chainId],
        pools: listsV2[chainId].pools.filter((p) => p !== pool),
      },
    }))
  }

  return {
    v1: {
      savedTokens: listsV1[chainId]?.tokens ?? [],
      addToken: addTokenV1,
      removeToken: removeTokenV1,
      savedPools: listsV1[chainId]?.pools ?? [],
      addPool: addPoolV1,
      removePool: removePoolV1,
    },
    v2: {
      savedTokens: listsV2[chainId]?.tokens ?? [],
      addToken: addTokenV2,
      removeToken: removeTokenV2,
      savedPools: listsV2[chainId]?.pools ?? [],
      addPool: addPoolV2,
      removePool: removePoolV2,
    }
  }
}

export default useInfoUserSavedTokensAndPools
