import { ChainId } from '@pulsex/chains'
import { createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import {
  PoolsState,
  SerializedPool
} from 'state/types'
import { getViemClients } from 'utils/viem'
import { getPoolApr } from 'utils/apr'
import { BIG_ZERO } from '@pulsex/utils/bigNumber'
import { getBalanceNumber } from '@pulsex/utils/formatBalance'
import {
  fetchPoolsTotalStaking,
  fetchPoolsStakingLimits,
  fetchPoolsBlockLimits,
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserPendingRewards,
  fetchUserStakeBalances,
  getPoolsConfig,
} from '@pulsex/pools'
import { getTokenPricesFromFarm } from './helpers'

export const initialPoolVaultState = Object.freeze({
  totalShares: null,
  pricePerFullShare: null,
  totalPlsxInVault: null,
  estimatedPlsxBountyReward: null,
  totalPendingPlsxHarvest: null,
  fees: {
    performanceFee: null,
    callFee: null,
    withdrawalFee: null,
    withdrawalFeePeriod: null,
  },
  userData: {
    isLoading: true,
    userShares: null,
    plsxAtLastUserAction: null,
    lastDepositedTime: null,
    lastUserActionTime: null,
    credit: null,
  },
  creditStartBlock: null,
})

const initialState: PoolsState = {
  data: [],
  userDataLoaded: false
}

// Thunks
export const fetchPoolsPublicDataAsync = (chainId: number) => async (dispatch, getState) => {
  try {
    const [block, timeLimits, totalStakings] = await Promise.all([
      getViemClients({ chainId })?.getBlock({ blockTag: 'latest' }),
      fetchPoolsBlockLimits(chainId, getViemClients),
      fetchPoolsTotalStaking(chainId, getViemClients),
    ])
    let currentTime
    if (!currentTime) {
      currentTime = Number(block.timestamp)
    }

    const poolsConfig = getPoolsConfig(chainId) || []
    const prices = getTokenPricesFromFarm(getState().farms.data)
    const liveData = poolsConfig.map((pool) => {
      const timeLimit = timeLimits.find((entry) => entry.poolId === pool.poolId)
      const totalStaking = totalStakings.find((entry) => entry.poolId === pool.poolId)
      const isPoolEndTimeExceeded = currentTime > 0 && timeLimit ? currentTime > Number(timeLimit.endTime) : false
      const isPoolFinished = pool.isFinished || isPoolEndTimeExceeded

      const stakingTokenAddress = pool.stakingToken.address ? pool.stakingToken.address.toLowerCase() : null
      const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0
      const earningTokenAddress = pool.earningToken.address ? pool.earningToken.address.toLowerCase() : null
      const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0
      const apr = !isPoolFinished
        ? getPoolApr(
            stakingTokenPrice,
            earningTokenPrice,
            getBalanceNumber(new BigNumber(totalStaking.totalStaked), pool.stakingToken.decimals),
            parseFloat(pool.tokenPerBlock),
          )
        : 0

      return {
        ...timeLimit,
        ...totalStaking,
        stakingTokenPrice,
        earningTokenPrice,
        apr,
        isFinished: isPoolFinished,
        timeStamp: currentTime,
      }
    })

    dispatch(setPoolsPublicData(liveData))
  } catch (error) {
    console.error('[Pools Action] error when getting public data', error)
  }
}

export const fetchPoolsStakingLimitsAsync = (chainId: ChainId) => async (dispatch, getState) => {
  const poolsWithStakingLimit = getState()
    .pools.data.filter(({ stakingLimit }) => stakingLimit !== null && stakingLimit !== undefined)
    .map((pool) => pool.poolId)
  try {
    const stakingLimits = await fetchPoolsStakingLimits({ poolsWithStakingLimit, chainId, provider: getViemClients })
    const poolsConfig = getPoolsConfig(chainId)
    const stakingLimitData = poolsConfig.map((pool) => {
      if (poolsWithStakingLimit.includes(pool.poolId)) {
        return { poolId: pool.poolId }
      }
      const stakingLimit = stakingLimits[pool.poolId] || BIG_ZERO
      const userLimitEnd = stakingLimits.userLimitEnd || 0

      return {
        poolId: pool.poolId,
        stakingLimit: stakingLimit.toString(),
        userLimitEnd,
      }
    })

    dispatch(setPoolsPublicData(stakingLimitData))
  } catch (error) {
    console.error('[Pools Action] error when getting staking limits', error)
  }
}

export const fetchPoolsUserDataAsync =
  (account: string, chainId: ChainId) => async (dispatch) => {
    try {
      const allowances = await fetchPoolsAllowance({ account, chainId, provider: getViemClients })
      const stakingTokenBalances = await fetchUserBalances({ account, chainId, provider: getViemClients })
      const stakedBalances = await fetchUserStakeBalances({ account, chainId, provider: getViemClients })
      const pendingRewards = await fetchUserPendingRewards({ account, chainId, provider: getViemClients })

      const poolsConfig = getPoolsConfig(chainId)

      const userData = poolsConfig.map((pool) => ({
        sousId: pool.poolId,
        allowance: allowances[pool.poolId],
        stakingTokenBalance: stakingTokenBalances[pool.poolId],
        stakedBalance: stakedBalances[pool.poolId],
        pendingReward: pendingRewards[pool.poolId],
      }))

      dispatch(setPoolsUserData(userData))
    } catch (error) {
      console.error('[Pools Action] Error fetching pool user data', error)
    }
  }

export const updateUserAllowance =
  (sousId: number, account: string, chainId: ChainId) =>
  async (dispatch) => {
    const allowances = await fetchPoolsAllowance({ account, chainId, provider: getViemClients })
    dispatch(updatePoolsUserData({ sousId, field: 'allowance', value: allowances[sousId] }))
  }

export const updateUserBalance =
  (sousId: number, account: string, chainId: ChainId) =>
  async (dispatch) => {
    const tokenBalances = await fetchUserBalances({ account, chainId, provider: getViemClients })
    dispatch(updatePoolsUserData({ sousId, field: 'stakingTokenBalance', value: tokenBalances[sousId] }))
  }

export const updateUserStakedBalance =
  (sousId: number, account: string, chainId: ChainId) =>
  async (dispatch) => {
    const stakedBalances = await fetchUserStakeBalances({ account, chainId, provider: getViemClients })
    dispatch(updatePoolsUserData({ sousId, field: 'stakedBalance', value: stakedBalances[sousId] }))
  }

export const updateUserPendingReward =
  (sousId: number, account: string, chainId: ChainId) =>
  async (dispatch) => {
    const pendingRewards = await fetchUserPendingRewards({ chainId, account, provider: getViemClients })
    dispatch(updatePoolsUserData({ sousId, field: 'pendingReward', value: pendingRewards[sousId] }))
  }

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setInitialPoolConfig: (state, action) => {
      const { chainId } = action.payload
      const allPools = getPoolsConfig(chainId) || []
      state.data = [...allPools]
      state.userDataLoaded = false
    },
    setPoolPublicData: (state, action) => {
      const { poolId } = action.payload
      const poolIndex = state.data.findIndex((pool) => pool.poolId === poolId)
      state.data[poolIndex] = {
        ...state.data[poolIndex],
        ...action.payload.data,
      }
    },
    setPoolUserData: (state, action) => {
      const { poolId } = action.payload
      const poolIndex = state.data.findIndex((pool) => pool.poolId === poolId)
      state.data[poolIndex].userData = action.payload.data
    },
    setPoolsPublicData: (state, action) => {
      const livePoolsData: SerializedPool[] = action.payload
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsData.find((entry) => entry.poolId === pool.poolId)
        return { ...pool, ...livePoolData }
      })
    },
    setPoolsUserData: (state, action) => {
      const userData = action.payload
      state.data = state.data.map((pool) => {
        const userPoolData = userData.find((entry) => entry.poolId === pool.poolId)
        return { ...pool, userData: userPoolData }
      })
      state.userDataLoaded = true
    },
    updatePoolsUserData: (state, action) => {
      const { field, value, poolId } = action.payload
      const index = state.data.findIndex((p) => p.poolId === poolId)

      if (index >= 0) {
        state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
      }
    },
  },
})

// Actions
export const { setPoolsPublicData, setPoolsUserData, updatePoolsUserData, setPoolPublicData, setPoolUserData, setInitialPoolConfig } =
  PoolsSlice.actions

export default PoolsSlice.reducer
