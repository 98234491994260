import { InputHTMLAttributes } from "react";
import { styled } from "styled-components";

interface StyledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  isMax: boolean;
}

interface DisabledProp {
  disabled?: boolean;
}

const getCursorStyle = ({ disabled = false }: DisabledProp) => {
  return disabled ? "not-allowed" : "cursor";
};

const getBaseThumbStyles = ({ disabled }: StyledInputProps) => `
  -webkit-appearance: none;
  box-shadow: none;
  border: 0;
  cursor: ${getCursorStyle};
  width: 24px;
  height: 24px;
  filter: ${disabled ? "grayscale(100%)" : "none"};

  &:hover {
    transform: ${disabled ? "scale(1.1)" : "scale(1.1)"};
  }
`;

export const StyledSlider = styled.div`
  position: absolute;

  width: calc(100% - 3px);
`;

export const StyledInput = styled.input<StyledInputProps>`
  cursor: ${getCursorStyle};
  height: 32px;
  position: relative;

  &::-webkit-slider-thumb {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.primary};
    ${getBaseThumbStyles}
  }

  &::-moz-range-thumb {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.primary};
    ${getBaseThumbStyles}
  }

  &::-ms-thumb {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.primary};
    ${getBaseThumbStyles}
  }
`;

export const BarBackground = styled.div<DisabledProp>`
  background-color: ${({ theme, disabled }) => theme.colors[disabled ? "textDisabled" : "input"]};
  height: 2px;
  position: absolute;
  top: 18px;
  width: calc(100% - 18px);
  left: 12px;
`;

export const BarProgress = styled.div<DisabledProp>`
  background-color: ${({ theme }) => theme.colors.primary};
  filter: ${({ disabled }) => (disabled ? "grayscale(100%)" : "none")};
  height: 10px;
  position: absolute;
  top: 14px;
  left: 12px;
`;
