import { ViewMode } from 'state/user/actions'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { ButtonMenu, ButtonMenuItem, NotificationDot } from '@pulsex/uikit'
import { Link } from 'react-router-dom'
import { useTranslation } from '@pulsex/localization'
import ToggleView from './ToggleView/ToggleView'

const ViewControls = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
    width: auto;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    padding-left: 12px;
    padding-right: 12px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: 0px;
  }
`

const PoolTabButtons = ({ hasStakeInFinishedPools, viewMode, setViewMode }) => {
  const { pathname } = useLocation()

  const { t } = useTranslation()

  const isExact = pathname === '/pools'

  const viewModeToggle = <ToggleView viewMode={viewMode} onToggle={(mode: ViewMode) => setViewMode(mode)} />

  const liveOrFinishedSwitch = (
    <Wrapper>
      <ButtonMenu activeIndex={isExact ? 0 : 1} scale="sm" variant="subtle">
        <ButtonMenuItem as={Link} to="/pools" replace>
          {t('Live')}
        </ButtonMenuItem>
        <NotificationDot show={hasStakeInFinishedPools}>
          <ButtonMenuItem id="finished-pools-button" as={Link} to="/pools/history" replace>
            {t('Finished')}
          </ButtonMenuItem>
        </NotificationDot>
      </ButtonMenu>
    </Wrapper>
  )

  // const stakedOnlySwitch = (
  //   <ToggleWrapper>
  //     <Toggle checked={stakedOnly} onChange={() => setStakedOnly(!stakedOnly)} scale="sm" />
  //     <Text> {t('Staked only')}</Text>
  //   </ToggleWrapper>
  // )

  return (
    <ViewControls>
      {/* {stakedOnlySwitch} */}
      {liveOrFinishedSwitch}
      {viewModeToggle}
    </ViewControls>
  )
}

export default PoolTabButtons
