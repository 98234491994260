import {
  Box,
  Button,
  CopyAddress,
  Flex,
  FlexGap,
  InjectedModalProps,
  LinkExternal,
  Message,
  Skeleton,
  Text
} from '@pulsex/uikit'
import { WNATIVE } from '@pulsex/sdk'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { FetchStatus } from 'config/constants/types'
import { useTranslation } from '@pulsex/localization'
import useAuth from 'hooks/useAuth'
import useNativeCurrency from 'hooks/useNativeCurrency'
import useTokenBalance, { useGetPlsxBalance } from 'hooks/useTokenBalance'
import { useBalance } from 'wagmi'
import { getBlockExplorerLink, getBlockExploreName } from 'utils'
import { formatBigInt, getFullDisplayBalance } from '@pulsex/utils/formatBalance'
import { ChainLogo } from 'components/Logo/ChainLogo'

interface WalletInfoProps {
  hasLowNativeBalance: boolean
  switchView: (newIndex: number) => void
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowNativeBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account, chainId, chain } = useActiveWeb3React()
  const nativeBalance = useBalance({ address: account, chainId })
  const native = useNativeCurrency()
  const wNativeToken = WNATIVE[chainId]
  const { balance: wNativeBalance, fetchStatus: wNativeFetchStatus } = useTokenBalance(wNativeToken?.address)
  const { balance: plsxBalance, fetchStatus: plsxFetchStatus } = useGetPlsxBalance()
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss?.()
    logout()
  }

  return (
    <>
      <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
        {t('Your Address')}
      </Text>
      <FlexGap flexDirection="column" mb="24px" gap="8px">
        <CopyAddress tooltipMessage={t('Copied')} account={account} />
      </FlexGap>
      {hasLowNativeBalance && (
        <Message variant="warning" mb="24px">
        <Box>
          <Text fontWeight="bold">
            {t('%currency% Balance Low', {
              currency: native.symbol,
            })}
          </Text>
          <Text as="p">
            {t('You need %currency% for transaction fees.', {
              currency: native.symbol,
            })}
          </Text>
        </Box>
      </Message>
      )}
      {chain &&
        <Box mb="12px">
          <Flex justifyContent="space-between" alignItems="center" mb="8px">
            <Flex borderRadius="16px" pl="4px" pr="8px" py="2px">
              <ChainLogo chainId={chain.id} />
              <Text color="white" ml="4px">
                {chain.name}
              </Text>
            </Flex>
            <LinkExternal to={getBlockExplorerLink(account, 'address', chainId)}>
              {getBlockExploreName(chainId)}
            </LinkExternal>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="textSubtle">
              {native.symbol} {t('Balance')}
            </Text>
            {!nativeBalance.isFetched ? (
              <Skeleton height="22px" width="60px" />
            ) : (
              <Text>{formatBigInt(nativeBalance?.data?.value, 6)}</Text>
            )}
          </Flex>
          {wNativeBalance.gt(0) && (
            <Flex alignItems="center" justifyContent="space-between">
              <Text color="textSubtle">
                {wNativeToken.symbol} {t('Balance')}
              </Text>
              {wNativeFetchStatus !== FetchStatus.Fetched ? (
                <Skeleton height="22px" width="60px" />
              ) : (
                <Text>{getFullDisplayBalance(wNativeBalance, wNativeToken.decimals, 6)}</Text>
              )}
            </Flex>
          )}
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="textSubtle">{t('PLSX Balance')}</Text>
            {plsxFetchStatus !== FetchStatus.Fetched ? (
              <Skeleton height="22px" width="60px" />
            ) : (
              <Text>{formatBigInt(plsxBalance, 3)}</Text>
            )}
          </Flex>
        </Box>
      }
      <Button variant="secondary" width="100%" minHeight={48} onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </Button>
    </>
  )
}

export default WalletInfo
