import { Currency } from '@pulsex/sdk'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useNavigate, useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { currencyId } from 'utils/currencyId'
import { PLSX, STABLE_COIN } from '@pulsex/tokens'
import { useAddLiquidityFormState } from '../../state/mint/reducer'

export const useCurrencySelectRoute = () => {
  const native = useNativeCurrency()
  const navigate = useNavigate()
  const { chainId } = useActiveChainId()

  const {
    currencyIdA,
    currencyIdB,
  } = useParams<{ currencyIdA?: string; currencyIdB?: string }>() || {
    currencyIdA: native.symbol,
    currencyIdB: PLSX[chainId]?.address ?? STABLE_COIN[chainId]?.address
  }

  // Use protocol of mint state instead of router query 
  // as users can toggle using the protcol version button
  const { protocol } = useAddLiquidityFormState()

  const handleCurrencyASelect = useCallback(
    (currencyA_: Currency) => {
      const newCurrencyIdA = currencyId(currencyA_)
      if (newCurrencyIdA === currencyIdB) {
        navigate(`/add/${protocol}/${currencyIdB}/${currencyIdA}`)
        // router.replace(`/add/${protocol}/${currencyIdB}/${currencyIdA}`, undefined, { shallow: true })
      } else if (currencyIdB) {
        navigate(`/add/${protocol}/${newCurrencyIdA}/${currencyIdB}`)
        // router.replace(`/add/${protocol}/${newCurrencyIdA}/${currencyIdB}`, undefined, { shallow: true })
      } else {
        navigate(`/add/${protocol}/${newCurrencyIdA}`)
        // router.replace(`/add/${protocol}/${newCurrencyIdA}`, undefined, { shallow: true })
      }
    },
    [currencyIdB, currencyIdA, protocol, navigate],
  )
  const handleCurrencyBSelect = useCallback(
    (currencyB_: Currency) => {
      const newCurrencyIdB = currencyId(currencyB_)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          navigate(`/add/${protocol}/${currencyIdB}/${newCurrencyIdB}`)
          // router.replace(`/add/${protocol}/${currencyIdB}/${newCurrencyIdB}`, undefined, { shallow: true })
        } else {
          navigate(`/add/${protocol}/${newCurrencyIdB}`)
          // router.replace(`/add/${protocol}/${newCurrencyIdB}`, undefined, { shallow: true })
        }
      } else {
        navigate(`/add/${protocol}/${currencyIdA || native.symbol}/${newCurrencyIdB}`)
        // router.replace(`/add/${protocol}/${currencyIdA || native.symbol}/${newCurrencyIdB}`, undefined, { shallow: true })
      }
    },
    [currencyIdA, currencyIdB, native, protocol, navigate],
  )

  return {
    handleCurrencyASelect,
    handleCurrencyBSelect,
  }
}
