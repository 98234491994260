import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import { usePlsxLab } from 'hooks/useContract'

const options = {}

const poolStake = async (plsxLabContract, sousId, amount) => {
  return plsxLabContract.write.deposit([sousId, new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()], {
    ...options,
  })
}

const poolStakePls = async (plsxLabContract, sousId, amount) => {
  return plsxLabContract.write.deposit([sousId, new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()], {
    ...options,
  })
}

const useStakePool = (sousId: number, isUsingPls = false) => {
  const plsxLabContract = usePlsxLab()

  const handleStake = useCallback(
    async (amount: string) => {
      if (isUsingPls) {
        return poolStakePls(plsxLabContract, sousId, amount)
      }
      return poolStake(plsxLabContract, sousId, amount)
    },
    [isUsingPls, plsxLabContract, sousId],
  )

  return { onStake: handleStake }
}

export default useStakePool
