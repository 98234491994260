import { useTranslation } from '@pulsex/localization'
import { Currency, SwapParameters, Percent, RouterV1, RouterV2, TradeType, TradeV1, TradeV2 } from '@pulsex/sdk'
import isZero from '@pulsex/utils/isZero'
import truncateHash from '@pulsex/utils/truncateHash'
import { useMemo } from 'react'
import { isUserRejected } from 'utils/sentry'
import { Hash, isAddress } from 'viem'
import { useAddLiquidityFormState } from 'state/mint/reducer'
import { useRouterContractBothProtocols , basisPointsToPercent } from 'utils/exchange'
import { BIPS_BASE , INITIAL_ALLOWED_SLIPPAGE } from 'config/constants/exchange'

import { useTransactionAdder } from 'state/transactions/hooks'
import { transactionErrorToUserReadableMessage } from 'utils/transactionErrorToUserReadableMessage'
import useAccountActiveChain from 'hooks/useAccountActiveChain'
import useTransactionDeadline from './useTransactionDeadline'

export enum SwapCallbackState {
  INVALID,
  LOADING,
  VALID,
}

interface SwapCall {
  contract: ReturnType<typeof useRouterContractBothProtocols>
  parameters: SwapParameters
}

interface SuccessfulCall extends SwapCallEstimate {
  gasEstimate: bigint
}

interface FailedCall extends SwapCallEstimate {
  error: string
}

interface SwapCallEstimate {
  call: SwapCall
}

export class TransactionRejectedError extends Error { }

/**
 * Returns the swap calls that can be used to make the trade
 * @param trade trade to execute
 * @param allowedSlippage user allowed slippage
 * @param recipientAddress
 */
function useSwapCallArguments(
  trade: TradeV1<Currency, Currency, TradeType> | TradeV2<Currency, Currency, TradeType> | undefined, // trade to execute, required
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE, // in bips
  recipientAddress: string | null, // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
): SwapCall[] {
  const { account, chainId } = useAccountActiveChain()
  const { protocol } = useAddLiquidityFormState()
  const contract = useRouterContractBothProtocols(protocol)
  const recipient = recipientAddress === null ? account : recipientAddress
  const deadline = useTransactionDeadline()

  return useMemo(() => {
    if (!trade || !account || !recipient || !chainId) return []

    if (!contract) {
      return []
    }

    const swapMethods = []

    if (trade instanceof TradeV1) {
      swapMethods.push(
        RouterV1.swapCallParameters(trade, {
          feeOnTransfer: false,
          allowedSlippage: new Percent(BigInt(allowedSlippage), BIPS_BASE),
          recipient,
          deadline: Number(deadline),
        }),
      )

      if (trade.tradeType === TradeType.EXACT_INPUT) {
        swapMethods.push(
          RouterV1.swapCallParameters(trade, {
            feeOnTransfer: true,
            allowedSlippage: new Percent(BigInt(allowedSlippage), BIPS_BASE),
            recipient,
            deadline: Number(deadline),
          }),
        )
      }
    } else {
      swapMethods.push(
        RouterV2.swapCallParameters(trade, {
          feeOnTransfer: false,
          allowedSlippage: new Percent(BigInt(allowedSlippage), BIPS_BASE),
          recipient,
          deadline: Number(deadline),
        }),
      )

      if (trade.tradeType === TradeType.EXACT_INPUT) {
        swapMethods.push(
          RouterV2.swapCallParameters(trade, {
            feeOnTransfer: true,
            allowedSlippage: new Percent(BigInt(allowedSlippage), BIPS_BASE),
            recipient,
            deadline: Number(deadline),
          }),
        )
      }
    }

    return swapMethods.map((parameters) => ({ parameters, contract }))
  }, [account, allowedSlippage, chainId, deadline, recipient, trade, contract])
}

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback(
  trade: TradeV1<Currency, Currency, TradeType> | TradeV2<Currency, Currency, TradeType> | undefined, // trade to execute, required
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE, // in bips
  recipientAddress: string | null, // the address of the recipient of the trade, or null if swap should be returned to sender
): { state: SwapCallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId } = useAccountActiveChain()

  const { t } = useTranslation()

  const addTransaction = useTransactionAdder()

  const recipient = recipientAddress === null ? account : recipientAddress

  const swapCalls = useSwapCallArguments(trade, allowedSlippage, recipient)

  return useMemo(() => {
    if (!trade || !account || !chainId) {
      return { state: SwapCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }
    if (!recipient) {
      if (recipientAddress !== null) {
        return { state: SwapCallbackState.INVALID, callback: null, error: 'Invalid recipient' }
      }
      return { state: SwapCallbackState.LOADING, callback: null, error: null }
    }

    return {
      state: SwapCallbackState.VALID,
      callback: async function onSwap(): Promise<string> {
        const estimatedCalls: SwapCallEstimate[] = await Promise.all(
          swapCalls.map((call) => {
            const {
              parameters: { methodName, args, value },
              contract,
            } = call
            const options = !value || isZero(value) ? {} : { value }

            return contract.estimateGas[methodName](args, options)
              .then((gasEstimate) => {
                return {
                  call,
                  gasEstimate,
                }
              })
              .catch((gasError) => {
                console.error('Gas estimate failed, trying eth_call to extract error', call)
                return { call, error: transactionErrorToUserReadableMessage(gasError, t) }
              })
          }),
        )

        // a successful estimation is a bignumber gas estimate and the next call is also a bignumber gas estimate
        let bestCallOption: SuccessfulCall | SwapCallEstimate | undefined = estimatedCalls.find(
          (el, ix, list): el is SuccessfulCall =>
            'gasEstimate' in el && (ix === list.length - 1 || 'gasEstimate' in list[ix + 1]),
        )

        if (!bestCallOption) {
          const errorCalls = estimatedCalls.filter((call): call is FailedCall => 'error' in call)
          if (errorCalls.length > 0) throw errorCalls[errorCalls.length - 1].error
          const firstNoErrorCall = estimatedCalls.find<SwapCallEstimate>(
            (call): call is SwapCallEstimate => !('error' in call),
          )
          if (!firstNoErrorCall) throw new Error(t('Unexpected error. Could not estimate gas for the swap.'))
          bestCallOption = firstNoErrorCall
        }
        
        const call = bestCallOption.call as SwapCall & { gas?: string | bigint }

        const {
          contract,
          gas,
          parameters: {
            args,
            methodName,
            value,
          },
        } = call

        return contract.write[methodName](args, {
          gas,
          // TODO: implement gasPrice calc or oracle so we are not relying on wallet gasPrice feed
          ...(value && !isZero(value) ? { value, account } : { account }),
        })
          .then((response: Hash) => {
            const inputSymbol = trade.inputAmount.currency.symbol
            const outputSymbol = trade.outputAmount.currency.symbol
            const pct = basisPointsToPercent(allowedSlippage)
            const inputAmount =
              trade.tradeType === TradeType.EXACT_INPUT
                ? trade.inputAmount.toSignificant(3)
                : trade.maximumAmountIn(pct).toSignificant(3)

            const outputAmount =
              trade.tradeType === TradeType.EXACT_OUTPUT
                ? trade.outputAmount.toSignificant(3)
                : trade.minimumAmountOut(pct).toSignificant(3)

            const base = `Swap ${trade.tradeType === TradeType.EXACT_OUTPUT ? 'max.' : ''
              } ${inputAmount} ${inputSymbol} for ${trade.tradeType === TradeType.EXACT_INPUT ? 'min.' : ''
              } ${outputAmount} ${outputSymbol}`

            const recipientAddressText =
              recipientAddress && isAddress(recipientAddress) ? truncateHash(recipientAddress) : recipientAddress

            const withRecipient = recipient === account ? base : `${base} to ${recipientAddressText}`

            const translatableWithRecipient =
              trade.tradeType === TradeType.EXACT_OUTPUT
                ? recipient === account
                  ? 'Swap max. %inputAmount% %inputSymbol% for %outputAmount% %outputSymbol%'
                  : 'Swap max. %inputAmount% %inputSymbol% for %outputAmount% %outputSymbol% to %recipientAddress%'
                : recipient === account
                  ? 'Swap %inputAmount% %inputSymbol% for min. %outputAmount% %outputSymbol%'
                  : 'Swap %inputAmount% %inputSymbol% for min. %outputAmount% %outputSymbol% to %recipientAddress%'

            addTransaction(
              { hash: response },
              {
                summary: withRecipient,
                translatableSummary: {
                  text: translatableWithRecipient,
                  data: {
                    inputAmount,
                    inputSymbol,
                    outputAmount,
                    outputSymbol,
                    ...(recipient !== account && { recipientAddress: recipientAddressText }),
                  },
                },
                type: 'swap',
              },
            )

            return response
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (isUserRejected(error)) {
              throw new Error('Transaction rejected.')
            } else {
              // otherwise, the error was unexpected and we need to convey that
              console.error(`Swap failed`, error, methodName, args, value)
              throw new Error(t('Swap failed: %message%', { message: transactionErrorToUserReadableMessage(error, t) }))
            }
          })
      },
      error: null,
    }
  }, [trade, account, chainId, recipient, recipientAddress, swapCalls, t, addTransaction, allowedSlippage])
}
