import { Box } from '@pulsex/uikit'
import styled from 'styled-components'

export const StyledPriceChart = styled(Box)<{ 
  $isDark: boolean; 
  $isExpanded: boolean;
  $isFullWidthContainer?: boolean
}>`
  border: none;
  border-radius: 10px;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 36px;
  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 8px;
    background: transparent;
    border-radius: ${({ $isExpanded }) => ($isExpanded ? '0' : '10px')};
    width: ${({ $isExpanded, $isFullWidthContainer }) => ($isFullWidthContainer || $isExpanded ? '100%' : '50%')};
    height: ${({ $isExpanded }) => ($isExpanded ? 'calc(100vh - 167px)' : '566px')};
    margin-right: ${({ $isExpanded }) => ($isExpanded ? '0px' : '0px')};
  }
`

StyledPriceChart.defaultProps = {
  height: '70%',
}
