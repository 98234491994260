import { Button, Flex, Heading, useToast } from '@pulsex/uikit'
import { useAccount } from 'wagmi'
import { SendTransactionResult } from 'wagmi/actions'
import BigNumber from 'bignumber.js'
import Balance from 'components/Balance'
import { useTranslation } from '@pulsex/localization'
import { ToastDescriptionWithTx } from 'components/Toast'
import useCatchTxError from 'hooks/useCatchTxError'
import { usePriceINCUsdc } from 'state/farms/hooks'
import { BIG_ZERO } from '@pulsex/utils/bigNumber'
import { getBalanceAmount } from '@pulsex/utils/formatBalance'

interface FarmCardActionsProps {
  earnings?: BigNumber
  onReward?: () => Promise<SendTransactionResult>
  onDone?: () => void
}

const HarvestAction: React.FC<React.PropsWithChildren<FarmCardActionsProps>> = ({
  earnings,
  onReward,
  onDone,
}) => {
  const { address: account } = useAccount()
  const { toastSuccess } = useToast()
  const { fetchWithCatchTxError, loading: pendingTx } = useCatchTxError()
  const { t } = useTranslation()
  const incPrice = usePriceINCUsdc()
  const rawEarningsBalance = account ? getBalanceAmount(earnings) : BIG_ZERO
  const displayBalance = rawEarningsBalance.toFixed(3, BigNumber.ROUND_DOWN)
  const earningsUSDC = rawEarningsBalance ? rawEarningsBalance.multipliedBy(incPrice).toNumber() : 0

  const handleHarvest = async () => {
    const receipt = await fetchWithCatchTxError(() => {
      return onReward()
    })
    if (receipt?.status) {
      toastSuccess(
        `${t('Harvested')}!`,
        <ToastDescriptionWithTx txHash={receipt.transactionHash}>
          {t('Your %symbol% earnings have been sent to your wallet!', { symbol: 'INC' })}
        </ToastDescriptionWithTx>,
      )
      onDone?.()
    }
  }

  return (
    <Flex mb="8px" justifyContent="space-between" alignItems="center">
      <Flex flexDirection="column" alignItems="flex-start">
        <Heading color={rawEarningsBalance.eq(0) ? 'textDisabled' : 'text'}>{displayBalance}</Heading>
        {earningsUSDC > 0 && (
          <Balance fontSize="12px" color="textSubtle" decimals={2} value={earningsUSDC} unit=" USD" prefix="~" />
        )}
      </Flex>
      <Button
        disabled={rawEarningsBalance.eq(0) || pendingTx}
        onClick={handleHarvest}
      >
        {pendingTx ? t('Harvesting') : t('Harvest')}
      </Button>
    </Flex>
  )
}

export default HarvestAction
