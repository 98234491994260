import { WalletConfigV2, WalletFilledIcon } from '@pulsex/uikit'
// import { getTrustWalletProvider } from '@pulsex/wagmi/connectors/trustWallet'
import { walletConnectNoQrCodeConnector } from '../utils/wagmi'

const domain = 'https://tokens.app.pulsex.com'

export enum ConnectorNames {
  MetaMask = 'metaMask',
  Injected = 'injected',
  WalletConnect = 'walletConnect',
  WalletConnectV1 = 'walletConnectLegacy',
  Ledger = 'ledger',
  TrustWallet = 'trustWallet',
}

const createQrCode = (chainId: number, connect) => async () => {
  connect({ connector: walletConnectNoQrCodeConnector, chainId })

  const r = await walletConnectNoQrCodeConnector.getProvider()
  return new Promise<string>((resolve) => {
    r.on('display_uri', (uri) => {
      resolve(uri)
    })
  })
}

const isMetamaskInstalled = () => {
  if (typeof window === 'undefined') {
    return false
  }

  if (window.ethereum?.isMetaMask) {
    return true
  }

  if (window.ethereum?.providers?.some((p) => p.isMetaMask)) {
    return true
  }

  return false
}

const walletsConfig = ({
  chainId,
  connect,
}: {
  chainId: number
  connect: (connectorID: ConnectorNames) => void
}): WalletConfigV2<ConnectorNames>[] => {
  const qrCode = createQrCode(chainId, connect)
  return [
    {
      id: 'metamask',
      title: 'Metamask',
      icon: `${domain}/images/metamask.png`,
      get installed() {
        return isMetamaskInstalled()
        // && metaMaskConnector.ready
      },
      connectorId: ConnectorNames.MetaMask,
      deepLink: 'https://metamask.app.link/dapp/pulsex.com/',
      qrCode,
      downloadLink: 'https://metamask.app.link/dapp/pulsex.com/',
    },
    // TODO: enable Trust wallet
    // {
    //   id: 'trust',
    //   title: 'Trust Wallet',
    //   icon: `${domain}/images/trust.png`,
    //   connectorId: ConnectorNames.TrustWallet,
    //   get installed() {
    //     return !!getTrustWalletProvider()
    //   },
    //   deepLink: 'https://link.trustwallet.com/open_url?coin_id=20000714&url=https://app.pulsex.com/',
    //   downloadLink: 'https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph',
    //   guide: {
    //     desktop: 'https://trustwallet.com/browser-extension',
    //     mobile: 'https://trustwallet.com/',
    //   },
    //   qrCode,
    // },
    {
      id: 'walletconnect',
      title: 'WalletConnect',
      icon: `${domain}/images/walletconnect.png`,
      connectorId: ConnectorNames.WalletConnect,
    },
    {
      id: 'opera',
      title: 'Opera Wallet',
      icon: `${domain}/images/opera.png`,
      connectorId: ConnectorNames.Injected,
      get installed() {
        return typeof window !== 'undefined' && Boolean(window.ethereum?.isOpera)
      },
      downloadLink: 'https://www.opera.com/crypto/next',
    },
    {
      id: 'brave',
      title: 'Brave Wallet',
      icon: `${domain}/images/brave.png`,
      connectorId: ConnectorNames.Injected,
      get installed() {
        return typeof window !== 'undefined' && Boolean(window.ethereum?.isBraveWallet)
      },
      downloadLink: 'https://brave.com/wallet/',
    },
    {
      id: 'rabby',
      title: 'Rabby Wallet',
      icon: `${domain}/images/rabby.png`,
      connectorId: ConnectorNames.Injected,
      get installed() {
        return typeof window !== 'undefined' && Boolean(window.ethereum?.isRabby)
      },
      downloadLink: 'https://rabby.io/',
    },
    {
      id: 'ledger',
      title: 'Ledger',
      icon: `${domain}/images/ledger.png`,
      connectorId: ConnectorNames.Ledger,
    },
  ]
}

export const createWallets = (chainId: number, connect: any) => {
  const hasInjected = typeof window !== 'undefined' && !window.ethereum
  const config = walletsConfig({ chainId, connect })
  return hasInjected && config.some((c) => c.installed && c.connectorId === ConnectorNames.Injected)
    ? config // add injected icon if none of injected type wallets installed
    : [
        ...config,
        {
          id: 'injected',
          title: 'Injected',
          icon: WalletFilledIcon,
          connectorId: ConnectorNames.Injected,
          installed: typeof window !== 'undefined' && Boolean(window.ethereum),
        },
      ]
}
