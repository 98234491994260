import { ChainId } from '@pulsex/chains'
import { Currency, Price } from '@pulsex/sdk'
import { SWRConfiguration } from 'swr'
import { pulsexPairAbi } from 'config/abi/IPulseXPair'
import { POOLS_API } from 'config/constants/endpoints'
import { useBuyAndBurnContract } from 'hooks/useContract'
import { BuyAndBurnLpResponse, LP } from 'state/buyandburn/types'
import chunk from 'lodash/chunk'
import flatten from 'lodash/flatten'
import { Address, PublicClient } from 'viem'
import lpListV1 from './lists/lpListV1.json'
import lpListV2 from './lists/lpListV2.json'
import testnetLpListV1 from './lists/testnetLpListV1.json'
import testnetLpListV2 from './lists/testnetLpListV2.json'

export const SWR_SETTINGS: SWRConfiguration = {
  refreshInterval: 10000,
  keepPreviousData: true,
  errorRetryCount: 3,
  errorRetryInterval: 3000,
}

const POOL_FILTER_SETTINGS = {
  reserveUSD: 100000,
}

export const fetchBuyAndBurnPairs = async (
  chainId: number,
  protocol: string,
  buyandburn: ReturnType<typeof useBuyAndBurnContract>,
  plsPrice: Price<Currency, Currency>,
  client: PublicClient
): Promise<{
  result: BuyAndBurnLpResponse[];
  totalBurned: bigint;
}> => {
  const fetchLpList = async () => {
    const response = await fetch(`${POOLS_API}?chainId=${chainId.toString()}&protocol=${protocol.toLowerCase()}`)
    if (response.ok) {
      const lpList = await response.json()
      const lpListFiltered = lpList.filter((pool) => {
        const plsUSDValue = parseFloat(pool.reserve) * parseFloat(plsPrice.toSignificant())
        return parseFloat(pool.reserveUSD) > POOL_FILTER_SETTINGS.reserveUSD &&
          plsUSDValue > POOL_FILTER_SETTINGS.reserveUSD
      })
      return lpListFiltered as LP[]
    }
    // fallback to static pool list
    return chainId === ChainId.PULSECHAIN ? (protocol === 'V1' ? lpListV1 as LP[] : lpListV2 as LP[]) :
      chainId === ChainId.PULSECHAIN_TESTNET ? (protocol === 'V1' ? testnetLpListV1 as LP[] : testnetLpListV2 as LP[]) : null
  }

  const lpList = await fetchLpList()

  const lpInfoCalls = lpList.flatMap(
    (lp) =>
    [
      {
        abi: pulsexPairAbi,
        address: lp.id as Address,
        functionName: 'getReserves',
      },
      {
        abi: pulsexPairAbi,
        address: lp.id as Address,
        functionName: 'token0',
      },
      {
        abi: pulsexPairAbi,
        address: lp.id as Address,
        functionName: 'token1',
      },
      {
        abi: pulsexPairAbi,
        address: lp.id as Address,
        functionName: 'totalSupply',
      },
      {
        abi: pulsexPairAbi,
        address: lp.id as Address,
        functionName: 'balanceOf',
        args: [buyandburn.address],
      }
    ] as const
  )

  // fetch with multicall splitting calls into the chunks of 500
  
  const callsChunck = chunk(lpInfoCalls, 500)
  const resp = []
  for (let i = 0; i < callsChunck.length; i++) {
    // eslint-disable-next-line no-await-in-loop
    resp.push(await client.multicall({
      contracts: callsChunck[i],
      allowFailure: true,
    }))
  }

  // flatten the response nested array and sort the responses
  const chunkSize = lpInfoCalls.length / lpList.length
  const chunked = chunk(flatten(resp), chunkSize)

  const result: BuyAndBurnLpResponse[] = []
  for (const [index, res] of chunked.entries()) {
    result.push({
      name: lpList[index].name,
      address: lpList[index].id,
      reserve: {
        reserve0: res[0].result[0],
        reserve1: res[0].result[1],
        blockTimestampLast: res[0].result[2],
      },
      token0: res[1].result as Address,
      token1: res[2].result as Address,
      totalSupply: res[3].result as bigint,
      balance: res[4].result as bigint,
    })
  }
  const totalBurned = await buyandburn.read.burnedPLSX()

  return { result, totalBurned }
}
