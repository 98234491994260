import { MixedRouteSDK, Protocol } from '@pulsex/smart-order-router/src/routers/sdk'
import { Currency, CurrencyAmount, Token, TradeType, PairV1, PairV2, RouteV1 as V1Route, RouteV2 as V2Route } from '@pulsex/sdk'
import { GetQuoteResult, InterfaceTrade, V2PoolInRoute, V1PoolInRoute } from './types'

/**
 * Transforms a Routing API quote into an array of routes that can be used to create
 * a `Trade`.
 */
export function computeRoutes(
  currencyIn: Currency | undefined,
  currencyOut: Currency | undefined,
  tradeType: TradeType,
  quoteResult: Pick<GetQuoteResult, 'route'> | undefined
) {
  if (!quoteResult || !quoteResult.route || !currencyIn || !currencyOut) return undefined

  if (quoteResult.route.length === 0) return []

  const parsedTokenIn = parseToken(quoteResult.route[0][0].tokenIn)
  const parsedTokenOut = parseToken(quoteResult.route[0][quoteResult.route[0].length - 1].tokenOut)
  if (parsedTokenIn.address !== currencyIn.wrapped.address) return undefined
  if (parsedTokenOut.address !== currencyOut.wrapped.address) return undefined
  if (parsedTokenIn.wrapped.equals(parsedTokenOut.wrapped)) return undefined

  try {
    return quoteResult.route.map((route) => {
      if (route.length === 0) {
        throw new Error('Expected route to have at least one pair or pool')
      }
      const rawAmountIn = route[0].amountIn
      const rawAmountOut = route[route.length - 1].amountOut

      if (!rawAmountIn || !rawAmountOut) {
        throw new Error('Expected both amountIn and amountOut to be present')
      }

      const routeProtocol = getRouteProtocol(route)

      return {
        routev1:
          routeProtocol === Protocol.V1
            ? new V1Route(route.map(genericPoolPairParser) as PairV1[], currencyIn, currencyOut)
            : null,
        routev2:
          routeProtocol === Protocol.V2
            ? new V2Route(route.map(genericPoolPairParser) as PairV2[], currencyIn, currencyOut)
            : null,
        mixedRoute:
          routeProtocol === Protocol.MIXED
            ? new MixedRouteSDK(route.map(genericPoolPairParser), currencyIn, currencyOut)
            : null,
        inputAmount: CurrencyAmount.fromRawAmount(currencyIn, rawAmountIn),
        outputAmount: CurrencyAmount.fromRawAmount(currencyOut, rawAmountOut),
      }
    })
  } catch (e) {
    // `Route` constructor may throw if inputs/outputs are temporarily out of sync
    // (RTK-Query always returns the latest data which may not be the right inputs/outputs)
    // This is not fatal and will fix itself in future render cycles
    console.error(e)
    return undefined
  }
}

export function transformRoutesToTrade<TTradeType extends TradeType>(
  route: ReturnType<typeof computeRoutes>,
  tradeType: TTradeType,
  blockNumber?: string | null,
  gasUseEstimateUSD?: CurrencyAmount<Token> | null
): InterfaceTrade<Currency, Currency, TTradeType> {
  return new InterfaceTrade({
    v1Routes:
      route
        ?.filter((r): r is typeof route[0] & { routev3: NonNullable<typeof route[0]['routev1']> } => r.routev1 !== null)
        .map(({ routev1, inputAmount, outputAmount }) => ({ routev1, inputAmount, outputAmount })) ?? [],
    v2Routes:
      route
        ?.filter((r): r is typeof route[0] & { routev2: NonNullable<typeof route[0]['routev2']> } => r.routev2 !== null)
        .map(({ routev2, inputAmount, outputAmount }) => ({ routev2, inputAmount, outputAmount })) ?? [],
    mixedRoutes:
      route
        ?.filter(
          (r): r is typeof route[0] & { mixedRoute: NonNullable<typeof route[0]['mixedRoute']> } =>
            r.mixedRoute !== null
        )
        .map(({ mixedRoute, inputAmount, outputAmount }) => ({ mixedRoute, inputAmount, outputAmount })) ?? [],
    tradeType,
    gasUseEstimateUSD,
    blockNumber,
  })
}

const parseToken = ({ address, chainId, decimals, symbol }: GetQuoteResult['route'][0][0]['tokenIn']): Token => {
  return new Token(chainId, address, parseInt(decimals.toString()), symbol)
}

const parsePairV1 = ({ reserve0, reserve1 }: V1PoolInRoute): PairV1 =>
  new PairV1(
    CurrencyAmount.fromRawAmount(parseToken(reserve0.token), reserve0.quotient),
    CurrencyAmount.fromRawAmount(parseToken(reserve1.token), reserve1.quotient)
  )

const parsePairV2 = ({ reserve0, reserve1 }: V2PoolInRoute): PairV2 =>
  new PairV2(
    CurrencyAmount.fromRawAmount(parseToken(reserve0.token), reserve0.quotient),
    CurrencyAmount.fromRawAmount(parseToken(reserve1.token), reserve1.quotient)
  )

const genericPoolPairParser = (pool: V1PoolInRoute | V2PoolInRoute): PairV1 | PairV2 => {
  return pool.type === 'v1-pool' ? parsePairV1(pool) : parsePairV2(pool)
}

function getRouteProtocol(route: (V1PoolInRoute | V2PoolInRoute)[]): Protocol {
  if (route.every((pool) => pool.type === 'v1-pool')) return Protocol.V1
  if (route.every((pool) => pool.type === 'v2-pool')) return Protocol.V2
  return Protocol.MIXED
}
