import { Protocol } from '@pulsex/smart-order-router/src/routers/sdk'
import { Currency } from '@pulsex/sdk'
import { Box, Text , DotLineIcon, MouseoverTooltip } from '@pulsex/uikit'
import { Badge } from 'components/Badge'
import { DoubleCurrencyLogo } from 'components/Logo'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Row, { AutoRow } from 'components/Layout/Row'
import { RoutingDiagramEntry } from 'pages/Swap/SmartSwap/components/SwapRoute'
import { styled } from 'styled-components'
import { useToken } from '../../hooks/Tokens'

const Wrapper = styled(Box)`
  align-items: center;
  width: 100%;
`

const RouteContainerRow = styled(Row)`
  display: grid;
  grid-template-columns: 24px 1fr 24px;
`

const RouteRow = styled(Row)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.1rem 0.5rem;
  position: relative;
`

const PoolBadge = styled(Badge)`
  display: flex;
  padding: 4px 4px;
`

const DottedLine = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: calc(100%);
  z-index: 1;
  opacity: 0.5;
`

const DotColor = styled(DotLineIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.background};
  }
`

const OpaqueBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.colors.backgroundAlt2};
  border-radius: 8px;
  display: grid;
  font-size: 12px;
  grid-gap: 4px;
  grid-auto-flow: column;
  justify-content: start;
  padding: 4px 6px 4px 4px;
  z-index: 1000};
`

const ProtocolBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.colors.backgroundDisabled};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.textSubtle};
  font-size: 10px;
  padding: 2px 4px;
  z-index: 1001;
`

const MixedProtocolBadge = styled(ProtocolBadge)`
  width: 60px;
`

const BadgeText = styled(Text)`
  word-break: normal;
`

export default function RoutingDiagram({
  currencyIn,
  currencyOut,
  routes,
}: {
  currencyIn: Currency
  currencyOut: Currency
  routes: RoutingDiagramEntry[]
}) {
  const tokenIn = useToken(currencyIn.wrapped.address)
  const tokenOut = useToken(currencyOut.wrapped.address)

  return (
    <Wrapper>
      {routes?.map((entry, index) => (
        <RouteContainerRow key={index}>
          <CurrencyLogo currency={tokenIn} size="20px" />
          <Route entry={entry} />
          <CurrencyLogo currency={tokenOut} size="20px" />
        </RouteContainerRow>
      ))}
    </Wrapper>
  )
}

function Route({ entry: { percent, path, protocol } }: { entry: RoutingDiagramEntry }) {
  return (
    <RouteRow>
      <DottedLine>
        <DotColor />
      </DottedLine>
      <OpaqueBadge>
        {protocol === Protocol.MIXED ? (
          <MixedProtocolBadge>
            <BadgeText small>V1 + V2</BadgeText>
          </MixedProtocolBadge>
        ) : (
          <ProtocolBadge>
            <BadgeText small>{protocol.toUpperCase()}</BadgeText>
          </ProtocolBadge>
        )}
        <BadgeText small style={{ minWidth: 'auto' }}>
          {percent.toSignificant(2)}%
        </BadgeText>
      </OpaqueBadge>
      <AutoRow gap="1px" width="100%" style={{ justifyContent: 'space-evenly', zIndex: 2 }}>
        {path.map(([currency0, currency1, protocolVersion], index) => (
          <Pool key={index} currency0={currency0} currency1={currency1} protocolVersion={protocolVersion} />
        ))}
      </AutoRow>
    </RouteRow>
  )
}

function Pool({ currency0, currency1, protocolVersion }: { currency0: Currency; currency1: Currency; protocolVersion: string }) {
  const tokenInfo0 = useToken(currency0.wrapped.address)
  const tokenInfo1 = useToken(currency1.wrapped.address)

  // TODO - link pool icon to info.uniswap.org via query params
  return (
    <MouseoverTooltip
      text={<Text small>{`${tokenInfo0?.symbol  }/${  tokenInfo1?.symbol  } ${protocolVersion}`} pool</Text>}
    >
      <PoolBadge>
        <Box margin="2px 0 0 14px">
          <DoubleCurrencyLogo currency0={tokenInfo1} currency1={tokenInfo0} size={20} />
        </Box>
      </PoolBadge>
    </MouseoverTooltip>
  )
}
