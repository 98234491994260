import { useTranslation } from '@pulsex/localization'
import { Card, Flex, Heading } from '@pulsex/uikit'
import Page from 'components/Layout/Page'
import { useMemo } from 'react'
import {
  useAllTokenDataQuery,
  useProtocolChartDataQuery,
  useProtocolDataQuery,
  useProtocolTransactionsQuery,
} from 'state/info/hooks'
import { TokenData } from 'state/info/types'
import { styled } from 'styled-components'
import BarChart from 'pages/Info/components/InfoCharts/BarChart'
import LineChart from 'pages/Info/components/InfoCharts/LineChart'
import PoolTable from 'pages/Info/components/InfoTables/PoolsTable'
import TokenTable from 'pages/Info/components/InfoTables/TokensTable'
import TransactionTable from 'pages/Info/components/InfoTables/TransactionsTable'
import HoverableChart from '../components/InfoCharts/HoverableChart'
import { useNonSpamPoolsData } from '../hooks/usePoolsData'

export const ChartCardsContainer = styled(Flex)`
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 0;
  gap: 1em;

  & > * {
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  } ;
`

const Overview: React.FC<{ protocol: string }> = ({ protocol }) => {
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()

  const protocolData = useProtocolDataQuery(protocol)
  const chartData = useProtocolChartDataQuery(protocol)
  const transactions = useProtocolTransactionsQuery(protocol)

  const currentDate = useMemo(
    () => new Date().toLocaleString(locale, { month: 'short', year: 'numeric', day: 'numeric' }),
    [locale],
  )

  const allTokens = useAllTokenDataQuery(protocol)

  const formattedTokens = useMemo(() => {
    return Object.values(allTokens)
      .map((token) => token.data)
      .filter<TokenData>((token): token is TokenData => token?.name !== 'unknown')
  }, [allTokens])

  const { poolsData } = useNonSpamPoolsData(protocol)

  const somePoolsAreLoading = useMemo(() => {
    return poolsData.some((pool) => !pool?.token0Price)
  }, [poolsData])

  return (
    <Page>
      <Heading scale="lg" mb="16px" id="info-overview-title">
        {t('PulseX Info & Analytics')}
      </Heading>
      <ChartCardsContainer>
        <Card>
          <HoverableChart
            chartData={chartData}
            protocolData={protocolData}
            currentDate={currentDate}
            valueProperty="liquidityUSD"
            title={t('Liquidity')}
            ChartComponent={LineChart}
          />
        </Card>
        <Card>
          <HoverableChart
            chartData={chartData}
            protocolData={protocolData}
            currentDate={currentDate}
            valueProperty="volumeUSD"
            title={t('Volume 24H')}
            ChartComponent={BarChart}
          />
        </Card>
      </ChartCardsContainer>
      <Heading scale="lg" mt="40px" mb="16px">
        {t('Top Tokens')}
      </Heading>
      <TokenTable protocol={protocol} tokenDatas={formattedTokens} />
      <Heading scale="lg" mt="40px" mb="16px">
        {t('Top Pools')}
      </Heading>
      <PoolTable protocol={protocol} poolDatas={poolsData} loading={somePoolsAreLoading} />
      <Heading scale="lg" mt="40px" mb="16px">
        {t('Transactions')}
      </Heading>
      <TransactionTable transactions={transactions} />
    </Page>
  )
}

export default Overview
