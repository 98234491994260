import { useTranslation } from '@pulsex/localization'
import { Skeleton, useToast } from '@pulsex/uikit'
import { FarmWidget } from '@pulsex/widgets-internal'
import BigNumber from 'bignumber.js'
import { ToastDescriptionWithTx } from 'components/Toast'
import useCatchTxError from 'hooks/useCatchTxError'
import { useAppDispatch } from 'state'
import { fetchFarmUserDataAsync } from 'state/farms'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallback } from 'react'
import useUSDCPrice from 'hooks/useUSDCPrice'
import { BIG_ZERO } from '@pulsex/utils/bigNumber'
import { SendTransactionResult } from 'wagmi/actions'
import { getBalanceAmount } from '@pulsex/utils/formatBalance'
import { FarmWithStakedValue } from '@pulsex/farms'
import { INC } from '@pulsex/tokens'
import useHarvestFarm from '../../../hooks/useHarvestFarm'

const { FarmTableHarvestAction } = FarmWidget.FarmTable

interface HarvestActionProps extends FarmWithStakedValue {
  userDataReady: boolean
  onReward?: () => Promise<SendTransactionResult>
  onDone?: () => void
}

export const HarvestActionContainer = ({ children, ...props }) => {
  const { onReward } = useHarvestFarm(props.pid)
  const { account, chainId } = useActiveWeb3React()
  const dispatch = useAppDispatch()

  const onDone = useCallback(
    () => dispatch(fetchFarmUserDataAsync({ account, pids: [props.pid], chainId })),
    [account, chainId, dispatch, props.pid],
  )

  return children({ ...props, onDone, onReward })
}

const HarvestAction: React.FunctionComponent<HarvestActionProps> = ({ 
  userData, 
  userDataReady,
  onReward,
  onDone,
}) => {
  const { chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  const { toastSuccess } = useToast()
  const { fetchWithCatchTxError, loading: pendingTx } = useCatchTxError()
  const earningsBigNumber = new BigNumber(userData.earnings)
  const incPrice = new BigNumber(useUSDCPrice(INC[chainId])?.toFixed())
  let earnings = BIG_ZERO
  let earningsUsd = 0
  let displayBalance = userDataReady ? earnings.toLocaleString() : <Skeleton width={60} />

  // If user didn't connect wallet default balance will be 0
  if (!earningsBigNumber.isZero()) {
    earnings = getBalanceAmount(earningsBigNumber)
    earningsUsd = earnings.multipliedBy(incPrice).toNumber()
    displayBalance = earnings.toFixed(3, BigNumber.ROUND_DOWN)
  }

  const handleHarvest = async () => {
    const receipt = await fetchWithCatchTxError(() => {
      return onReward()
    })
    if (receipt?.status) {
      toastSuccess(
        `${t('Harvested')}!`,
        <ToastDescriptionWithTx txHash={receipt.transactionHash}>
          {t('Your %symbol% earnings have been sent to your wallet!', { symbol: 'INC' })}
        </ToastDescriptionWithTx>,
      )
      onDone?.()
    }
  }

  return (
    <FarmTableHarvestAction
      earnings={earnings}
      earningsUsd={earningsUsd}
      displayBalance={displayBalance}
      pendingTx={pendingTx}
      userDataReady={userDataReady}
      handleHarvest={handleHarvest}
    />
    // <ActionContainer>
    //   <ActionTitles>
    //     <Text bold textTransform="uppercase" color="secondary" fontSize="12px" pr="4px">
    //       INC
    //     </Text>
    //     <Text bold textTransform="uppercase" color="textSubtle" fontSize="12px">
    //       {t('Earned')}
    //     </Text>
    //   </ActionTitles>
    //   <ActionContent>
    //     <div>
    //       <Heading>{displayBalance}</Heading>
    //       {earningsBusd > 0 && (
    //         <Balance fontSize="12px" color="textSubtle" decimals={2} value={earningsBusd} unit=" USD" prefix="~" />
    //       )}
    //     </div>
    //     <Button
    //       disabled={earnings.eq(0) || pendingTx || !userDataReady}
    //       onClick={async () => {
    //         const receipt = await fetchWithCatchTxError(() => {
    //           return onReward()
    //         })
    //         if (receipt?.status) {
    //           toastSuccess(
    //             `${t('Harvested')}!`,
    //             <ToastDescriptionWithTx txHash={receipt.transactionHash}>
    //               {t('Your %symbol% earnings have been sent to your wallet!', { symbol: 'INC' })}
    //             </ToastDescriptionWithTx>,
    //           )
    //           dispatch(fetchFarmUserDataAsync({ account, pids: [pid] }))
    //         }
    //       }}
    //       ml="4px"
    //     >
    //       {pendingTx ? t('Harvesting') : t('Harvest')}
    //     </Button>
    //   </ActionContent>
    // </ActionContainer>
  )
}

export default HarvestAction
