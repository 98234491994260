// src/chainId.ts
var ChainId = /* @__PURE__ */ ((ChainId2) => {
  ChainId2[ChainId2["PULSECHAIN"] = 369] = "PULSECHAIN";
  ChainId2[ChainId2["PULSECHAIN_TESTNET"] = 943] = "PULSECHAIN_TESTNET";
  return ChainId2;
})(ChainId || {});

// src/chainProviders.ts
var pulsechain = {
  id: 369,
  name: "PulseChain",
  network: "pulsechain",
  nativeCurrency: {
    decimals: 18,
    name: "Pulse",
    symbol: "PLS"
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.pulsechain.com"],
      webSocket: ["wss://ws.pulsechain.com"]
    },
    public: {
      http: ["https://rpc.pulsechain.com"],
      webSocket: ["wss://ws.pulsechain.com"]
    }
  },
  blockExplorers: {
    default: {
      name: "Otterscan",
      url: "https://otter.pulsechain.com"
    }
  },
  contracts: {
    ensRegistry: {
      address: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e"
    },
    multicall3: {
      address: "0xdc6ed7C2EE5ab7fd303334B34F7524Cbb463DE57",
      blockCreated: 17235438
    }
  }
};
var pulsechainTestnet = {
  id: 943,
  name: "Testnet",
  network: "pulsechainTestnet",
  testnet: true,
  nativeCurrency: {
    decimals: 18,
    name: "Test Pulse",
    symbol: "tPLS"
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.v4.testnet.pulsechain.com"],
      webSocket: ["wss://ws.v4.testnet.pulsechain.com"]
    },
    public: {
      http: ["https://rpc.v4.testnet.pulsechain.com"],
      webSocket: ["wss://ws.v4.testnet.pulsechain.com"]
    }
  },
  blockExplorers: {
    default: {
      name: "PulseScan",
      url: "https://scan.v4.testnet.pulsechain.com"
    }
  },
  contracts: {
    ensRegistry: {
      address: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e"
    },
    multicall3: {
      address: "0x083F559993227395009596f77496FDc48e84C69a",
      blockCreated: 16528971
    }
  }
};
var CHAINS = [pulsechain, pulsechainTestnet];

// src/subgraphs.ts
var V1_SUBGRAPHS = {
  [369 /* PULSECHAIN */]: {
    url: "https://graph.pulsechain.com/subgraphs/name/pulsechain/pulsex",
    startBlock: 17235499,
    timestamp: 1683811175
  },
  [943 /* PULSECHAIN_TESTNET */]: {
    url: "https://graph.v4.testnet.pulsechain.com/subgraphs/name/pulsechain/pulsex",
    startBlock: 16528996,
    timestamp: 1681720119
  }
};
var V2_SUBGRAPHS = {
  [369 /* PULSECHAIN */]: {
    url: "https://graph.pulsechain.com/subgraphs/name/pulsechain/pulsexv2",
    startBlock: 17413325,
    timestamp: 1685609745
  },
  [943 /* PULSECHAIN_TESTNET */]: {
    url: "https://graph.v4.testnet.pulsechain.com/subgraphs/name/pulsechain/pulsexv2",
    startBlock: 16842459,
    timestamp: 1685529339
  }
};
var INFO_BY_CHAIN = {
  [369 /* PULSECHAIN */]: {
    "v1": V1_SUBGRAPHS[369 /* PULSECHAIN */],
    "v2": V2_SUBGRAPHS[369 /* PULSECHAIN */]
  },
  [943 /* PULSECHAIN_TESTNET */]: {
    "v1": V1_SUBGRAPHS[943 /* PULSECHAIN_TESTNET */],
    "v2": V2_SUBGRAPHS[943 /* PULSECHAIN_TESTNET */]
  }
};

export { CHAINS, ChainId, INFO_BY_CHAIN, V1_SUBGRAPHS, V2_SUBGRAPHS, pulsechain, pulsechainTestnet };
