import { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from '@pulsex/localization'
import { Currency, TradeType } from '@pulsex/sdk'
import { Text, useMatchBreakpoints, MouseoverTooltip } from '@pulsex/uikit'
import { Field } from 'state/swap/actions'
import { computeSmartRouterSlippageAdjustedAmounts } from 'utils/exchange'
import { RowFixed } from 'components/Layout/Row'
import { InterfaceTrade } from 'state/routing/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useNativeCurrency from 'hooks/useNativeCurrency'
import FormattedPriceImpact from '../../components/FormattedPriceImpact'
import { Separator } from '../../styles'

const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.25rem 0;
`
const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

interface AdvancedSwapDetailsProps {
  trade?: InterfaceTrade<Currency, Currency, TradeType>
  allowedSlippage: number
  syncing?: boolean
  hideInfoTooltips?: boolean
}

export function AdvancedSwapDetails({
  trade,
  allowedSlippage,
}: AdvancedSwapDetailsProps) {
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()
  const { chainId } = useActiveWeb3React()
  const nativeCurrency = useNativeCurrency()

  const {
    expectedOutputAmount,
    priceImpact,
    slippageAdjustedAmounts
  } = useMemo(() => {
    return {
      expectedOutputAmount: trade?.outputAmount,
      priceImpact: trade ? trade.priceImpact : undefined,
      slippageAdjustedAmounts: trade ? computeSmartRouterSlippageAdjustedAmounts(trade, allowedSlippage) : undefined
    }
  }, [trade, allowedSlippage])

  return (
    <Container>
      <Separator />

        {!trade?.gasUseEstimateUSD || !chainId ? null : (
          <LabelRow>
            <RowFixed>
              <MouseoverTooltip
                isMobile={isMobile}
                text={t(`The fee paid to miners who process your transaction. This must be paid in ${nativeCurrency.symbol}.`)}
              >
                <Text fontSize="14px" color="textSubtle">
                  {t('Network Fee')}
                </Text>
              </MouseoverTooltip>
            </RowFixed>
            <Text fontSize="14px" textAlign="right">
              ~${trade.gasUseEstimateUSD.toFixed(2)}
            </Text>
          </LabelRow>
        )}

        <LabelRow>
          <RowFixed>
            <MouseoverTooltip
              isMobile={isMobile}
              text={t('The impact your trade has on the market price of this pool.')}
            >
              <Text fontSize="14px" color="textSubtle">
                {t('Price Impact')}
              </Text>
            </MouseoverTooltip>
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpact} />
        </LabelRow>

        <LabelRow>
          <RowFixed>
            <MouseoverTooltip
              isMobile={isMobile}
              text={t(
                'The amount you expect to receive at the current market price. You may receive less or more if themarket price changes while your transaction is pending.',
              )}
            >
              <Text fontSize="14px" color="textSubtle">
                {t('Expected Output')}
              </Text>
            </MouseoverTooltip>
          </RowFixed>
          <Text fontSize="14px" textAlign="right">
            {expectedOutputAmount
              ? `${expectedOutputAmount.toSignificant(6)}  ${expectedOutputAmount.currency.symbol}`
              : '-'}
          </Text>
        </LabelRow>

        <LabelRow>
          <RowFixed>
            <MouseoverTooltip
              isMobile={isMobile}
              text={t(
                'The minimum amount you are guaranteed to receive. If the price slips any further, your transaction will revert.',
              )}
            >
              <Text fontSize="14px" color="textSubtle">
                {trade.tradeType === TradeType.EXACT_INPUT ? t('Minimum received') : t('Maximum sold')}{' '}
                {t(`after slippage (${(allowedSlippage / 100).toFixed(2)}%)`)}
              </Text>
            </MouseoverTooltip>
          </RowFixed>
          <Text fontSize="14px" textAlign="right">
            {trade.tradeType === TradeType.EXACT_INPUT
              ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.outputAmount.currency.symbol}` ??
              '-'
              : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.inputAmount.currency.symbol}` ??
              '-'}
          </Text>
        </LabelRow>


    </Container>
  )
}
