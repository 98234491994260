import { useCallback } from 'react'
import { BIG_ZERO } from '@pulsex/utils/bigNumber'
import { usePlsxLab } from 'hooks/useContract'

const options = {}

const harvestPool = async (sousChefContract, sousId) => {
  return sousChefContract.write.deposit(sousId, '0', { ...options })
}

const harvestPoolPls = async (sousChefContract) => {
  return sousChefContract.deposit({ ...options, value: BIG_ZERO })
}

const useHarvestPool = (sousId, isUsingPls = false) => {
  const plsxLabContract = usePlsxLab()

  const handleHarvest = useCallback(async () => {

    if (isUsingPls) {
      return harvestPoolPls(plsxLabContract)
    }

    return harvestPool(plsxLabContract, sousId)
  }, [isUsingPls, plsxLabContract, sousId])

  return { onReward: handleHarvest }
}

export default useHarvestPool
