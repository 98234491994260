import { useMemo, useState } from 'react'
import { styled } from 'styled-components'
import {
  AddIcon,
  Button,
  ButtonMenu, 
  ButtonMenuItem,
  CardBody,
  CardFooter,
  Text, Flex,
} from '@pulsex/uikit'
import { PairV1, PairV2 } from '@pulsex/sdk'
import { Link } from 'react-router-dom'
import { useTranslation } from '@pulsex/localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import FullPositionCard from 'components/PositionCard'
import { useV1PairsByAccount, useV2PairsByAccount } from '../../hooks/usePairs'
import { useMintActionHandlers } from '../../state/mint/hooks'
import Dots from '../../components/Loader/Dots'
import { AppBody, AppHeader } from '../../components/App'
import Page from '../Page'

const Body = styled(CardBody)`
  background-image: ${({ theme }) => theme.colors.gradientInverseBubblegum};
`

enum FILTER {
  ALL = 0,
  V1 = 1,
  V2 = 2,
}

export default function Pool() {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()

  const [selectedTypeIndex, setSelectedTypeIndex] = useState(FILTER.ALL)

  const { onChangeProtocol } = useMintActionHandlers(true)

  const { data: v1Pairs, loading: v1Loading } = useV1PairsByAccount(account)
  const { data: v2Pairs, loading: v2Loading } = useV2PairsByAccount(account)

  let v1PairsSection = null
  if (v1Pairs?.length) {
    v1PairsSection = v1Pairs.map((pair) => (
      <FullPositionCard
        key={PairV1.getAddress(pair.token0, pair.token1)}
        pair={pair}
        mb='16px'
      />
    ))
  }

  let v2PairsSection = null
  if (v2Pairs?.length) {
    v2PairsSection = v2Pairs.map((pair) => (
      <FullPositionCard
        key={PairV2.getAddress(pair.token0, pair.token1)}
        pair={pair}
        mb='16px'
      />
    ))
  }

  const mainSection = useMemo(() => {
    let resultSection = null
    if (!account) {
      resultSection = (
        <Text color="textSubtle" textAlign="center">
          {t('Connect to a wallet to view your liquidity.')}
        </Text>
      )
    } else if (v1Loading || v2Loading) {
      resultSection = (
        <Text color="textSubtle" textAlign="center">
          <Dots>{t('Loading')}</Dots>
        </Text>
      )
    } else if (!v1PairsSection && !v2PairsSection) {
      resultSection = (
        <Text color="textSubtle" textAlign="center">
          {t('No liquidity found.')}
        </Text>
      )
    } else {
      // Order should be v1, v2
      const sections = [v1PairsSection, v2PairsSection]

      resultSection = selectedTypeIndex ? sections.filter((_, index) => selectedTypeIndex === index + 1) : sections
    }
    return resultSection
  }, [account, selectedTypeIndex, t, v1Loading, v1PairsSection, v2Loading, v2PairsSection])

  const protocolSelector =
    <ButtonMenu
      scale="sm"
      activeIndex={selectedTypeIndex}
      onItemClick={(index) => setSelectedTypeIndex(index)}
      variant="subtle"
    >
      <ButtonMenuItem>All</ButtonMenuItem>
      <ButtonMenuItem>V1</ButtonMenuItem>
      <ButtonMenuItem>V2</ButtonMenuItem>
    </ButtonMenu>

  return (
    <Page>
      <AppBody>
        <AppHeader
          title={t('Your Liquidity')}
          protocolSelector={protocolSelector}
        />
        <Body>
          {mainSection}
          {account && !v1Loading && !v2Loading && (
            <Flex flexDirection="column" alignItems="center" mt="24px">
              <Text color="textSubtle" mb="8px">
                {t("Don't see a pool you joined?")}
              </Text>
              <Link to="/find">
                <Button id="import-pool-link" variant="secondary" scale="sm">
                  {t('Find other LP tokens')}
                </Button>
              </Link>
            </Flex>
          )}
        </Body>
        <CardFooter style={{ textAlign: 'center' }}>
          <Link to="/add/V1">
            <Button
              id="join-pool-button"
              width="100%"
              startIcon={<AddIcon color="white" />}
              onClick={() => {onChangeProtocol('V2')}}
            >
              {t('Add Liquidity')}
            </Button>
          </Link>
        </CardFooter>
      </AppBody>
    </Page>
  )
}
