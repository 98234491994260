import { batch, useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useRefreshEffect } from 'hooks/useRefreshEffect'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { Token } from '@pulsex/sdk'
import { DeserializedPool } from '@pulsex/pools'
import { FAST_INTERVAL, SLOW_INTERVAL } from 'config/constants/exchange'
import { fetchPoolsPublicDataAsync, fetchPoolsUserDataAsync, fetchPoolsStakingLimitsAsync } from '.'
import { State } from '../types'
import { transformPool } from './helpers'
import { fetchFarmsPublicDataAsync } from '../farms'

export const useFetchPublicPoolsData = () => {
  const dispatch = useAppDispatch()
  const { chainId } = useActiveChainId()

  useRefreshEffect(SLOW_INTERVAL, () => {
    const fetchPoolsDataWithFarms = async () => {
      const activeFarms = []
      dispatch(fetchFarmsPublicDataAsync({ pids: activeFarms, chainId }))
      batch(() => {
        dispatch(fetchPoolsPublicDataAsync(chainId))
        dispatch(fetchPoolsStakingLimitsAsync(chainId))
      })
    }

    fetchPoolsDataWithFarms()
  }, [dispatch, chainId])
}

export const useFetchUserPools = (account) => {
  const dispatch = useAppDispatch()
  const { chainId } = useActiveChainId()

  useRefreshEffect(FAST_INTERVAL, () => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account, chainId))
    }
  }, [account, chainId, dispatch])
}

export const usePools = (): { pools: DeserializedPool<Token>[]; userDataLoaded: boolean } => {
  const { pools, userDataLoaded } = useSelector((state: State) => ({
    pools: state.pools.data,
    userDataLoaded: state.pools.userDataLoaded,
  }))
  return { pools: pools.map(transformPool), userDataLoaded }
}

export const usePool = (poolId: number): { pool: DeserializedPool<Token>; userDataLoaded: boolean } => {
  const { pool, userDataLoaded } = useSelector((state: State) => ({
    pool: state.pools.data.find((p) => p.poolId === poolId),
    userDataLoaded: state.pools.userDataLoaded,
  }))
  return { pool: transformPool(pool), userDataLoaded }
}
