import { useState, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { Flex, ButtonMenu, ButtonMenuItem, Box, Text, Heading, Card, Button, useToast } from '@pulsex/uikit'
import useCatchTxError from 'hooks/useCatchTxError'
import { useTranslation } from '@pulsex/localization'
import Page from 'components/Layout/Page'
import { ToastDescriptionWithTx } from 'components/Toast'
import useSWR from 'swr'
import { useBuyAndBurnContract } from 'hooks/useContract'
import { getViemClients } from 'utils/viem'
import { usePLSUsdcPrice, usePlsxUsdcPrice } from 'hooks/useUSDCPrice'
import { fetchBuyAndBurnPairs, SWR_SETTINGS } from 'state/buyandburn/hooks'
import { formatAmount } from '@pulsex/utils/formatInfoNumbers'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useAddLiquidityFormState } from 'state/mint/reducer'
import { Address, formatEther } from 'viem'
import { useMintActionHandlers } from '../../state/mint/hooks'
import PoolTable from './PoolsTable'
import { ChartCardsContainer } from './shared'

const Burn: React.FC = () => {
  const { t } = useTranslation()
  const { chainId } = useActiveChainId()
  const { toastSuccess } = useToast()
  const { fetchWithCatchTxError } = useCatchTxError()
  const [reset, setReset] = useState(false)
  const [checked, setChecked] = useState([])
  const [selectedUSD, setSelectedUSD] = useState(0)
  const plsxPrice = usePlsxUsdcPrice()
  const plsxPriceBN = new BigNumber(plsxPrice?.toSignificant())
  const plsPrice = usePLSUsdcPrice()

  const { protocol } = useAddLiquidityFormState()
  const { onChangeProtocol } = useMintActionHandlers(true)

  const buyandburn = useBuyAndBurnContract(protocol)
  const client = getViemClients({ chainId })

  const { data, isLoading } = useSWR(
    [protocol, buyandburn, client],
    () => fetchBuyAndBurnPairs(chainId, protocol, buyandburn, plsPrice, client),
    SWR_SETTINGS, // update lp info every 10s
  )
  const totalPLSXBurned = useMemo(() => {
    return data ? new BigNumber(formatEther(data.totalBurned)) : new BigNumber(0)
  }, [data])

  const handleCheck = (event) => {
    let updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const calculateUSD = (e, USDValue) => {
    let updatedCout = selectedUSD;
    if (e.target.checked) {
      updatedCout += USDValue;
    } else {
      updatedCout -= USDValue;
    }
    if (updatedCout < 0) {
      updatedCout = 0
    }
    setSelectedUSD(updatedCout)
  }

  const burn = async () => {
    const tokensArray0: Address[] = []
    const tokensArray1: Address[] = []
    for (let i = 0; i < checked.length; i++) {
      tokensArray0.push(checked[i].slice(0, 42))
      tokensArray1.push(checked[i].slice(43, 85))
    }
    const receipt = await fetchWithCatchTxError(async() => {
      const gasEstimate = await buyandburn.estimateGas.convertLps([tokensArray0, tokensArray1], {})
      return buyandburn.write.convertLps([tokensArray0, tokensArray1], { gasLimit: gasEstimate })
    })
    if (receipt?.status) {
      toastSuccess(
        t('PLSX Burned!'),
        <ToastDescriptionWithTx txHash={receipt.transactionHash}>
          {t('PLSX bounty has been sent to your wallet.')}
        </ToastDescriptionWithTx>,
      )
    }
    setChecked([])
    setSelectedUSD(0)
    setReset(true)
  }

  const handleReset = () => {
    setReset(false)
  }

  const protocolSelector =
    <ButtonMenu
      ml="20px"
      scale="sm"
      activeIndex={protocol === 'V1' ? 0 : 1}
      onItemClick={(index) => onChangeProtocol(index === 0 ? 'V1' : 'V2')}
      variant="subtle"
    >
      <ButtonMenuItem>V1</ButtonMenuItem>
      <ButtonMenuItem>V2</ButtonMenuItem>
    </ButtonMenu>

  return (
    <Page>
      <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
        <Flex flex="2" flexDirection="column">
          <Heading scale="xl" mb="16px" id="info-overview-title">
            {t('PulseX Buy & Burn')}
          </Heading>
          <Heading scale="md" mb="16px" id="info-overview-title">
            {t('Select LPs to Buy and Burn PLSX')}
          </Heading>
        </Flex>
        <ChartCardsContainer>
          <Card>
            <Box p={['16px', '16px', '24px']}>
              <Flex alignItems="center" justifyContent="space-between">
                <Text bold fontSize="24px" color="secondary">
                 {t('Burn PLSX')}
               </Text>
               <Text fontSize="13px">
                 Total PLSX burned: {formatAmount(totalPLSXBurned.toNumber())}🔥
               </Text>
              </Flex>
              <Text color="textSubtle">Selected USD value: ${formatAmount(selectedUSD)}</Text>
              <Text color="textSubtle">This would burn: ~{formatAmount(selectedUSD / plsxPriceBN.toNumber())} PLSX</Text>
              <Text bold>
                Bounty: {formatAmount(((selectedUSD - (selectedUSD * 0.1429)) / plsxPriceBN.toNumber()) * 0.001)} PLSX
                / ~${formatAmount((selectedUSD - (selectedUSD * 0.1429)) * 0.001)}
              </Text>
             <Box>
                <Flex paddingTop="20px" alignContent="center" justifyContent="center">
                  <Button width="100%" onClick={burn}>BURN</Button>
                </Flex>
             </Box>
            </Box>
          </Card>
        </ChartCardsContainer>
      </Flex>
      
      <Heading scale="lg" mt="40px" mb="16px">
        {t('LPs to Burn')}
        {protocolSelector}
      </Heading>
      <PoolTable
        lpData={data?.result}
        loading={isLoading}
        handleCheck={handleCheck}
        calculateUSD={calculateUSD}
        reset={reset}
        handleReset={handleReset}
      />
    </Page>
  )
}

export default Burn
