import { Flex, IconButton, CogIcon, useModal, NotificationDot } from '@pulsex/uikit'
import { useExpertModeManager } from 'state/user/hooks'
import SettingsModal from './SettingsModal'

type Props = {
  color?: string
  mr?: string
}

const GlobalSettings = ({ color, mr = '8px' }: Props) => {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)
  const [expertMode] = useExpertModeManager()

  return (
    <Flex>
      <IconButton onClick={onPresentSettingsModal} variant="text" scale="sm" mr={mr} id="open-settings-dialog-button">
        <NotificationDot show={expertMode}>
          <CogIcon height={22} width={22} color={color || 'textSubtle'} />
        </NotificationDot>
      </IconButton>
    </Flex>
  )
}

export default GlobalSettings
