import { Box, ButtonMenu, ButtonMenuItem, Flex } from '@pulsex/uikit'
import { useTranslation } from '@pulsex/localization'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Search from 'pages/Info/components/InfoSearch'

const NavWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.gradientCardHeader};
  justify-content: space-between;
  padding: 20px 16px;
  flex-direction: column;
  gap: 8px;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 20px 40px;
    flex-direction: row;
  }
`

const InfoNav: React.FC<React.PropsWithChildren<{ isV2: boolean }>> = ({ isV2 }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isPools = isV2 ? pathname.includes('/info/v2/pools') : pathname.includes('/info/pools')
  const isTokens = isV2 ? pathname.includes('/info/v2/tokens') : pathname.includes('/info/tokens')
  let activeIndex = 0
  if (isPools) {
    activeIndex = 1
  }
  if (isTokens) {
    activeIndex = 2
  }
  return (
    <NavWrapper>
      <Box>
        <ButtonMenu activeIndex={activeIndex} scale="sm" variant="subtle">
          <ButtonMenuItem as={Link} to={isV2 ? "/info/v2" : "/info"}>
            {t('Overview')}
          </ButtonMenuItem>
          <ButtonMenuItem as={Link} to={isV2 ? "/info/v2/pools" : "/info/pools"}>
            {t('Pools')}
          </ButtonMenuItem>
          <ButtonMenuItem as={Link} to={isV2 ? "/info/v2/tokens" : "/info/tokens"}>
            {t('Tokens')}
          </ButtonMenuItem>
        </ButtonMenu>
      </Box>
      <Box width={['100%', '100%', '250px']}>
        <Search protocol={isV2 ? 'v2' : 'v1'} />
      </Box>
    </NavWrapper>
  )
}

export default InfoNav
