import { ChainId } from '@pulsex/chains'

export default {
  router: {
    [ChainId.PULSECHAIN]: '0x98bf93ebf5c380C0e6Ae8e192A7e2AE08edAcc02',
    [ChainId.PULSECHAIN_TESTNET]: '0xDaE9dd3d1A52CfCe9d5F2fAC7fDe164D500E50f7',
  },
  multiCall: {
    [ChainId.PULSECHAIN]: '0xdc6ed7C2EE5ab7fd303334B34F7524Cbb463DE57',
    [ChainId.PULSECHAIN_TESTNET]: '0x083F559993227395009596f77496FDc48e84C69a',
  },
  masterChef: {
    [ChainId.PULSECHAIN]: '0xB2Ca4A66d3e57a5a9A12043B6bAD28249fE302d4',
    [ChainId.PULSECHAIN_TESTNET]: '0x3b34Fff74497cA21771ce9a0c11cb07490686A58',
  },
  plsxLab: {
    [ChainId.PULSECHAIN]: '0x3E3EC0af505D6458D18A3AA1E29A9d7f008dd98e',
    [ChainId.PULSECHAIN_TESTNET]: '0x959a437F1444DaDaC8aF997E71EAF0479c810267',
  },
  buyandburnV1: {
    [ChainId.PULSECHAIN]: '0xD46BD969d995A122AD5B803A45d309021A647B87',
    [ChainId.PULSECHAIN_TESTNET]: '0x7cD43983A963c62Add34694E83E896c5AfC3333f',
  },
  buyandburnV2: {
    [ChainId.PULSECHAIN]: '0xd6cA7ee047a6F45d20d2962E4394E070cF27724F',
    [ChainId.PULSECHAIN_TESTNET]: '0x3DdaBc2B4f5D0b74A28481eE6f42C67c495E0E5E',
  },
} as const
