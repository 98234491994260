import React from "react";
import { Link } from "./Link";
import OpenNewIcon from "../Svg/Icons/OpenNew";
import PlsIcon from "../Svg/Icons/PlsIcon";
import { StyledLinkProps } from "./types";

export const LinkExternal: React.FC<React.PropsWithChildren<StyledLinkProps>> = ({ 
  children,
  isPlsScan,
  ...props
}) => {
  return (
    <Link target="_blank" rel="noopener noreferrer" {...props}>
      {children}
      {isPlsScan && <PlsIcon color={props.color ? props.color : "primary"} ml="4px" />}
      <OpenNewIcon color={props.color ? props.color : "primary"} ml="4px" />
    </Link>
  );
};
