import styled from 'styled-components'

import { AutoRouterIcon, Text } from '@pulsex/uikit'

const StyledAutoRouterIcon = styled(AutoRouterIcon)`
  height: 16px;
  width: 16px;

  :hover {
    filter: brightness(1.3);
  }
`
const StyledAutoRouterLabel = styled(Text)`
  line-height: 1rem;

  /* fallback color */
  color: ${({ theme }) => theme.colors.success};

  @supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
    background-image: linear-gradient(90deg, #2172e5 0%, #54e521 163.16%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export function AutoRouterLogo() {
  return <StyledAutoRouterIcon />
}

export function AutoRouterLabel() {
  return <StyledAutoRouterLabel>Auto Router</StyledAutoRouterLabel>
}
