import BigNumber from 'bignumber.js'
import { Currency, PairV1, PairV2 } from '@pulsex/sdk'
import {
  Button,
  ChevronDownIcon,
  CopyButton,
  Text,
  useModal,
  Flex,
  Box
} from '@pulsex/uikit'
import { styled } from 'styled-components'
import { safeGetAddress } from 'utils'
import { useTranslation } from '@pulsex/localization'
import { WrappedTokenInfo } from '@pulsex/token-lists'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useUSDCPrice from 'hooks/useUSDCPrice'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'
import NumInput from './NumericalInput'
import AddToWalletButton from '../AddToWallet/AddToWalletButton'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0.5rem 0.75rem 0.5rem;
`
const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })`
  padding: 0 0;
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 0.5rem 0 0.5rem;
`
const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: '10px';
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  z-index: 1;
`
const Container = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.input};
  box-shadow: ${({ theme }) => theme.shadows.inset};
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: PairV1 | PairV2 | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
}: CurrencyInputPanelProps) {
  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { t } = useTranslation()

  const token = pair ? pair.liquidityToken : currency?.isToken ? currency : null
  const tokenAddress = token ? safeGetAddress(token.address) : null

  const price = new BigNumber(useUSDCPrice(currency)?.toFixed(18))
  const inputPrice = price.multipliedBy(value.replace(/,/g, '')).toString()

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
    />,
  )
  return (
    <Box id={id}>
      <InputPanel>
        <Container>
          <LabelRow>
            <NumInput
              value={value}
              onUserInput={onUserInput}
            />

            <CurrencySelectButton
              className="open-currency-select-button"
              selected={!!currency}
              onClick={() => {
                if (!disableCurrencySelect) {
                  onPresentCurrencyModal()
                }
              }}
            >
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
              ) : currency ? (
                <CurrencyLogo currency={currency} size="26px" style={{ marginRight: '8px' }} />
              ) : null}
              {pair ? (
                <Text id="pair" bold>
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </Text>
              ) : (
                <Text fontSize="18px" id="pair" bold>
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                        currency.symbol.length - 5,
                        currency.symbol.length,
                      )}`
                    : currency?.symbol) || t('Select a currency')}
                </Text>
              )}
              {!disableCurrencySelect && <ChevronDownIcon />}
            </CurrencySelectButton>
            {token && tokenAddress ? (
              <Flex style={{ gap: '4px' }} ml="4px" alignItems="center">
                <CopyButton
                  width="16px"
                  buttonColor="textSubtle"
                  text={tokenAddress}
                  tooltipMessage={t('Token address copied')}
                />
                <AddToWalletButton
                  variant="text"
                  p="0"
                  height="auto"
                  width="fit-content"
                  tokenAddress={tokenAddress}
                  tokenSymbol={token.symbol}
                  tokenDecimals={token.decimals}
                  tokenLogo={token instanceof WrappedTokenInfo ? token.logoURI : undefined}
                />
              </Flex>
            ) : null}
          </LabelRow>
          <InputRow selected={disableCurrencySelect}>
            {currency && value ? (
              <Text color="textSubtle2" fontSize="14px">
                ${Number(inputPrice).toLocaleString('en-US', { maximumFractionDigits: 9 })}
              </Text>
            ) : otherCurrency && value ? (
              <Text color="textSubtle2" fontSize="14px">
                ${Number(inputPrice).toLocaleString('en-US', { maximumFractionDigits: 9 })}
              </Text>
            ) : (
              <Text />
            )}

            <Flex mb="6px" alignItems="center" justifyContent="flex-end">
              {account && (
                <Text
                  onClick={onMax}
                  color="textSubtle2"
                  fontSize="13px"
                  style={{ display: 'inline', cursor: 'pointer' }}
                >
                  {!hideBalance && !!currency
                    ? t('Balance: %balance%', {
                        balance: selectedCurrencyBalance
                          ? Number(selectedCurrencyBalance.toFixed()).toLocaleString(undefined, {
                              maximumFractionDigits: 3,
                            })
                          : t('Loading'),
                      })
                    : ' -'}
                </Text>
              )}
            </Flex>
          </InputRow>
        </Container>
      </InputPanel>
    </Box>
  )
}
