import { styled } from 'styled-components'

export const Badge = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundAlt2};
  border: ${({ theme }) => theme.colors.cardBorder};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.primary};
  display: inline-flex;
  padding: 4px 6px;
  justify-content: center;
  font-weight: 500;
`

export const SmallBadge = styled(Badge)`
  border-radius: 5px;
  padding: 2px 4px;
`
