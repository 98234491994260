import { Flex, Text, TimerIcon } from '@pulsex/uikit'
import BigNumber from 'bignumber.js'
import { useTranslation } from '@pulsex/localization'
import Balance from 'components/Balance'
import { getFullDisplayBalance } from '@pulsex/utils/formatBalance'
import { Token } from '@pulsex/sdk'

interface MaxStakeRowProps {
  small?: boolean
  stakingLimit: BigNumber
  timeStamp: number
  stakingLimitEnd: number
  stakingToken: Token
  hasPoolStarted: boolean
}

const MaxStakeRow: React.FC<MaxStakeRowProps> = ({
  small = false,
  stakingLimit,
  timeStamp,
  stakingLimitEnd,
  stakingToken,
  hasPoolStarted,
}) => {
  const { t } = useTranslation()

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" alignItems="center">
        <Text small={small}>{t('MAX stake per user:')}</Text>
        <Text small={small}>{`${getFullDisplayBalance(stakingLimit, stakingToken.decimals, 0)} ${
          stakingToken.symbol
        }`}</Text>
      </Flex>
      {hasPoolStarted && (
        <Flex justifyContent="space-between" alignItems="center">
          <Text small={small}>{t('Max. stake limit ends in')}:</Text>
          <Balance small={small} value={Math.max(stakingLimitEnd - timeStamp, 0)} decimals={0} color="primary" />
          <Text small={small} ml="4px" color="primary" textTransform="lowercase">
            {t('seconds')}
          </Text>
          <TimerIcon ml="4px" color="primary" />
        </Flex>
      )}
    </Flex>
  )
}

export default MaxStakeRow
