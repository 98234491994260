import { gql } from 'graphql-request'
import { useEffect, useState } from 'react'
import { Block, ProtocolData } from 'state/info/types'
import { getChangeForPeriod } from 'utils/getChangeForPeriod'
import { getDeltaTimestamps } from 'utils/getDeltaTimestamps'
import { infoClientWithChain } from 'utils/graphql'
import { useBlocksFromTimestamps } from 'pages/Info/hooks/useBlocksFromTimestamps'
import { getPercentChange } from 'pages/Info/utils/infoDataHelpers'

interface PulseXFactory {
  totalTransactions: string
  totalVolumeUSD: string
  totalLiquidityUSD: string
}

interface OverviewResponse {
  pulseXFactories: PulseXFactory[]
}

/**
 * Latest Liquidity, Volume and Transaction count
 */
const getOverviewData = async (
  chainId: number,
  protocol: string,
  block?: number
): Promise<{ data?: OverviewResponse; error: boolean }> => {
  try {
    const query = gql`query overview {
      pulseXFactories(
        ${block ? `block: { number: ${block}}` : ``}
        first: 1) {
          totalTransactions
          totalVolumeUSD
          totalLiquidityUSD
        }
    }`
    const data = await infoClientWithChain(chainId, protocol).request<OverviewResponse>(query)
    return { data, error: false }
  } catch (error) {
    console.error('Failed to fetch info overview', error)
    return { data: null, error: true }
  }
}

const formatPulseXFactoryResponse = (rawPulseXFactory?: PulseXFactory) => {
  if (rawPulseXFactory) {
    return {
      totalTransactions: parseFloat(rawPulseXFactory.totalTransactions),
      totalVolumeUSD: parseFloat(rawPulseXFactory.totalVolumeUSD),
      totalLiquidityUSD: parseFloat(rawPulseXFactory.totalLiquidityUSD),
    }
  }
  return null
}

interface ProtocolFetchState {
  error: boolean
  data?: ProtocolData
}

const useFetchProtocolData = (chainId: number, protocol: string): ProtocolFetchState => {
  const [fetchState, setFetchState] = useState<ProtocolFetchState>({
    error: false,
  })
  const [t24, t48] = getDeltaTimestamps()
  const { blocks, error: blockError } = useBlocksFromTimestamps([t24, t48])
  const [block24, block48] = blocks ?? []

  useEffect(() => {
    const fetchData = async () => {
      const [{ error, data }, { error: error24, data: data24 }, { error: error48, data: data48 }] = await Promise.all([
        getOverviewData(chainId, protocol),
        getOverviewData(chainId, protocol, block24?.number ?? undefined),
        getOverviewData(chainId, protocol, block48?.number ?? undefined),
      ])
      const anyError = error || error24 || error48
      const overviewData = formatPulseXFactoryResponse(data?.pulseXFactories?.[0])
      const overviewData24 = formatPulseXFactoryResponse(data24?.pulseXFactories?.[0]) || overviewData
      const overviewData48 = formatPulseXFactoryResponse(data48?.pulseXFactories?.[0]) || overviewData24
      const allDataAvailable = overviewData && overviewData24 && overviewData48
      if (anyError || !allDataAvailable) {
        setFetchState({
          error: true,
        })
      } else {
        const [volumeUSD, volumeUSDChange] = getChangeForPeriod(
          overviewData.totalVolumeUSD,
          overviewData24.totalVolumeUSD,
          overviewData48.totalVolumeUSD,
        )
        const liquidityUSDChange = getPercentChange(overviewData.totalLiquidityUSD, overviewData24.totalLiquidityUSD)
        // 24H transactions
        const [txCount, txCountChange] = getChangeForPeriod(
          overviewData.totalTransactions,
          overviewData24.totalTransactions,
          overviewData48.totalTransactions,
        )
        const protocolData: ProtocolData = {
          volumeUSD,
          volumeUSDChange: typeof volumeUSDChange === 'number' ? volumeUSDChange : 0,
          liquidityUSD: overviewData.totalLiquidityUSD,
          liquidityUSDChange,
          txCount,
          txCountChange,
        }
        setFetchState({
          error: false,
          data: protocolData,
        })
      }
    }

    const allBlocksAvailable = block24?.number && block48?.number
    if (allBlocksAvailable && !blockError && !fetchState.data) {
      fetchData()
    }
  }, [chainId, block24, block48, blockError, fetchState, protocol])

  return fetchState
}

export const fetchProtocolData = async (chainId: number, protocol: string, block24: Block, block48: Block) => {
  const [{ data }, { data: data24 }, { data: data48 }] = await Promise.all([
    getOverviewData(chainId, protocol),
    getOverviewData(chainId, protocol, block24?.number ?? undefined),
    getOverviewData(chainId, protocol, block48?.number ?? undefined),
  ])

  // const anyError = error || error24 || error48
  const overviewData = formatPulseXFactoryResponse(data?.pulseXFactories?.[0])
  const overviewData24 = formatPulseXFactoryResponse(data24?.pulseXFactories?.[0]) || overviewData
  const overviewData48 = formatPulseXFactoryResponse(data48?.pulseXFactories?.[0]) || overviewData24
  // const allDataAvailable = overviewData && overviewData24 && overviewData48

  const [volumeUSD, volumeUSDChange] = getChangeForPeriod(
    overviewData.totalVolumeUSD,
    overviewData24.totalVolumeUSD,
    overviewData48.totalVolumeUSD,
  )
  const liquidityUSDChange = getPercentChange(overviewData.totalLiquidityUSD, overviewData24.totalLiquidityUSD)
  // 24H transactions
  const [txCount, txCountChange] = getChangeForPeriod(
    overviewData.totalTransactions,
    overviewData24.totalTransactions,
    overviewData48.totalTransactions,
  )
  const protocolData: ProtocolData = {
    volumeUSD,
    volumeUSDChange: typeof volumeUSDChange === 'number' ? volumeUSDChange : 0,
    liquidityUSD: overviewData.totalLiquidityUSD,
    liquidityUSDChange,
    txCount,
    txCountChange,
  }
  return protocolData
}

export default useFetchProtocolData
