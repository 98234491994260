import { useMemo } from 'react'
import { Card, Heading, Text } from '@pulsex/uikit'
import Page from 'components/Layout/Page'
import { useTranslation } from '@pulsex/localization'
import { useAllTokenDataQuery, useTokenDatasQuery } from 'state/info/hooks'
import useInfoUserSavedTokensAndPools from 'hooks/useInfoUserSavedTokensAndPoolsList'
import TokenTable from 'pages/Info/components/InfoTables/TokensTable'
import { useActiveChainId } from 'hooks/useActiveChainId'
import TopTokenMovers from 'pages/Info/components/TopTokenMovers'

const TokensOverview: React.FC<{ protocol: string }> = ({ protocol }) => {
  const { t } = useTranslation()
  const { chainId } = useActiveChainId()

  const allTokens = useAllTokenDataQuery(protocol)
  const { v1, v2 } = useInfoUserSavedTokensAndPools(chainId)
  const { savedTokens } = protocol === 'v1' ? v1 : v2

  const formattedTokens = useMemo(() => {
    return Object.values(allTokens)
      .map((token) => token.data)
      .filter((token) => token)
  }, [allTokens])

  const watchListTokens = useTokenDatasQuery(protocol, savedTokens)

  return (
    <Page>
      <Heading scale="lg" mb="16px">
        {t('Your Watchlist')}
      </Heading>
      {savedTokens.length > 0 ? (
        <TokenTable protocol={protocol} tokenDatas={watchListTokens} />
      ) : (
        <Card>
          <Text py="16px" px="24px">
            {t('Saved tokens will appear here')}
          </Text>
        </Card>
      )}
      <TopTokenMovers protocol={protocol} />
      <Heading scale="lg" mt="40px" mb="16px" id="info-tokens-title">
        {t('All Tokens')}
      </Heading>
      <TokenTable protocol={protocol} tokenDatas={formattedTokens} />
    </Page>
  )
}

export default TokensOverview
